import { RiFileExcel2Fill } from "react-icons/ri";
import { FcTodoList } from "react-icons/fc";
import { Link } from "react-router-dom";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { downloadListaEstudiantesAsignaturaXlsx, evaluacionesDefinidas, materiaAsignatura } from "../../http/log";
import { useUsuario } from "../../context/UsuarioContext";
import { Form, FormControl, Modal, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { mostrarAlerta, mostrarAlertaDanger, mostrarAlertaInfoTopC, mostrarAlertaSuccess } from "../../alerts/Alerts";
import { asignaturaAvanzarFase, definirEvaluacion, definirEvaluacionAll, tiposEvaluacionNota } from "../../http/log_materia";
import ModalConfirm from "../modals/modalConfirm/ModalConfirm";
import { certificadoNota, certificadoNotaPDF, preActaPDF, preActaSegundaInstanciaPDF } from "../../http/log_libretas";



export const AsignaturaAsignadaCard = ({ data }) => {


    const { setSpinnerLoad } = useUsuario();
    const [modalEvaluacion, setModalEvaluacion] = useState(false);
    const [modalDefinirEvaluacion, setModalDefinirEvaluacion] = useState(false);
    const [modalAvanzarFase, setModalAvanzarFase] = useState(false);
    const [modalCertificadoNota, setModalCertificadoNota] = useState(false);
    const [dataFull, setDataFull] = useState({});
    const [load, setLoad] = useState(false);

    useEffect(() => {
        setLoad(true)
        materiaAsignatura(data?.codigo_asig, data?.gestion, data?.periodo).then(
            data => {
                setLoad(false)
                if (data != null) {
                    console.log(data);
                    setDataFull(data);
                }
            }
        )
    }, [data]);
    //**************** */


    const upDate = () => {
        setSpinnerLoad(true);
        materiaAsignatura(data?.codigo_asig, data?.gestion, data?.periodo).then(
            data => {
                if (data != null) {
                    console.log(data);
                    setDataFull(data);
                }
                setSpinnerLoad(false);
            }
        )
    }


    function downloadListaEstudiante(codigo, materia) {
        setSpinnerLoad(true);
        downloadListaEstudiantesAsignaturaXlsx({ code: codigo }, materia).then(
            data => {
                setSpinnerLoad(false);
            }
        );
    }

    function downloadPreActa(asig, ges, per,materia) {
        setSpinnerLoad(true);
        preActaPDF(asig, ges, per,materia).then(
            data => {
                setSpinnerLoad(false);
            }
        );
    }

    function downloadPreActaSegunda(asig, ges, per,materia) {
        setSpinnerLoad(true);
        preActaSegundaInstanciaPDF(asig, ges, per,materia).then(
            data => {
                setSpinnerLoad(false);
            }
        );
    }


    function cargarCertificadoNota(asig, ges, per) {
        setModalCertificadoNota(true);
    }


    const openModal = (d) => {
        setModalEvaluacion(true);
    }

    const verificFase = (mensaje) => {
        mostrarAlertaInfoTopC(mensaje);
    }

    const avanzarFase = () => {
        setModalAvanzarFase(true);
    }

    const avanzarFaseConfirm = () => {
        setSpinnerLoad(true);
        setModalAvanzarFase(false);
        asignaturaAvanzarFase(data?.codigo_asig, data?.gestion, data?.periodo).then(
            data => {
                if (data != null) {
                    console.log(data);
                }
                upDate();
                setSpinnerLoad(false);
            }
        )
    }

    const options = (mensajeFase, cerrada, definir, administrar, avanzar) => {
        if (definir === undefined) {
            return (<><div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div></>);
        }
        if (cerrada) {
            return (<></>);
        }

        return (
            <div className="text-center">
                {definir ?
                    <span onClick={() => setModalDefinirEvaluacion(true)} className="m-1 btn btn-success" title="Ver Lista"> Definir Evaluación</span>
                    : <></>}

                {administrar ?
                    <span onClick={() => openModal()} className="m-1 btn btn-info" >Administrar Calificaciones</span>
                    : <></>}

                {avanzar ?
                    (<span onClick={() => avanzarFase()} className="m-1 btn btn-warning" title="Ver Lista">
                        Avanzar Fase</span>)
                    :
                    (<span onClick={() => verificFase(mensajeFase)} className="m-1 btn btn-secondary" title="Avanzar de Fase">
                        Avanzar Fase</span>)
                }
            </div>
        );
    }


    return (
        <>
            <div className="card bg-white shadow mb-5  bg-body rounded border-0">
                <div className="card-body">
                    <h5 className="card-title roboto-condensed-regular text-center m-0">{data?.materia}</h5>
                    <p className="text-center"><strong>{data?.sigla}</strong></p>
                    <p className="card-text roboto-condensed-regular text-center">{data?.carrera}</p>
                </div>
                <div>

                    {load ?
                        <div className="text-center"><div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div></div>
                        : <ul className="list-group list-group-flush" style={{ fontSize: "11px" }}>
                            <li className="list-group-item p-3">
                                <div className="row">
                                    <div className="col-6 d-flex mb-2">
                                        <strong className="mr-1">Gestión:</strong>
                                        <strong className="w-100"></strong>
                                        <strong className="text-success">{data.gestion}</strong>
                                    </div>
                                    <div className="col-6 d-flex mb-2">
                                        <strong className="mr-1">Periodo:</strong>
                                        <strong className="w-100"></strong>
                                        <strong className="text-success">{data.periodo}</strong>
                                    </div>
                                    <div className="col-6 d-flex mb-2">
                                        <strong className="mr-1">Grupo:</strong>
                                        <strong className="w-100"></strong>
                                        <strong className="text-info">{data.grupo}</strong>
                                    </div>
                                    <div className="col-6 d-flex mb-2">
                                        <strong className="mr-1">Plan:</strong>
                                        <strong className="w-100"></strong>
                                        <strong className="text-warning">{data.plan_materia}</strong>
                                    </div>
                                    <div className="col-6 d-flex mb-2">
                                        <strong className="mr-1">Tipo Evaluación:</strong>
                                        <strong className="w-100"></strong>
                                        <strong className="text-primary m-auto">{dataFull.evaluacion}</strong>
                                    </div>
                                    <div className="col-6 d-flex mb-2">
                                        <strong className="mr-1">Nivel Académico:</strong>
                                        <strong className="w-100"></strong>
                                        <strong className="text-primary m-auto">{dataFull.nivel_academico ?? "-"}</strong>
                                    </div>

                                </div>
                            </li>
                            {Object.keys(dataFull).length !== 0 ? <li className="list-group-item m-0 p-2 d-flex">
                                {dataFull?.obs_estudiantes ? <div className="dropdown">
                                    <button className="btn btn-ligth dropdown-toggle" style={{ fontSize: "12px" }} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <strong >Estudiantes</strong>
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><span onClick={() => downloadListaEstudiante(data?.codigo_asig, data?.materia)} className="dropdown-item pointer" title="Descargar Lista en Excel"><RiFileExcel2Fill className="text-success" /> Descargar Lista</span></li>
                                        <Link to="detalle/" state={{ data: data?.codigo_asig, materia: data?.materia, carrera: data?.carrera }} className="dropdown-item" title="Ver Lista"><FcTodoList /> Ver Lista</Link>
                                    </ul>
                                </div> : <div className="dropdown">
                                    <button disabled className="btn btn-ligth dropdown-toggle" style={{ fontSize: "12px" }}>
                                        <strong >Estudiantes</strong>
                                    </button></div>}
                                <div className="w-100"></div>
                                {dataFull?.obs_libretas ? <div className="dropdown">
                                    <button className="btn btn-ligth dropdown-toggle" style={{ fontSize: "12px" }} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <strong >Pre-Actas</strong>
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><span className="dropdown-item pointer" onClick={() => downloadPreActa(data?.codigo_asig, data?.gestion, data?.periodo, data?.materia)}><BsFileEarmarkPdfFill className="text-danger" /> Pre-Acta</span></li>
                                        {dataFull?.obs_libretas_2da ?? false ? <li><span className="dropdown-item pointer" onClick={() => downloadPreActaSegunda(data?.codigo_asig, data?.gestion, data?.periodo, data?.materia)}><BsFileEarmarkPdfFill className="text-danger" /> Pre-Acta Segunda Instancia</span></li> : <></>}
                                    </ul>
                                </div> : <div className="dropdown">
                                    <button disabled className="btn btn-ligth dropdown-toggle" style={{ fontSize: "12px" }}>
                                        <strong >Pre-Actas</strong>
                                    </button></div>}
                                <div className="w-100"></div>
                                {dataFull?.obs_certificado ? <div className="dropdown">
                                    <button className="btn btn-ligth dropdown-toggle" style={{ fontSize: "12px" }} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <strong >Certificados</strong>
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><span className="dropdown-item pointer" onClick={() => cargarCertificadoNota(data?.codigo_asig, data?.gestion, data?.periodo)}>Certificación de Nota</span></li>
                                    </ul>
                                </div> : <div className="dropdown">
                                    <button disabled className="btn btn-ligth dropdown-toggle" style={{ fontSize: "12px" }}>
                                        <strong >Certificados</strong>
                                    </button></div>}
                            </li> : <></>}

                            <li className="list-group-item m-0 text-center">
                                <p className="mb-2" style={{ fontSize: "13px" }}><strong>{dataFull?.fase}</strong></p>
                                {options(dataFull?.desc_avance, dataFull?.cerrada, dataFull?.definir, dataFull?.administrar, dataFull?.avanzar)}
                            </li>
                        </ul>}
                </div>
            </div>
            <ModalEvaluacion
                show={modalEvaluacion}
                onHide={() => setModalEvaluacion(false)}
                data={dataFull}
                code={data?.codigo_asig ?? ""}
                codem={data?.codigo_mat ?? ""}
                ges={data?.gestion ?? ""}
                per={data?.periodo ?? ""}
            />


            <ModalDefinirEvaluacion
                show={modalDefinirEvaluacion}
                onHide={() => setModalDefinirEvaluacion(false)}
                onLoad={() => upDate()}
                data={dataFull}
                code={data?.codigo_asig ?? ""}
                ges={data?.gestion ?? ""}
                per={data?.periodo ?? ""}
            />

            <ModalConfirm
                show={modalAvanzarFase}
                onHide={() => setModalAvanzarFase(false)}
                titulo={"ATENCIÓN"}
                texto={"¿Está seguro en avanzar de fase?"}
                onConfirm={() => avanzarFaseConfirm()}
            />


            <ModalSelectCertificadoNota
                show={modalCertificadoNota}
                onHide={() => setModalCertificadoNota(false)}
                code={dataFull?.codigo_asig ?? ""}
                ges={dataFull?.gestion ?? ""}
                per={dataFull?.periodo ?? ""}
                data={dataFull}
            />
        </>
    );
}


const ModalEvaluacion = (props) => {

    const [dataEv, setDataEv] = useState([]);
    const [load, setLoad] = useState([]);

    const { show, code, codem, ges, per,onHide } = props;

    useEffect(() => {

        if (code !== "" && show) {
            setLoad(true);
            evaluacionesDefinidas(code, codem, ges, per).then(
                data => {
                    //console.log(data);
                    setLoad(false);
                    setDataEv(data);
                    if (data === null) {
                        onHide();
                    }
                }
            )
        }

    }, [show, code, codem, ges, per,onHide]);


    const listItems = dataEv?.map((data, index) =>
        <Link key={index} to="administrar_calificaciones/" state={{ eval: data, asig: props.data }} className="col-12 btn-ios rounded-bottom h5 m-0">
            {data.tipo_nota} - 	{data.ponderacion}%</Link>
    );

    return (

        <Modal
            size="sm"
            centered={true}
            show={props.show}
            onHide={() => props.onHide()}
            aria-labelledby="example-modal-sizes-title-sm"
        >
            <Modal.Body className="p-0">
                <div className='row col-12 m-0'>
                    {load ?
                        <div className="text-center my-3">
                            <div className="spinner-border text-info" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        : listItems}
                </div>
            </Modal.Body>
        </Modal>

    );
}


const ModalDefinirEvaluacion = (props) => {

    const [dataEv, setDataEv] = useState([]);
    const [load, setLoad] = useState(false);

    useEffect(() => {

        if (props.code !== "" && props.show) {
            tiposEvaluacionNota(props.code, props.ges, props.per).then(
                data => {
                    setDataEv(data);
                }
            )
        }

    }, [props.data, props.show, props.code, props.ges, props.per]);


    const listItems = dataEv?.map((data, index) =>
        <tr key={index} className="col-12 m-0">
            <td className="text-end">{data.tipo_nota}</td>
            <td>
                <FormControl
                    className="input-nota"
                    type="text"
                    defaultValue={0}
                    onChange={(e) => {
                        var v = 0;
                        if (!e.target.value.isNaN) {
                            v = parseInt(e.target.value);
                        }
                        dataEv[index]["ponderacion"] = v;
                    }}
                />{" %"}
            </td></tr>
    );

    const definirEval = async () => {

        var total = 0;
        dataEv?.forEach((data, index) => {
            total += data["ponderacion"];
        });

        if (total === 100) {
            var verif = true;
            var pondes="";
            for (var i = 0; i < dataEv.length; i++) {
                pondes+=dataEv[i]["ponderacion"]+"-"+dataEv[i]["id_tipo_nota"];
                if(i<dataEv.length-1){
                    pondes+="#";
                }
            }

            console.log(pondes);
            setLoad(true);
            var verif = await definirEvaluacionAll(props.code, props.ges, props.per,pondes);
            setLoad(false);
            if (verif) {
                props.onLoad();
            }
            props.onHide();

        } else {
            mostrarAlertaDanger("El total de la suma de las evaluaciones debe ser igual a 100");
        }
    }

    return (

        <Modal
            size="sm"
            centered={true}
            show={props.show}
            onHide={() => props.onHide()}
            aria-labelledby="example-modal-sizes-title-sm"
        >
            <Modal.Body className="p-0">
                {load?<div className="text-center mt-5 mb-5"><div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Cargando...</span>
                        </div></div>:
                <>
                <p className='text-center mt-3 ms-1 me-1 '><strong className=''>Definir Evaluaciones</strong></p>
                <p className='font13 text-center mb-3'>
                    {props.data["fase"]}
                </p>
                <div className='row col-12 m-0'>
                    <Table size="sm" className="m-0">
                        <tbody>
                            {listItems}
                        </tbody>
                    </Table>
                    <span onClick={(e) => props.onHide()} className="col-6 btn-ios rounded-bottom h6 m-0">Cancelar</span>
                    <span onClick={(e)=>definirEval()} className="col-6 btn-ios rounded-bottom h6 m-0">Guardar</span>
                </div>
                </>}
            </Modal.Body>
        </Modal>

    );
}


const ModalSelectCertificadoNota = (props) => {

    const { setSpinnerLoad } = useUsuario();

    const [dataNotas, setDataNotas] = useState([]);
    const [load, setLoad] = useState([]);
    const [select, setSelect] = useState({});

    const [nota, setNota] = useState(0);
    const [aprobado, setAprobado] = useState();
    const [motivo, setMotivo] = useState();


    const { show, code, ges, per ,onHide} = props;
    useEffect(() => {

        if (code !== "" && show) {
            setSelect({});
            setAprobado("Aprobado");
            setMotivo("Omision o error involutario en la calificacion o transcripcion de las Notas");

            setLoad(true);
            certificadoNota(code, ges, per).then(
                data => {
                    setLoad(false);
                    setDataNotas(data);
                    if (data === null) {
                        onHide();
                    }
                }
            )
        }

    }, [show, code, ges, per,onHide]);

    const selecionarEstudiante = (d) => {
        setSelect(d);
        setNota(d.nota);
    }

    const confirmar = () => {
        setSpinnerLoad(true);
        certificadoNotaPDF(props.data.codigo_asig, props.data.gestion, props.data.periodo, select.id_estudiante, nota, aprobado, motivo).then(
            data => {
                setSpinnerLoad(false);
                if (data) {
                    props.onHide();
                    mostrarAlertaSuccess("Documento generado con éxito");
                } else {
                    mostrarAlerta("Ocurrio un error, vuelva a intentarlo")
                }
            }
        );

    }

    const listItems = dataNotas?.map((data, index) =>
        <tr key={index}>
            <td>
                {index + 1}
            </td>
            <td>
                {data.id_estudiante}
            </td>
            <td>
                {data.nombres}
            </td>
            <td className="fw-bold">
                {data.nota}
            </td>
            <td>
                <button className="btn btn-success btn-sm" onClick={() => selecionarEstudiante(data)}>selecionar</button>
            </td>
        </tr>
    );

    return (

        <Modal
            size="md"
            centered={true}
            show={props.show}
            onHide={() => props.onHide()}
            aria-labelledby="example-modal-sizes-title-sm"
        >
            <Modal.Header closeButton>
                <Modal.Title>{Object.keys(select).length > 0 ? "Confirme los Datos" : "Seleccione un Estudiante"} <br /><p className="h6 m-0 fw-bold text-muted ms-2">{props.data.materia}</p></Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <div className='row col-12 m-0'>
                    {load ?
                        <div className="text-center my-3">
                            <div className="spinner-border text-info" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        : Object.keys(select).length > 0 ?
                            <><div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">Estudiante::</label>
                                    <p className="ms-3"><strong>{select.nombres}</strong></p>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">R.U::</label>
                                    <p className="ms-3"><strong>{select.id_estudiante}</strong></p>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">Nota::</label>
                                    <FormControl
                                        className={""}
                                        type="text"
                                        value={nota}
                                        placeholder={nota}
                                        onChange={(e) => setNota(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">Aprobado::</label>
                                    <Form.Select aria-label="" onChange={(e) => setAprobado(e.target.value)}>
                                        <option value="Aprobado">Aprobado</option>
                                        <option value="Reprobado">Reprobado</option>
                                    </Form.Select>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">Motivo::</label>
                                    <Form.Select aria-label="" onChange={(e) => setMotivo(e.target.value)}>
                                        <option value="Omision o error involutario en la calificacion o transcripcion de las Notas">Omision o error involutario en la calificacion o transcripcion de las Notas</option>
                                        <option value="Extravio de pruebas escritas">Extravio de pruebas escritas</option>
                                        <option value="Duplicidad del nombre del estudiante">Duplicidad del nombre del estudiante</option>
                                        <option value="Error en la ponderacion de notas parciales y/o finales">Error en la ponderacion de notas parciales y/o finales</option>
                                        <option value="Matriculacion Extemporanea">Matriculacion Extemporanea</option>
                                    </Form.Select>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary" onClick={() => confirmar()}>REGISTRAR</button>
                                </div>
                                <hr className="mt-3" />
                                <div className="m-3">
                                    <p><strong>NOTA:: Sr.(a) Usuario Docente, tenga presente lo siguiente ::</strong></p>
                                    <p className="text-primary">* La nota introducida, se registrara como un pre-registro de una nota adicional</p>
                                    <p className="text-primary">* La nota debe ser entre 0 y 100 puntos..</p>
                                    <p className="text-primary">* Solamente si esta seguro de lo introducido, presione sobre la opcion "Registrar"</p>
                                </div>
                            </div></>
                            :
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>RU</th>
                                        <th>Nombre</th>
                                        <th>Nota</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listItems}
                                </tbody>
                            </table>}
                </div>
            </Modal.Body>
        </Modal>

    );
}
