import './hojaImpresion.css';

function HojaImpresion({encabezado,cuerpo}){
    return (
        <div className='hoja-impresion'>
            <table className='table table-borderless'>
                <thead>
                    <tr>
                        <td>
                            {encabezado}
                        </td>
                    </tr>    
                </thead> 
                <tbody>
                    <tr>
                        <td>
                            {cuerpo}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default HojaImpresion;