import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useUsuario } from "../../context/UsuarioContext";
import logoUniMovil from '../../assets/img/UniMovil.png';
import pagoQRImg from '../../assets/img/CodigoQr.png';
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from 'react-icons/io';
import { GiCheckMark } from 'react-icons/gi';
import { peticionGetAbiertaAuthHttp } from "../../http/log_general";
import { FaCheckCircle, FaMoneyBillWave } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { confirmarPagoCertificadosHttp, listaCertificadosPagoHttp } from "../../http/log_certificados_pago";
import { mostrarAlerta } from "../../alerts/Alerts";
import PagoQr from "../../components/pago/PagoQr";
import PagoCpt from "../../components/pago/PagoCpt";
import TutorialPagoQr from "../../components/pago/TutorialPagoQr";
import TutorialPagoCpt from "../../components/pago/TutorialPagoCpt";

const ComprarCertificado = props => {

    const navigate = useNavigate();


    const { setSpinnerLoad } = useUsuario();
    const [periodoGestion, setPeriodoGestion] = useState({});
    const [modalPago, setModalPago] = useState(false);
    const [modalPreview, setModalPreview] = useState(false);
    const [selectedCert, setSelectedCert] = useState({});


    //inicar haciendo una peticion http para obtener datos
    const [certificados, setCertificados] = useState([]);
    useEffect(() => {
        listaCertificadosPagoHttp().then((data) => {
            if (data != null) {
                setCertificados(data);
            }
        });
    }, []);
    //**************** */

    const load = (d) => {
        console.log(d);
        setSpinnerLoad(true);
        /*materiasDocentePG(d).then(
            data => {
                console.log(data);
                setMaterias(data?.data);
                setPeriodoGestion(d);
                setSpinnerLoad(false);
            }
        )*/
    }

    const comprarCertificadoModal = (d) => {
        setModalPago(true);
        setSelectedCert(d);
    }

    const selectModalPreview = (d) => {
        setSelectedCert(d);
        setModalPreview(true);
    }

    const listItems = certificados?.map((data, index) =>
        <div key={index} className="col col-sx-6 col-ms-5 col-md-4 col-lg-3 col-12 mt-5">
            <div className="card h-100 bg-white bg-body rounded">
                <div className="card-body text-center">
                    <h3 className="card-title roboto-condensed-regular text-center m-0 mb-3">{data.nombre}</h3>
                    <img src={data.img} className="mb-3" style={{ 'height': '50px' }} />
                    <h1 className="text-center"><strong>{data.costo} bs.</strong></h1>
                    <div className="">{data.descripcion}</div>
                </div>
                <ul className="list-group list-group-flush" >
                    <li className="list-group-item p-3">
                        <div className="row">
                            <div className="col-6 text-center">
                                <button type="button" className="btn btn-primary" onClick={() => selectModalPreview(data)}>Previsualización</button>
                            </div>
                            <div className="col-6 text-center">
                                <button type="button" className="btn btn-warning" onClick={() => comprarCertificadoModal(data)}>Comprar 50bs</button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );

    return (
        <div className="asignaturas">
            <div className='container'>
                <div className='row p-2'>
                    <div className="col-12 mt-3 mb-3">
                        <p className="h5 m-0">Comprar Certificado</p>
                        <p className="roboto-condensed-regular h2">{"Certificados Habilitados"}</p>
                    </div>

                    <div className=' col-12 col-lg-12'>
                        <div className='card bg-white shadow mb-5 bg-body rounded border-0'>
                            <div className="col-12 text-end p-3">
                                <button type="button" className="btn btn-info" onClick={() => navigate("/listBuyCertificados")}>Ver Certificados Comprados</button>
                            </div>
                            <hr className="m-0" />
                            <div className="card-body col-12 pb-5">
                                <div className="row justify-content-evenly">
                                    {certificados?.length !== 0 ? <>{listItems}</> : <div>
                                        <div className='text-center'>
                                            <p className=" mt-4 mb-2">No se encontraron certificaciones habilitadas</p>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalRealizarPago2
                show={modalPago}
                onHide={() => setModalPago(false)}
                onLoad={(v) => load(v)}
                info={selectedCert}
            />

            <ModalPreviewDocument
                show={modalPreview}
                onHide={() => setModalPreview(false)}
                onLoad={(v) => load(v)}
                info={selectedCert}
            />

        </div>
    );
}


function ModalRealizarPago(props) {


    const [step, setStep] = useState(0);
    const [select, setSelect] = useState('');
    const [inst, setInst] = useState('');
    const [confirmado, setConfirmado] = useState(false);
    const [codigoPago, setCodigoPago] = useState('');
    const [loadCodigo, setLoadCodigo] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {
        if (props.show) {
            setStep(0);
        }
    }, [props.show]);

    const cambiar = () => {
        //props.onLoad({ "periodo": periodo, "gestion": gestion });
        props.onHide();
    }

    const avanzar = (p) => {
        setSelect(p)
        setStep(step + 1);
    }
    const volver = () => {
        setStep(step - 1);
    }


    const confirmarPago = (metodo) => {
        if (inst && metodo && props.info.codigo) {
            setLoadCodigo(true);
            confirmarPagoCertificadosHttp(props.info.codigo, metodo, inst).then((d) => {
                if (d != null) {
                    setStep(3);
                    setCodigoPago(d.codigo_pago);
                }
                setLoadCodigo(false);
            });
        } else {
            mostrarAlerta("no selecciono un metodo de pago o no registró correctamente el nombre de la institución")
        }
    }


    const stepsView = () => {

        switch (step) {
            case 0:
                return <>
                    <div className="m-3">
                        <div className="text-center mb-3 roboto-condensed-regular">
                            <h5>Ingrese el nombre de la institucion a la que dirigirá el certificado</h5>
                        </div>
                        <Row>
                            <Col sm={12} className="mb-3">
                                <div className="mb-1 mt-3" >
                                    <input type="text"
                                        className="form-control text-center w-100 p-2 fs-4"
                                        placeholder="Nombre de la institución"
                                        value={inst}
                                        onChange={(e) => setInst(e.target.value)}
                                    />
                                </div>
                                <p className="text-danger">*Debe ingresar el nombre completo de la institución a la que presentará el certificado, el nombre no debe ser una abreviado</p>

                            </Col>
                            <Col sm={12} className="text-center mt-3 mb-4">
                                <button className="btn btn-warning pe-5 ps-5" onClick={() => props.onHide()}>Salir</button>
                                <button className="ms-3 btn btn-primary pe-5 ps-5" onClick={() => avanzar()}>Continuar <IoMdArrowRoundForward /></button>
                            </Col>
                        </Row>
                    </div>
                </>
            case 1:
                return <>
                    <div className="m-3">
                        <div className="text-center mb-3 roboto-condensed-regular">
                            <h5>Seleccione la forma de pago</h5>
                        </div>
                        <Row>
                            <Col sm={12}>
                                <div className="card mb-3  btn-light pointer" onClick={() => avanzar('CPT')}>
                                    <div className="row g-0">
                                        <div className="col-md-3 d-flex">
                                            <img src={logoUniMovil} className="img-fluid rounded-start m-auto" alt="CTP" style={{ 'width': '100px' }} />
                                        </div>
                                        <div className="col-md-9">
                                            <div className="card-body text-start">
                                                <h5 className="card-title fw-bold">CPT - UniMovil plus</h5>
                                                <p className="card-text">Este pago se lo realiza mediante un codigo que se genera el cual debe ser ingresando en la aplicación de UniMovil Plus del Banco Unión.</p>
                                                <p className="card-text"><small className="text-muted">Necesitas tener instalada la aplicación de Banco Unión</small></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="card mb-3  btn-light pointer" onClick={() => avanzar('QR')}>
                                    <div className="row g-0">
                                        <div className="col-md-3 d-flex">
                                            <img src={pagoQRImg} className="img-fluid rounded-start m-auto" alt="QR" style={{ 'width': '100px' }} />
                                        </div>
                                        <div className="col-md-9">
                                            <div className="card-body  text-start">
                                                <h5 className="card-title fw-bold">Pago por QR</h5>
                                                <p className="card-text">Los pagos mediante el Codigo QR pueden ser realizados desde cualquier aplicación bancaria que tengan la opción de pago por QR.</p>
                                                <p className="card-text"><small className="text-muted">Imagen QR solo de referencias</small></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} className="text-center mt-3 mb-4">
                                <button type="button" className="btn btn-secondary me-3" onClick={() => volver()}><IoMdArrowRoundBack /> Volver</button>
                                <button className="btn btn-warning pe-5 ps-5" onClick={() => props.onHide()}>Salir</button>
                            </Col>
                        </Row>
                    </div>
                </>
            case 2:
                return <>
                    <div className="m-3">
                        {select === 'QR' ?
                            <div>
                                <h3 className="text-center fw-bold mb-3">Ha seleccionado pago por QR</h3>
                                <p className="ms-3 me-3">Una vez confirmado el tipo de pago, se generará y se mostrará un codigo QR. Antes de confirmar verifique si dispone lo necesario para proceder con el pago, detallado en la siguiente lista:</p>
                                <div className="p-4">
                                    <div className="fw-bold m-1"><span className="text-success me-3"><FaCheckCircle /></span> Tener una conexión estable a internet</div>
                                    <div className="fw-bold m-1"><span className="text-success me-3"><FaCheckCircle /></span> Tener disponible y acceso a una aplicacion bancaria con la opción pagos QR</div>
                                </div>
                                <div className="text-center mt-3 pb-4">
                                    <button type="button" className="btn btn-secondary me-3" onClick={() => volver()}><IoMdArrowRoundBack /> Volver</button>
                                    <button type="button" className="btn btn-primary" onClick={() => confirmarPago('QR')}><GiCheckMark /> Confirmar Método de Pago</button>
                                </div>
                            </div>
                            :
                            <div>
                                <h3 className="text-center fw-bold">Ha seleccionado pago por CPT</h3>
                                <h5 className="text-center fw-bold mb-3">UniMovil - UniNet (Banco Unión)</h5>
                                <p className="ms-3 me-3">Una vez confirmado el tipo de pago, se generará y se mostrará un codigo CPT (código de pago de tramite). Antes de confirmar verifique si dispone lo necesario para proceder con el pago, detallado en la siguiente lista:</p>
                                <div className="p-4">
                                    <div className="fw-bold m-1"><span className="text-success me-3"><FaCheckCircle /></span> Tener una conexión estable a internet</div>
                                    <div className="fw-bold m-1"><span className="text-success me-3"><FaCheckCircle /></span> Tener acceso a la aplicacion UniMovil o plataforma UniNet del Banco Unión</div>
                                </div>
                                <div className="text-center mt-3 pb-4">
                                    <button type="button" className="btn btn-secondary me-3" onClick={() => volver()}><IoMdArrowRoundBack /> Volver</button>
                                    <button type="button" className="btn btn-primary" onClick={() => confirmarPago('CPT')}><GiCheckMark /> Confirmar Método de Pago</button>
                                </div>
                            </div>
                        }
                    </div>
                </>
            case 3:
                return <>
                    {
                        select === 'QR' ?
                            <PagoQr
                                qrImg={codigoPago}
                            />
                            :
                            <PagoCpt
                                cptCode={codigoPago}
                            />
                    }

                    <Col sm={12} className="text-center mt-3 mb-4">
                        <button className="btn btn-danger pe-5 ps-5" onClick={() => props.onHide()}>Finalizar Proceso</button>
                        <button type="button" className="btn btn-info ms-5" onClick={() => navigate("/listBuyCertificados")}>Ver Certificados Comprados</button>

                    </Col>
                </>
            default:
                return <>:(</>

        }
    }

    return (
        <Modal size="lg" show={props.show} onHide={props.onHide}
            backdrop={"static"}
            keyboard={false}
            centered>
            <Modal.Body className="p-0" >
                <div className="text-center m-3 roboto-condensed-regular">
                    <h3>{props.info.nombre}</h3>
                </div>
                <hr />
                {loadCodigo ?
                    <div className="m-3 text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Cargando...</span>
                        </div>
                    </div>
                    : stepsView()}

            </Modal.Body>
        </Modal>
    );
}


function ModalRealizarPago2(props) {


    const { infoBasicData } = useUsuario();

    const [step, setStep] = useState(0);
    const [select, setSelect] = useState('');
    const [inst, setInst] = useState('');
    const [email, setEmail] = useState('');
    const [urlPago, setUrlPago] = useState('');
    const [loadCodigo, setLoadCodigo] = useState(false);
    const [clickPago, setClickPago] = useState(false);

    const [instruction, setInstruction] = useState("");

    const navigate = useNavigate();


    useEffect(() => {
        if (props.show) {
            setStep(0);
            setClickPago(false);
            setEmail("");
            setInst("");
        }
    }, [props.show]);


    const avanzar = (p) => {
        setSelect(p)
        setStep(step + 1);
    }
    const volver = () => {
        setStep(step - 1);
    }


    const confirmarPago = (metodo) => {
        if (inst && email && props.info.codigo) {
            setLoadCodigo(true);
            confirmarPagoCertificadosHttp(props.info.codigo, inst,email).then((d) => {
                if (d != null) {
                    avanzar();
                    setUrlPago(d.url_redirect);
                }
                setLoadCodigo(false);
            });
        } else {
            mostrarAlerta("No registró correctamente el nombre de la institución o no selecionó un correo electrónico")
        }
    }

    const stepsView = () => {

        switch (step) {
            case 0:
                return <>
                    <div className="m-5">
                        <div className="text-center mb-2 roboto-condensed-regular">
                            <h5>Ingrese el nombre de la institucion a la que dirigirá el certificado</h5>
                        </div>
                        <Row>
                            <Col sm={12} className="mb-3">
                                <div className="mb-1 mt-3" >
                                    <input type="text"
                                        className="form-control text-center w-100 p-2 fs-4"
                                        placeholder="Nombre de la institución"
                                        value={inst}
                                        onChange={(e) => setInst(e.target.value)}
                                    />
                                </div>
                                <p className="text-danger">*Debe ingresar el nombre completo de la institución a la que presentará el certificado, el nombre no debe estar abreviado</p>

                            </Col>
                            <Col sm={12} className="mt-5">    
                                <div className="text-center mb-2 roboto-condensed-regular">
                                    <h5>Seleccione un correo electronico para recibir su certificado digital</h5>
                                </div>
                            </Col>
                            <Col sm={12} className="mb-3">

                                <div className="mb-1 mt-3" >
                                    
                                <Form.Select
                                    className="form-control text-center w-100 p-2 fs-4" 
                                    onChange={(e)=>setEmail(e.target.value)}>
                                        <option value={""}>Seleccione una opción</option>
                                        {
                                            infoBasicData?.correos.map((d,i)=><option value={d} key={i}>{d}</option>)
                                        }
                                    
                                </Form.Select>
                                
                                </div>
                            </Col>
                            <Col sm={12} className="text-center mt-3 mb-4">
                                <button className="btn btn-warning pe-5 ps-5" onClick={() => props.onHide()}>Salir</button>
                                <button className="ms-3 btn btn-primary pe-5 ps-5" onClick={() => avanzar()}>Continuar <IoMdArrowRoundForward /></button>
                            </Col>
                        </Row>
                    </div>
                </>
            case 1:
                return <>
                    <div className="m-3">
                        <div className="text-center mb-3 roboto-condensed-regular">
                            <h5>Métodos de pago habilitados</h5>
                        </div>
                        <Row>
                            <Col sm={12} md={6}>
                                <div className="card mb-3">
                                    <div className="row g-0">
                                        <div className="col-md-3 d-flex">
                                            <img src={pagoQRImg} className="img-fluid rounded-start m-auto" alt="QR" style={{ 'width': '100px' }} />
                                        </div>
                                        <div className="col-md-9">
                                            <div className="card-body  text-start">
                                                <h5 className="card-title fw-bold">Pago por QR</h5>
                                                <p className="card-text">Los pagos mediante el Codigo QR pueden ser realizados desde cualquier aplicación bancaria que tengan la opción de pago por QR.</p>
                                                <p className="card-text"><small className="text-muted">Imagen QR solo de referencias</small>
                                                    {instruction === "QR" ? <Button size="sm" variant="link" className="ms-3" onClick={() => setInstruction("")}>Dejar de ver Instrucciones de pago</Button>
                                                        : <Button size="sm" variant="link" className="ms-3" onClick={() => setInstruction("QR")}>Ver Instrucciones de pago</Button>
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="card mb-3">
                                    <div className="row g-0">
                                        <div className="col-md-3 d-flex">
                                            <img src={logoUniMovil} className="img-fluid rounded-start m-auto" alt="CTP" style={{ 'width': '100px' }} />
                                        </div>
                                        <div className="col-md-9">
                                            <div className="card-body text-start">
                                                <h5 className="card-title fw-bold">CPT - UniMovil plus</h5>
                                                <p className="card-text">Este pago se lo realiza mediante un codigo que se genera el cual debe ser ingresando en la aplicación de UniMovil Plus del Banco Unión.</p>
                                                <p className="card-text"><small className="text-muted">Necesitas tener instalada la aplicación de Banco Unión</small>
                                                    {instruction === "CPT" ? <Button size="sm" variant="link" className="ms-3" onClick={() => setInstruction("")}>Dejar de ver Instrucciones de pago</Button>
                                                        : <Button size="sm" variant="link" className="ms-3" onClick={() => setInstruction("CPT")}>Ver Instrucciones de pago</Button>
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="bg-light">
                                    {instruction === "CPT" ? <TutorialPagoCpt /> : <></>}
                                    {instruction === "QR" ? <TutorialPagoQr /> : <></>}
                                </div>
                            </Col>
                            <Col sm={12} className="text-center mt-3 mb-4">
                                <button type="button" className="btn btn-secondary me-3" onClick={() => volver()}><IoMdArrowRoundBack /> Volver</button>
                                {/*<button className="btn btn-warning pe-5 ps-5" onClick={() => props.onHide()}>Salir</button>
                                */}
                                <button type="button" className="btn btn-primary" onClick={() => confirmarPago()}><GiCheckMark /> Confirmar Compra</button>
                            </Col>
                        </Row>

                    </div>
                </>
            case 2:
                return <>
                    <div className="m-3">

                        <Col className="text-center">
                            <a className={"btn btn-warning px-5 py-3 fs-4"} href={urlPago} target="_blank" onClick={() => setClickPago(true)}><span><FaMoneyBillWave/> REALIZAR PAGO </span></a>
                        </Col>

                        {clickPago ? <Col sm={12} className="text-center mt-3 mb-4">
                            <button className="btn btn-danger pe-5 ps-5" onClick={() => props.onHide()}>Finalizar Proceso</button>
                            <button type="button" className="btn btn-info ms-5" onClick={() => navigate("/listBuyCertificados")}>Ver Certificados Comprados</button>
                        </Col> : <></>}
                    </div>
                </>
            case 3:
                return <>
                    <Col sm={12} className="text-center mt-3 mb-4">
                        <button className="btn btn-danger pe-5 ps-5" onClick={() => props.onHide()}>Finalizar Proceso</button>
                        <button type="button" className="btn btn-info ms-5" onClick={() => navigate("/listBuyCertificados")}>Ver Certificados Comprados</button>

                    </Col>
                </>
            default:
                return <>:(</>

        }
    }

    return (
        <Modal size="lg" show={props.show} onHide={props.onHide}
            backdrop={"static"}
            keyboard={false}
            centered>
            <Modal.Body className="p-0" >
                <div className="text-center m-3 roboto-condensed-regular">
                    <h3>{props.info.nombre}</h3>
                </div>
                <hr />
                {loadCodigo ?
                    <div className="m-3 text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Cargando...</span>
                        </div>
                    </div>
                    : stepsView()}

            </Modal.Body>
        </Modal>
    );
}

const ModalInstruccion = (props) => {
    return (
        <Modal size="lg" show={props.show} onHide={props.onHide}
            centered>
            <Modal.Body className="p-0" >
                <TutorialPagoQr />
            </Modal.Body>
        </Modal>
    );
}

function ModalPreviewDocument(props) {

    const [doc, setDoc] = useState('');

    useEffect(() => {
        setDoc('');
        if (props.show) {
            peticionGetAbiertaAuthHttp(props.info.preview).then((a) => {
                console.log(a.html);
                setDoc(a.html);
            });
        }
    }, [props.show]);

    return (
        <Modal size="lg" show={props.show} onHide={props.onHide}
            centered>
            <Modal.Body className="p-0" >
                <div className="text-center m-3 roboto-condensed-regular">
                    <h3>{props.info.nombre}</h3>
                </div>
                <hr />
                <div className="m-3">
                    <div className="text-center mb-3 roboto-condensed-regular">
                        <h5>Previsualización de Certificado</h5>
                    </div>
                    <Row>
                        <Col sm={12}>
                            <div className="card mb-3 p-3 border-primary" dangerouslySetInnerHTML={{ __html: doc }} />
                        </Col>
                    </Row>
                </div>
                <hr />
            </Modal.Body>
        </Modal>
    );
}

export default ComprarCertificado;