import React, { useState } from "react";

const SideContext = React.createContext();

export function SideProvider(props){

  const [showSide, setShowSide]=useState(false);

      const value = React.useMemo(()=>{
        return({
          showSide, 
          setShowSide
        });
      },[showSide]);

      return <SideContext.Provider value={value} {...props} />
}

export function useShowSide(){
    const context = React.useContext(SideContext);
    if(!context){
        throw new Error('useUsuario debe estar dentro del proveedor SideContext');
    }
    return context;
}