import { useEffect, useState } from "react";
import { AsignaturaAsignadaCard } from "../../components/asignaturaAsignadaCard/AsignaturaAsignadaCard";
import { useUsuario } from "../../context/UsuarioContext";
import { horarioDocente, materiasDocentePG } from "../../http/log";
import { IoMdAlert } from "react-icons/io";

const HorariosPage = (props) => {
  const { setSpinnerLoad } = useUsuario();
  const [periodoGestion, setPeriodoGestion] = useState({});

  const [tablaHorario, setTablaHorario] = useState("");
  const [data, setData] = useState({ lista: [] });

  //inicar haciendo una peticion http para obtener datos
  const [materias, setMaterias] = useState([]);
  useEffect(() => {
    setSpinnerLoad(true);
    horarioDocente().then((data) => {
      if (data) {
        setTablaHorario(data.tabla);
        setData(data);
      }
      console.log("-------------------");
      console.log(data);
      setSpinnerLoad(false);
    });
  }, []);
  //**************** */

  const load = (d) => {
    console.log(d);
    setSpinnerLoad(true);
    materiasDocentePG(d).then((data) => {
      console.log(data);
      setMaterias(data?.data);
      setPeriodoGestion(d);
      setSpinnerLoad(false);
    });
  };

  const listItems = materias?.map((data, index) => (
    <div key={index} className="col col-md-6 col-lg-4 col-12 ">
      <AsignaturaAsignadaCard data={data} />
    </div>
  ));

  return (
    <div className="asignaturas">
      <div className="container">
        <div className="row p-2">
          <div className="col-12 mt-3 mb-3">
            <p className="h5 m-0">{"Asignaturas Vigentes"}</p>
            <p className="roboto-condensed-regular h2">{"Horario"}</p>
          </div>
          <div className=" col-12 col-lg-12">
            <div className="row ">
              <div className=" card p-4 pt-5 rounded">
                {data?.mensaje ?? false ? (
                  <div className="alert mb-4 alert-warning d-flex">
                    <IoMdAlert className="h3 m-0 text-info me-2" />
                    <p className="fw-semibold">{data?.mensaje}</p>
                  </div>
                ) : (
                  <></>
                )}
                <div className="">
                  <div
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: tablaHorario,
                    }}
                  />
                </div>
                {Object.keys(tablaHorario ?? {}).length > 0 ? (
                  <div className="mb-3">
                    <p className="fw-semibold">
                      Se encontraron {data?.totalMaterias} asignaciones de aulas
                      de {data?.materias?.length} materias programadas
                    </p>
                    {/*<div className="fs-1 ms-3">
                    {data?.materias.map((d, i) => (
                      <p
                        key={i}
                        className={`${
                          d.tiene ? "" : "text-decoration-line-through"
                        }`}
                      >
                        {d.materia}
                      </p>
                    ))}
                  </div>*/}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorariosPage;
