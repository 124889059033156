import { useEffect, useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import { BiBookAlt } from "react-icons/bi";
import { searchAccessElibro, searchLibro } from "../../http/log";
import './biblioteca.css';
import logBiblio from "../../assets/img/biblioteca.png"
import { Link } from "react-router-dom";
import { TbArrowBigUpLinesFilled } from "react-icons/tb";

function Biblioteca() {


    const [texto, setText] = useState("");
    const [busquedaData, setBusquedaData] = useState([]);

    const [userText, setUserText] = useState("-");
    const [passText, setPassText] = useState("-");

    useEffect(() => {
        cargarCredens();
    }, []);

    function buscar() {
        if (texto !== "" && texto !== " ") {
            searchLibro({ texto: texto }).then(
                data => {
                    console.log(data);
                    if (data != null) {
                        setBusquedaData(data);
                    }
                }
            );
        }
    }

    const cargarCredens = () => {

        searchAccessElibro({}).then(
            data => {
                if(data){
                    setPassText(data.pass);
                    setUserText(data.correo);
                }
            }
        );
    }



    const listItems = busquedaData.map((data, index) =>
        <div key={index}>
            <div className="col-12 busq" >
                <p className="roboto-condensed-regular h4"><BiBookAlt />{data.titulo} <span className="font12">( {data.bvTipoDocumento.documento} )</span></p>
                <p className="h5">{data.autor} - <strong>{data.codLibro}</strong></p>
                <p className="font12">{data.claves}</p>
                <p className="font12 text-end roboto-medium">{data.bvBiblioteca.nombres}</p>
            </div>
            <hr className="mt-3 mb-3" />
        </div>
    );

    return (
        <div className="biblioteca">
            <div className='container'>
                <div className='row p-2'>
                    <div className='col-12 mt-4 mb-2 text-start'>
                        <h3>Biblioteca Digital</h3>
                    </div>
                    <div className=' col-12 col-lg-12'>
                        <div className='bg-white shadow mb-5 p-3 bg-body rounded'>
                            <div className="row">
                                
                            <div className="col-12  mb-3">
                                <p className="m-0">Para ingresar a la <strong>Biblioteca Digital</strong> Elibro, se le asignó un usuario temporalmente, debe copiar las credenciales e ingresar a la plataforma en el botón de abajo. si presenta problemas con este acceso dale <Link onClick={()=>cargarCredens()}>click aquí</Link> para generar otro. </p>
                                <hr className="mt-2" />
                                <div className="text-end mt-2 me-2">
                                    <img src={"https://www.elibro.com/img/logos/elibro@2x.png"} className="img-fluid" alt="eLibro" style={{maxWidth:"150px"}}/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 row p-3">
                                <div className="col-lg-12 col-sm-12 row">
                                    <div className="col-lg-3 col-sm-12 text-secondary">Usuario:</div>
                                    <div className="col-lg-9 col-sm-12 fs-4 fw-bold">{userText}</div>
                                </div>
                                <div className="col-lg-12 col-sm-12 row">
                                    <div className="col-lg-3 col-sm-12 text-secondary">Contraseña:</div>
                                    <div className="col-lg-9 col-sm-12 fs-4 fw-bold">{passText}</div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-12 text-center p-3">
                                <Link to={"https://elibro.net/es/lc/uapbiblioteca/inicio/"} target="blank" className="btn btn-light mt-3 pe-4 ps-4">
                                    <img src={logBiblio} style={{maxWidth:"300px"}} className="img-fluid" alt="Dale Click Aquí"/>
                                </Link>
                                <div className="text-danger text-center fw-bold fs-1"><TbArrowBigUpLinesFilled  /></div>
                                <div className="text-danger text-center fw-bold">Ingresa Aquí</div>
                                <p className="text-secondary" style={{fontSize:"10px"}}>**Recuerda cerrar sesión cuando salgas de la Biblioteca Digital</p>
                            </div>
                            <div className="col-12">
                                <Link onClick={()=>cargarCredens()}>Generar otro acceso</Link>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 mt-2 mb-2 text-start'>
                        <h3>Bibliotecas de la UAP</h3>
                    </div>
                    <div className=' col-12 col-lg-12'>
                        <div className='bg-white shadow mb-5 p-3 bg-body rounded '>
                            <div className="col-12">
                                <Form className="d-flex">
                                    <FormControl
                                        type="search"
                                        placeholder="Busqueda de material bibliográfico"
                                        className="me-2"
                                        aria-label="Search"
                                        onChange={(e) => setText(e.target.value)}
                                    />
                                    <Button variant="outline-success" onClick={() => buscar()}>Buscar</Button>
                                </Form>
                                <hr className="mt-3" />
                                <p className='text-end mb-3 font12'>{busquedaData.length} coincidencias encontradas</p>
                            </div>
                            <div className="col-12">
                                {listItems}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Biblioteca;