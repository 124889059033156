import { Modal } from "react-bootstrap";

function ModalInfoOk(props){

    return(
        <Modal size="sm" show={props.show} onHide={props.onHide} 
        centered>
        <Modal.Body>
          <div closeButton className="text-center roboto-condensed-regular m-1 mb-3"><p>{props.titulo}</p></div>
          <div className="text-center  mb-3">{props.texto}</div>
          
        </Modal.Body>
          

          
        <Modal.Footer className='p-0'>
            <div className='row col-12 m-0'>
              <span onClick={props.onHide} className='col-12 btn-ios rounded-bottom'>Ok</span>
            </div>
        </Modal.Footer>

      </Modal>
    );
}

export default ModalInfoOk;