const StepCard = (props) => {

    return (<div className="card m-auto">
        <div className="row g-0">
            <div className="col-md-3 d-flex p-2">
                <img src={props.img} className="img-fluid rounded-start m-auto" alt="QR" />
            </div>
            <div className="col-md-9">
                <div className="card-body  text-start p-2">
                    <h5 className="card-title fw-bold fs-6">{props.titulo}</h5>
                    <p className="card-text font12">{props.detalle}</p>
                    <p className="card-text"><small className="text-muted">{props.aclariacion}</small></p>
                </div>
            </div>
        </div>
    </div>);
}

export default StepCard;