import { useRef } from "react";
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import HojaImpresion from "../hoja-impresion/HojaImpresion";
import { AiFillPrinter } from "react-icons/ai";
import './cuadroImpresion.css';

function CuadroImpresion({ encabezado, cuerpo, imprimir }) {


    let componentRef = useRef(null);

    return (
        <div className="">
            {
                imprimir ? <>
                    <div className="mt-3 mb-3">
                        <p className="text-end">
                            <ReactToPrint
                                trigger={() => {
                                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                    // to the root node of the returned component as it will be overwritten.
                                    return <Button className=""><AiFillPrinter /> imprimir</Button>;
                                }}
                                documentTitle="Impresión"
                                content={() => componentRef}
                            />
                        </p>
                    </div>
                </> : <></>
            }
            <div className="bg-secondary p-3 cuadroDoc border">
                <div className="d-flex justify-content-center" >
                    <div className="borde-pagina-1cm">
                        <div className="" ref={el => (componentRef = el)}>
                            <HojaImpresion encabezado={encabezado} cuerpo={cuerpo} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CuadroImpresion;