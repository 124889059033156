
import { Table } from 'react-bootstrap';
import logo from '../../assets/uap-logo.bmp';

function ActualizacionDocente() {


    return (
        <div className="uap-encabezado mb-3">
            <Table bordered style={{borderColor:"#000"}} responsive size="sm">
                <tbody>
                    <tr>
                        <td className='h3 text-end roboto-condensed-regular align-middle'>DA-UPD-FRD 001</td>
                        <td rowSpan={3} className="align-middle">
                            <p className="h2 text-center roboto-condensed-regular">FICHA ACTUALIZACIÓN</p>
                            <p className="h2 text-center roboto-condensed-regular">DOCENTE</p>
                        </td>
                        <td rowSpan={3} className="align-middle text-center">
                                <img src={logo} alt="Logo UAP" className="img-responsive center-block"/>
                        </td>
                    </tr>
                    <tr >
                        <td  className="align-middle">
                            <p className='font12 text-end roboto-condensed-regular '> VIGENCIA: GESTIÓN 2022</p>
                            <p className='font12 text-end'> VERSIÓN N° 1</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="align-middle">
                            <p className='font10 text-end roboto-condensed-regular'>Fecha Impresión : {new Date().toLocaleString() + ""}</p>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}

export default ActualizacionDocente;