import { Button, Table, } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import * as Bs from 'react-icons/bs';
import { useEffect, useState } from 'react';
import ModalInfo from '../../components/modals/ModalInfo';
import { infoDocuments } from '../../http/log';
import { Link } from 'react-router-dom';


const Documentos = ({onView})=>{

    //inicar haciendo una peticion http para obtener datos
    const [documentos, setDocumentos] = useState([]);

    useEffect(() => {
        const cargarDatos=()=>{
            infoDocuments().then(
                data => {
                    if (data != null) {
                        if (data.status === 200 && data.ok) {
                            //console.log(data);
                            setDocumentos(data.data ?? []);
                            let cont = false;
                            data.data.forEach(function(documento) {
                                if(!documento?.tiene && documento?.necesario && !cont){
                                    console.log("No Tiene");
                                    cont=true;
                                }
                            });
                            if(cont){
                                    onView();
                            }
                        } else {
    
                        }
                    }
                }
            )
        }

        cargarDatos();
    }, [onView]);


    //**************** */

    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState("titulo");
    const [textoModal, setTextModal] = useState("texto");

    function dataUp(texto) {
        setShowModal(true);
        setTitleModal("Observación");
        setTextModal(texto);
    }

    /* DATA */

    /** */
    const listItems = documentos.map((data, index) =>
        <tr key={index.toString()}>
            <td>{index + 1}</td>
            <td>{data.tipo_documento}</td>
            <td className='text-center'>
                <strong >{data.tiene ? <FaIcons.FaRegCheckCircle className='text-success' /> : <FaIcons.FaRegTimesCircle className='text-danger' />}</strong>
            </td>
            <td className='text-center'>
                {data.observacion !== "" ? <Button variant="light" className='m-1' onClick={() => dataUp(data.observacion)}><Bs.BsFillChatSquareTextFill className='text-info' /></Button> : <></>}
            </td>
            <td className='text-center'>
                {data.url !== "" ? <a href={data.url} target="_blank" rel="noreferrer" className='m-1 btn btn-light'><FaIcons.FaFilePdf className='text-danger' /></a> : <></>}
            </td>

        </tr>);



    return (
        <div className="info_basico m-3">
            <div className='d-flex mb-2'>
                <div className='ms-auto'>
                    {/*<Link to="actualizar/" className="btn btn-outline-primary">Actualizar Información en Linea</Link>*/}
                </div>
            </div>
            <p className='font12 text-secondary text-end mb-2'>Fecha de última actualización {documentos[0]?.modificacion}</p>
            <hr />
            <div>
                <Table responsive className='align-middle' bordered hover size="sm" >
                    <thead>
                        <tr>
                            <th className='text-center'>#</th>
                            <th className='text-center'>Documento</th>
                            <th className='text-center'>Estado</th>
                            <th className='text-center'>Obs.</th>
                            <th className='text-center'><FaIcons.FaDownload /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listItems}
                    </tbody>
                </Table>
                <div className='text-muted'><strong>Nota: </strong><small>Para realizar actualizaciones de documentos, deben hacer llegar el Documento a la Unidad de Personal Docente de la Dirección Académica del Vicerrectorado</small></div>
            </div>
            <ModalInfo show={showModal} onHide={() => setShowModal(false)} titulo={titleModal} texto={textoModal} />
        </div>
    );
};

export default Documentos;