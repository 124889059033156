import { Modal, Spinner, } from "react-bootstrap";
import { useUsuario } from "../../../context/UsuarioContext";
import Login from "../../../pages/login/login";


function ModalLogin(){

  const {loginModalLoad, setLoginModalLoad } = useUsuario();
  
    return(
        <Modal 
        size="sm" 
        show={loginModalLoad} 
        fullscreen={true}
        onHide={()=>setLoginModalLoad(false)} 
        centered 
        contentClassName="bg-transparent border-0"
        backdrop="static"
        keyboard={false}>
        <Modal.Body className="p-0">
          <Login></Login>
        </Modal.Body>
          
      </Modal>
    );
}

export default ModalLogin;