import axios from "axios";
import download from "downloadjs";
import { certificado_nota_PDF_url, certificado_nota_url, preacta_pdf_url, preacta_sefunda_pdf_url } from "../config/url_libretas";
import { infoCod } from "../functions/info"
import { conexion } from "./log";
import verificarCode from "./VerificCodeResponse";
import { mostrarAlerta, mostrarAlertaDanger} from "../alerts/Alerts";


export async function preActaPDF(asig,gestion,periodo,materia) {

  await conexion();
  return await axios({
    url: preacta_pdf_url,
    data:{"code":asig,"gestion":gestion,"periodo":periodo},
    method: "POST",
    responseType: "blob",
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
    }
  }).then(response => {
    verificarCode(response.data);
    if (response.status === 200) {
      const content = response.headers['content-type'];
      download(response.data, `PreActa_${materia}.pdf`, content);
      return true;
    }
    return false;
  })
    .catch(error => {
      console.log(error);
      return false;
    });
}

export async function preActaSegundaInstanciaPDF(asig,gestion,periodo,materia) {

  await conexion();
  return await axios({
    url: preacta_sefunda_pdf_url,
    data:{"code":asig,"gestion":gestion,"periodo":periodo},
    method: "POST",
    responseType: "blob",
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
    }
  }).then(response => {
    verificarCode(response.data);
    if (response.status === 200) {
      const content = response.headers['content-type'];
      download(response.data, `PreActa2da_${materia}.pdf`, content);
      return true;
    }
    return false;
  })
    .catch(error => {
      console.log(error);
      return false;
    });
}

export async function certificadoNota(asig,gestion,periodo) {

  await conexion();

   return axios.post(certificado_nota_url,
    {"code":asig,"gestion":gestion,"periodo":periodo},
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {        
        verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
    });
  }

export async function certificadoNotaPDF(asig,gestion,periodo,est,nota,aprobado,motivo) {

    await conexion();
    return await axios({
      url: certificado_nota_PDF_url,
      data:{"code":asig,"gestion":gestion,"periodo":periodo,"est":est,"nota":nota,"aprobado":aprobado,"motivo":motivo},
      method: "POST",
      responseType: "blob",
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
      }
    }).then(response => {
      verificarCode(response.data);
      if (response.status === 200) {
        const content = response.headers['content-type'];
        download(response.data, "CertificadoNota.pdf", content);
        return true;
      }
      return false;
    })
      .catch(error => {
        console.log(error);
        return false;
      });
  }