import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

function InfoBasico({data}){


        
    return(
        <div className="info_basico m-3">
            
            <div className='d-flex mb-2'>
                <div className='ms-auto'>
                    {/*<Link to="actualizar/" className="btn btn-outline-primary">Actualizar Información en Linea</Link>*/}
                </div>
            </div>
            <p className='text-start mt-3 mb-3'>Datos Generales</p>
                <hr/>
                <div>
                    <Table responsive hover className='align-middle'>
                        <tbody>
                            <tr>
                                <td className='text-start'><strong>Nombres y Apellidos:</strong></td>
                                <td className='text-end'>{data?.nombres} {data?.paterno} {data?.materno}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>C.I.:</strong></td>
                                <td className='text-end'>{data?.ci} - {data?.expedido}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Sexo:</strong></td>
                                <td className='text-end'>{data?.sexo}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Estado Civil:</strong></td>
                                <td className='text-end'>{data?.estado_civil}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Lugar de Nacimiento:</strong></td>
                                <td className='text-end'>{data?.pais} - {data?.departamento} - {data?.provincia} - {data?.localidad}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Fecha de Nacimiento:</strong></td>
                                <td className='text-end'>{data?.nacimiento}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Domicilio:</strong></td>
                                <td className='text-end'>{data?.direccion}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Telefono:</strong></td>
                                <td className='text-end'>{data?.telefono}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Correo Personal:</strong></td>
                                <td className='text-end'>{data?.correo}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Correo Institucional UAP:</strong></td>
                                <td className='text-end'>{data?.correo_inst}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Celular:</strong></td>
                                <td className='text-end'>{data?.celular}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Grupo Sanguineo:</strong></td>
                                <td className='text-end'>{data?.sangre}</td>
                            </tr>
                            
                        </tbody>
                    </Table>
                </div>
        </div>
    );
}

export default InfoBasico;