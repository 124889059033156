import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { AsignaturaAsignadaCard } from "../../components/asignaturaAsignadaCard/AsignaturaAsignadaCard";
import { useUsuario } from "../../context/UsuarioContext";
import { materiasDocente, materiasDocentePG } from "../../http/log";

const Asignaturas = props => {



    const {setSpinnerLoad } = useUsuario();
    const [periodoGestion, setPeriodoGestion] = useState({});
    const [modalPeriodoGestion, setModalPeriodoGestion] = useState(false);

    //inicar haciendo una peticion http para obtener datos
    const [materias, setMaterias] = useState([]);
    useEffect(() => {
        setSpinnerLoad(true);
        materiasDocente().then(
            data => {
                console.log(data);
                setMaterias(data?.data);
                setSpinnerLoad(false);
            }
        )
    }, []);
    //**************** */

    const load = (d) => {
        console.log(d);
        setSpinnerLoad(true);
        materiasDocentePG(d).then(
            data => {
                console.log(data);
                setMaterias(data?.data);
                setPeriodoGestion(d);
                setSpinnerLoad(false);
            }
        )
    }

    const cambiarPeriodoGestion = () => {
        setModalPeriodoGestion(true);
    }

    const listItems = materias?.map((data, index) =>
        <div key={index} className="col col-md-6 col-lg-4 col-12 ">
            <AsignaturaAsignadaCard data={data} />
        </div>
    );

    return (
        <div className="asignaturas">
            <div className='container'>
                <div className='row p-2'>
                    <div className="col-12 mt-3 mb-3">
                        <p className="h5 m-0">{Object.keys(periodoGestion).length===0?"Periodo Actual ":"Periodo "+periodoGestion.periodo+" / "+periodoGestion.gestion+" "}<strong className="h6 text-primary pointer" onClick={() => cambiarPeriodoGestion()}>{" cambiar"}</strong></p>
                        <p className="roboto-condensed-regular h2">{"Asignaturas Asignadas"}</p>
                    </div>
                    <div className=' col-12 col-lg-12'>
                        <div className="row ">
                            {materias?.length !== 0 ? <>{listItems}</> : <div>
                                <div className='text-center'>
                                    <p className=" mt-2 mb-2">Sin materias Asignadas</p>
                                </div>
                            </div>
                            }



                        </div>
                    </div>

                </div>
            </div>

            <ModalSelectPeriodoGestion
                show={modalPeriodoGestion}
                onHide={() => setModalPeriodoGestion(false)}
                onLoad={(v) => load(v)}
            />

        </div>
    );
}


function ModalSelectPeriodoGestion(props) {

    
    const [periodo, setPeriodo] = useState(1);
    const [gestion, setGestion] = useState(new Date().getFullYear());

    const cambiar=()=>{
        props.onLoad({"periodo":periodo,"gestion":gestion});
        props.onHide();
    }

    return (
        <Modal size="sm" show={props.show} onHide={props.onHide}
            centered>
            <Modal.Body className="p-0">
                <div className="text-center m-3 roboto-condensed-regular"><p>Ingrese el Periodo y Gestión</p></div>
                <hr />
                <div className="m-3">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Periodo</Form.Label>
                        <Form.Control 
                        type="text" 
                        placeholder="periodo"
                        value={periodo}
                        onChange={(e)=>setPeriodo(e.target.value)}
                         />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Gestión</Form.Label>
                        <Form.Control 
                        type="text" 
                        placeholder="gestion"
                        value={gestion}
                        onChange={(e)=>setGestion(e.target.value)}
                        />
                    </Form.Group>
                </div>

                <hr />
            </Modal.Body>
            <Modal.Footer className='p-0'>
            <div className='row col-6 m-0'>
              <span onClick={props.onHide} className='col-12 btn-ios rounded-bottom'>Cerrar</span>
            </div>
            <div className='row col-6 m-0'>
              <span onClick={()=>cambiar()} className='col-12 btn-ios rounded-bottom'>Cambiar</span>
            </div>
        </Modal.Footer>
        </Modal>
    );
}

export default Asignaturas;