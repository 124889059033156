import React, { useEffect } from 'react';
import { useState} from 'react';
import './login.css';
import * as FaIcons from 'react-icons/fa';
import { Button, Form, FormControl, InputGroup, Modal, Spinner } from 'react-bootstrap';
import { confirmarRecovery, loginUser, verificarDatosRecovery } from '../../http/log';
import { useUsuario } from '../../context/UsuarioContext';
import { Link } from 'react-router-dom';
import { mostrarAlertaInfo } from '../../alerts/Alerts';

export default function RecoverPassword() {
 

    const { token,infoBasicData,setToken, spinnerLoad, setSpinnerLoad } = useUsuario();

    const [ci, setCi] = useState("");
    const [fecha, setFecha] = useState("");

    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState(false);
    const [error, setError] = useState("");
    const [data, setData] = useState();


    const handleValidation = () => {
      let formIsValid = true;
      if(ci===null || ci===""){
        setError("Ingrese un Usuario");
        return false;
      }
      if(fecha===null || fecha===""){
        setError("Ingrese la Contraseña");
          return false;
      }

      setError("");
      return formIsValid;
  }

    const handleSubmit = async e => {
      e.preventDefault();

      setSpinnerLoad(true);
      
      if(handleValidation()){
        let datax = await verificarDatosRecovery({
          ci,
          fecha
        });
        //console.log(data);
        if(datax!=null ){
          console.log(datax);
          if(datax.correos!==null && datax.correos!==[] ){
            setModal(true);
            setData(datax);
          }else{
              setModalMessage(true);  
          }
        }
      }
      setSpinnerLoad(false);
    }


    return (
        <div className='login d-flex align-items-center'>
            <div className="container">
                  <div className="d-flex justify-content-center">
                    <div className="login-box col-md-4 bg-white m-3 shadow mb-5 bg-body rounded">
                        <div className="card-header text-center bg-white h1 p-3">
                          <b>Perla</b><span> Net</span>
                          </div>
                          <div className="card-body text-center">
                            <strong className="mb-3">¿Olvidaste tu contraseña o nombre de usuario?</strong>
                            <p className="login-box-msg text-muted mb-1 text-start">Ingrese sus datos para proceder con la recupercación de sus credenciales</p>
                            <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <Form.Label htmlFor="ci">C.I.</Form.Label>
                                <InputGroup className="mb-3">
                                  <FormControl 
                                    id="ci"
                                    type="text"
                                    placeholder="Número de cédula de identidad"
                                    onChange={(e) => setCi(e.target.value)}
                                  />
                                </InputGroup>
                            </div>
                            
                            <div className="input-group">
                                <Form.Label htmlFor="fecha">Fecha de Nacimiento</Form.Label>
                                <InputGroup className="mb-1">
                                  <FormControl 
                                    id="fecha"
                                    type="date"
                                    placeholder="Correo electrónico"
                                    onChange={(e) => setFecha(e.target.value)}
                                  />
                                </InputGroup>
                            </div>

                            <div className='mb-3 text-danger font12'>
                                <strong>{error}</strong>
                            </div>
                            <div className="row">
                              <div className="col-12 align-self-end">
                                <button type="submit" className="btn btn-primary btn-block" >Verificar Información</button>
                              </div>
                            </div>
                            <p className="m-3">
                              <Link to="/">Volver atrás</Link>
                            </p>
                            </form>
                          </div>
                    </div>
                  </div>
            </div>
            <Modal
              show={modalMessage}
              size="sm"
              centered
              contentClassName="border-0"
              backdrop="static"
              keyboard={false}
            >
              <Modal.Body >
                <p className='text-center mt-3 ms-1 me-1 mb-3'><strong className=''>No tiene registrado un correo electrónico</strong></p>
                
                <p className='font13 text-center mb-3'>
                  Pase por oficina de USA-DIA para registrar uno nuevo
                </p>
              </Modal.Body>
              <Modal.Footer className='p-0'>
                <div className='row col-12 m-0'>
                  <div className='col-12 btn-ios rounded-bottom' onClick={()=>setModalMessage(false)}>OK</div>
                </div>
              </Modal.Footer>
            </Modal>
            
            <ModalConfir
              show={modal}
              onHide={() => setModal(false)}
              correos={data?.correos??[]}
              nombre={data?.nombre}
              ci={data?.ci}
              fecha={data?.fecha}
              codigo={data?.codigo}
            />
        </div>
    );
}

function ModalConfir(props) {


  const [load, setLoad] = useState(false);
  const [confimado, setConfirmado] = useState(false);
  const [correo, setCorreo] = useState();
  const [correoSend, setCorreoSend] = useState("");

  
  useEffect(()=>{
    setCorreo(props.correos[0]??"");
  },[props.show,props.correos]);

  function confirmRecovery(){
    setLoad(true);
    confirmarRecovery({
      "ci":props.ci,
      "fecha":props.fecha,
      "codigo":props.codigo,
      "correo":correo
    }).then(data=>{
      console.log(data);
      if(data!=null ){
        setConfirmado(true)
        setCorreoSend(data);
      }
      setLoad(false);
    });
    
  }

  const confimacion=()=>{
    
    if(load){
      return (
        <Modal.Body className='text-center'>
          <Spinner animation="border" variant="primary" className='m-5'/>
        </Modal.Body>
      )
    }else{
      if(confimado){
          return(
            <>
              <Modal.Body >
                <p className='text-center mt-3 ms-1 me-1 mb-1'><strong className=''>Se enviaron los datos correctamente</strong></p>
                <p className='font13 text-center mb-3'>
                  Revise su correo electrónico  <strong>{correoSend}</strong> y siga las intrucciones:
                </p>
                <p className='text-muted font13'>
                  *Si no logra ver el email, debe revisar su bandeja de <strong>SPAM</strong> y marcarlo como no Spam.
                </p>
              </Modal.Body>
              <Modal.Footer className='p-0'>
                <div className='row col-12 m-0'>
                  <Link to="/" className='col-12 btn-ios rounded-bottom'>Cerrar</Link>
                </div>
              </Modal.Footer>
            </>
          );
      }else{
        return (
          <>
          <Modal.Body >
            <p className='font13 text-center'>
              Hola {props.nombre}, Para reestablecer su acceso al sistema se enviará un email al correo que seleccione:
            </p>
            <div className='mt-4 mb-4'>
            <select className="form-select text-center fw-bold"
            value={correo}
            onChange={(e)=>setCorreo(e.target.value)}>
            {props.correos?.map((d)=><option value={d} className='text-center'><strong>{d}</strong></option>)}
</select>
            </div>
            <p className='text-muted font12'>
              *Si no tiene acceso a ninguno de estos correos, debe contactarse con la administracion en USA-DIA para modificarlo.
            </p>
          </Modal.Body>
          <Modal.Footer className='p-0'>
            <div className='row col-12 m-0'>
              <div className='col-6 btn-ios rounded-bottom' onClick={props.onHide}>Cancelar</div>
              <span className='col-6 border-start btn-ios rounded-bottom' onClick={(e)=>confirmRecovery()}>Continuar</span>
            </div>
          </Modal.Footer>
        </>
        );
      }
    }
  };

  return (
    <Modal
      {...props}
      size="sm"
      centered
      contentClassName="border-0"
      backdrop="static"
      keyboard={false}
    >
      {confimacion()}   
    </Modal>
  );
}