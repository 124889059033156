import { Col, Row } from "react-bootstrap";
import appImg from '../../assets/img/app.png'
import payQrImg from '../../assets/img/payQr.png'
import scanQrImg from '../../assets/img/scanQr.png'
import { TbSquareRoundedNumber1Filled, TbSquareRoundedNumber2Filled, TbSquareRoundedNumber3Filled, TbSquareRoundedNumber4Filled } from 'react-icons/tb';
import StepCard from "./StepCard";
import pagoImg from '../../assets/img/pago.png'

const PagoQr =(props)=>{

    return(<div>
        <div className="m-3">
                                    <Row>
                                        <Col sm={12} md={6} className="d-flex">
                                            <div className="text-center m-auto" >
                                                <h5 className="text-center fw-bold mb-5">Escanea este QR para realizar el pago</h5>
                                                <img src={props.qrImg} style={{ 'maxHeight': '300px' }} /></div>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Row>
                                                <Col sm={12} className="text-center m-3">
                                                    <strong>Intrucciones</strong>
                                                </Col>
                                                <Col sm={1} md={1} className="d-flex">
                                                    <div className="text-center text-info m-auto"><h1 className=""><TbSquareRoundedNumber1Filled /></h1></div>
                                                </Col>

                                                <Col sm={11} md={11} className="d-flex mt-2 mb-2">
                                                    <StepCard
                                                        img={appImg}
                                                        titulo={"Inicia el App de tu Entidad Financiera"}
                                                        detalle={"Debe iniciar una aplicacion bancaria de su preferencia te que tenga la opción de pagos por QR"}
                                                        aclariacion={""}
                                                    />
                                                </Col>
                                                <Col sm={1} md={1} className="d-flex">
                                                    <div className="text-center text-info m-auto"><h1 className=""><TbSquareRoundedNumber2Filled /></h1></div>
                                                </Col>

                                                <Col sm={11} md={11} className="d-flex mt-2 mb-2">
                                                    <StepCard
                                                        img={payQrImg}
                                                        titulo={"Busca la opción Escanear QR"}
                                                        detalle={"Localice la opción escanear codigo QR o Pagar por QR, inicie esta opción"}
                                                        aclariacion={""}
                                                    />
                                                </Col>
                                                <Col sm={1} md={1} className="d-flex">
                                                    <div className="text-center text-info m-auto"><h1 className=""><TbSquareRoundedNumber3Filled /></h1></div>
                                                </Col>

                                                <Col sm={11} md={11} className="d-flex mt-2 mb-2">
                                                    <StepCard
                                                        img={scanQrImg}
                                                        titulo={"Escanea el Codigo Generado"}
                                                        detalle={"Debe acercar su telefono hacia el codigo QR con el obetivo de escanear el mismo desde su app, entonces se desplegara los datos del pago"}
                                                        aclariacion={""}
                                                    />
                                                </Col>
                                                <Col sm={1} md={1} className="d-flex">
                                                    <div className="text-center text-info m-auto"><h1 className=""><TbSquareRoundedNumber4Filled /></h1></div>
                                                </Col>
                                                <Col sm={11} md={11} className="d-flex mt-2 mb-2">
                                                    <StepCard
                                                        img={pagoImg}
                                                        titulo={"Confirma el Pago"}
                                                        detalle={"Debe confirmar el pago siguiendo las instrucciones de su banca movil"}
                                                        aclariacion={""}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={12} className="mt-2 mb-2">
                                            * Después de confirmar el pago, debes dirigirte a la opcion de VER CERTIFICADOS COMPRADOS, donde podrás descargar el documento y ver el estado del mismo
                                        </Col>
                                    </Row>
                                </div>
    </div>);
}

export default PagoQr;