import { RiWifiOffLine } from "react-icons/ri";

const FueraDeLinea = () => {

    return (<>
        <div className="d-flex" style={{width:"100vw",height:"100vh"}}>
            <div className="m-auto">
                <div className="ht-100p d-flex flex-column align-items-center justify-content-center pd-5">
                    <div className="">
                        <RiWifiOffLine className="fw-bold text-secondary" style={{fontSize:"100px"}} />
                    </div>
                    <h1 className="fw-bold h2 mb-3">Fuera de Linea</h1>
                    <h5 className="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">Su dispositivo no tiene acceso a internet</h5>
                    <p className="text-secondary">Esto impedirá que el sistema funciones correctamente, es por ello que se muestra este mensaje hasta que retorne la conexión</p>
                    <p className="text-secondary mb-3">Podrías revisar tu conexión y/o servicio de internet (WIFI, Datos)</p>
                    <span className="mb-5">Se cargará automátimente el sistema cuando vuelva la conexión</span>
                    <span className="text-secondary">Sistema PerlaNet</span>
                </div>
            </div>
        </div>
    </>);

}

export default FueraDeLinea;