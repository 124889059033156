import { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { downloadCertificadoPagoCodigoOriginalHttpPdf, listaCertificadosPagoSolicitadosHttp } from "../../http/log_certificados_pago";
import { useUsuario } from "../../context/UsuarioContext";
import { BsFilePdfFill } from "react-icons/bs"
import { TbReload } from "react-icons/tb";
import { MdOutlineError } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import { mostrarAlertaErrorInfo, mostrarAlertaInfoTopC } from "../../alerts/Alerts";
import PagoQr from "../../components/pago/PagoQr";
import PagoCpt from "../../components/pago/PagoCpt";

const ListaComprasCertificados = () => {


    const { setSpinnerLoad } = useUsuario();
    const [certificados, setCertificados] = useState([]);
    const [selectCertificado, setSelectCertificado] = useState({});
    const [viewModalPagoCertificado, setViewModalPagoCertificado] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setSpinnerLoad(true);
        listaCertificadosPagoSolicitadosHttp().then(
            d => {
                //console.log(data);
                if (d != null) {
                    setCertificados(d);
                }
                setSpinnerLoad(false);
            }
        )
    }

    const descargarOriginal = (data) => {
        setSpinnerLoad(true);
        downloadCertificadoPagoCodigoOriginalHttpPdf(data.codigo, data.tipo_certificado).then((d) => {
            setSpinnerLoad(false);
        });
    }


    const viewModalPago = (d) => {
        setSelectCertificado(d);
        setViewModalPagoCertificado(true);
    }

    const listItems = certificados.map((data, index) =>
        <tr key={index.toString()}>
            <td>{index + 1}</td>
            <td className="text-center">{data.numero_documento}</td>
            <td className="text-center fw-bold">{data.fecha_generado}</td>
            <td className="text-center">{data.tipo_certificado}</td>
            <td className="text-center">{data.institucion}</td>
            <td className="text-center">{data.metodo}</td>
            <td className="text-center">{data.withError || data.withWarning ?
                <button className={data.withError?"btn btn-sm btn-danger":"btn btn-sm btn-warning text-white"} onClick={() => mostrarAlertaErrorInfo(data.error)}><MdOutlineError />{data.withError?"Error":"Alerta"}</button>
                : (data.pagado ? 'Pagado' : data.pagar?'Por Pagar':'No Pagado')}</td>
            <td className="text-center">
                {data.pagado ?
                    <button className="btn btn-sm btn-light text-danger" onClick={() => descargarOriginal(data)}><BsFilePdfFill /> Descargar</button>
                    :
                    data.pagar && data.urlPago?<a className="btn btn-sm btn-warning" href={data.urlPago??""} target="_blank"><GiMoneyStack /> Pagar</a>:<></>
                }
            </td>
        </tr>);

    return (
        <div className="listaCertificadosComprado">
            <div className='container'>
                <div className='row p-2'>
                    <div className="col-12 mt-3 mb-3">
                        <p className="h5 m-0">Lista de Certificados Solicitados</p>
                    </div>

                    <div className=' col-12 col-lg-12'>
                        <div className='card bg-white shadow mb-5 bg-body rounded border-0'>
                            <div className="col-12 text-end p-3">
                                <button type="button" className="btn btn-light text-primary fw-bold" onClick={(e) => loadData()} ><TbReload /></button>
                            </div>
                            <hr className="m-0" />
                            <div className="card-body col-12 pb-5">
                                <div className="row justify-content-evenly">
                                    <Table responsive size="sm" className='align-middle font12'>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>#</th>
                                                <th className='text-center'>N. Certificado</th>
                                                <th className='text-center'>Fecha Solicitado</th>
                                                <th className='text-center'>Certificado</th>
                                                <th className='text-center'>Dirigido</th>
                                                <th className='text-center'>Metodo de Pago</th>
                                                <th className='text-center'>Estado</th>
                                                <th className='text-center'></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listItems}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalRealizarPago
                show={viewModalPagoCertificado}
                onHide={() => setViewModalPagoCertificado(false)}
                cert={selectCertificado}
            />
        </div>
    );
}


function ModalRealizarPago(props) {
    return (
        <Modal size="lg" show={props.show} onHide={props.onHide}
            centered>
            <Modal.Body className="p-0" >
                <div className="text-center m-3 roboto-condensed-regular">
                    <h3>{props.cert.tipo_certificado}</h3>
                </div>
                <hr />
                {props.cert.metodo === 'QR' ?
                    <PagoQr
                        qrImg={props.cert.codigoPago}
                    />
                    :
                    <PagoCpt
                        cptCode={props.cert.codigoPago}
                    />}

                <div className="mt-5 mb-4 text-center">
                    <button className="btn btn-danger pe-5 ps-5" onClick={() => props.onHide()}>Cerrar</button>  
                </div>
            </Modal.Body>
        </Modal>
    );
}


export default ListaComprasCertificados;