import { Spinner } from "react-bootstrap";

function DivLoader(){
    return (
        <div className="div-loader">
            <div className="col-12">
                <div>
                    <div className="container">
                        <div className="row">
                        <div className="col align-self-center text-center">
                            <Spinner animation="border" variant="secondary" role="status">
                            <span className="visually-hidden">Cargando...</span>
                            </Spinner>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DivLoader;