import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { notificacionHttp } from "../../http/log_general";


const ModalNotification=(props)=>{

  
  const [modalInfoView, setModalInfoView] = useState(false);
  const [mensajeModalInfoView, setMesajeModalInfoView] = useState("");

  useEffect(()=>{
    notificacionHttp().then((d)=>{
      if(d!=null){
        setMesajeModalInfoView(d);
        setModalInfoView(true);
      }
    });
  },[]);
  
    return(
      <Modal size="md" show={modalInfoView} onHide={()=>setModalInfoView(false)}
      centered  >
        
      <Modal.Body>
        <div closeButton className="text-center roboto-condensed-regular m-1 mb-3"><p>{props.titulo}</p></div>
        <div className="text-center  m-3" dangerouslySetInnerHTML={{ __html: mensajeModalInfoView }} />
      </Modal.Body>

      <Modal.Footer className='p-0'>
      </Modal.Footer>

    </Modal>
    );
}

export default ModalNotification;