import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useUsuario } from '../../context/UsuarioContext';
import { historialDocente } from '../../http/log';

function Historial(){

    const { setSpinnerLoad } = useUsuario();
    //inicar haciendo una peticion http para obtener datos
    const [history, setHistory] = useState([]);
    const [anios, setAnios] = useState("-");
    useEffect(()=>{
        setSpinnerLoad(true);
        historialDocente().then(
              data =>{
                //console.log(data);
                setHistory(data.data.historialAsignacion??[]);
                setAnios(data.data.cantidadAnios??"-");
                setSpinnerLoad(false);
              }
          )
    },[]);
//**************** */
    



    const listItems = history.map((data,index) =>  
    <tr key={index.toString()}>
        <td>{index+1}</td>
        <td>{data.gestion}</td>
        <td>{data.sigla}</td>
        <td>{data.materia}</td>
        <td>{data.grupo}</td>
        <td>{data.carrera}</td>
        <td>{data.plan}</td>
        <td>{data.evaluacion}</td>
        <td>{data.admision}</td>
        <td>{data.carga}</td>
    </tr>);

    return (
        <div className="documents">
           <div className='container'>
               <div className='row p-2'>
                    <div className='col-12 mt-4 mb-2 text-start'>
                        <h3>Historial de Docencia</h3>
                    </div>
                    <div className=' col-12 col-lg-12'>
                        <div className='bg-white shadow mb-5 p-3 bg-body rounded '>
                            <div>
                                <Table responsive  size="sm" className='align-middle font12'>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>#</th>
                                            <th className='text-center'>Gestión</th>
                                            <th className='text-center'>Sigla</th>
                                            <th className='text-center'>Materia</th>
                                            <th className='text-center'>Grupo</th>
                                            <th className='text-center'>Programa</th>
                                            <th className='text-center'>Plan</th>
                                            <th className='text-center'>Evaluación</th>
                                            <th className='text-center'>Admisión</th>
                                            <th className='text-center'>Carga</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listItems}
                                    </tbody>
                                </Table>
                            </div>
                            <hr/>
                            
                            <p className='text-start mt-3 mb-3'>* Cumpliendo en la actualidad {anios} {anios==='1'?"año":"años"} de experiencia docente.</p>
                        </div>
                    </div>
                    

               </div>
           </div>
        </div>
    );
}

export default Historial;