import React from 'react';
import { useParams } from 'react-router-dom';
import './DocumentVal.css';

function DocumentValidate(){

    const { token } = useParams();

    return (
        <div className='login d-flex align-items-center'>
        <div className="container">
              <div className="d-flex justify-content-center">
                <div className="login-box col-md-4 bg-white m-3 shadow mb-5 bg-body rounded">
                    <div className="card-header text-center bg-white h1 p-3">
                      <b>Perla</b><span> Net</span>
                      </div>
                      <div className="card-body text-center">
                          <h3>Este Documento Es Válido</h3>
                          <p>Código de verificacion: {token}</p>
                      </div>
                </div>
              </div>
        </div>
    </div>
    );
}

export default DocumentValidate;