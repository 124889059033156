import React, { useEffect } from 'react';
import { useState} from 'react';
import './login.css';
import * as FaIcons from 'react-icons/fa';
import { Button, Form, FormControl, InputGroup, Modal, Spinner } from 'react-bootstrap';
import { confirmarRecovery, loginUser, verificarDatosRecovery, verificarTokenRecover } from '../../http/log';
import { useUsuario } from '../../context/UsuarioContext';
import { Link, useParams } from 'react-router-dom';
import { mostrarAlertaInfo, mostrarAlertaSuccess } from '../../alerts/Alerts';
import {AiFillEye,AiFillEyeInvisible} from "react-icons/ai";
import DivLoader from '../../components/divLoader/DivLoader';

export default function RecoverPasswordConfirm(props) {
 

    const { setSpinnerLoad } = useUsuario();

    const [load, setLoad] = useState(false);
    const [modal, setModal] = useState(false);
    /**** */
    const { token } = useParams();
    const [usuario, setUsuario]= useState("");
    const [pass, setPass]= useState("");
    const [passwordShown, setPasswordShown] = useState(false);

    const [error, setError] = useState("");
    const [error2, setError2] = useState("");

    const handleValidation = () => {
      var paswd=  /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,12}$/;
      let formIsValid = true;
      if(usuario===null || usuario===""){
        setError("Ingrese un Usuario");
        return false;
    }
      if(pass===null || pass===""){
        setError("Ingrese la Contraseña");
          return false;
      }


      if(!pass.match(paswd)){
        setError("La contraseña debe tener el formato establecido");
          return false;
      }

      if(usuario.includes(" ")){
        setError2("Su nombre de Usuario no debe tener espacios");
          return false;
      }

      setError("");
      return formIsValid;
  }


    const handleSubmit = async e => {
      e.preventDefault();
      setError("");
      setSpinnerLoad(true);

      if(handleValidation()){
        setLoad(true);
        let datax = await verificarTokenRecover({
          tk:token,
          usuario:usuario,
          pass:pass
        });
  
        setLoad(false);
        if(datax!=null ){
          console.log(datax);
          mostrarAlertaSuccess("Cambio de contraseña correcto");
          setModal(true);
        }

      }
      setSpinnerLoad(false);
    }

    const confimacion=()=>{
    
      if(load){
        return(
          <div className='text-center'>
            <Spinner animation="border" variant="primary" className='m-5'/>
          </div>
        );
      }else{
        return (<>
        <strong className="mb-5">Restablecer Credenciales de Acceso</strong>
                            <p className="login-box-msg text-muted mb-3 mt-3 text-start">Ingrese sus datos para continuar con el proceso</p>
                            <form onSubmit={handleSubmit}>
                                <div className="input-group">
                                    <Form.Label htmlFor="ci">Nuevo Nombre de Usuario</Form.Label>
                                    <InputGroup className="">
                                      <Form.Control 
                                      type="text"
                                      value={usuario}
                                      onChange={(e) => setUsuario(e.target.value)}
                                      />
                                      <span className="input-group-text" id="basic-addon2"><FaIcons.FaUserAlt /></span> 
                                  </InputGroup>
                                    <span className="font10 text-secondary" >* No debe ingresar espacios</span>
                                
                                </div>
                                
                                <div className='mb-3 text-danger font12 text-start'>
                                    {error2}
                                </div>

                                <div className="input-group">
                                    <Form.Label className='m-0' htmlFor="fecha">Nueva Contraseña</Form.Label>  
                                        <p className="font12 text-secondary  mb-1 text-start">
                                           * Contraseña de 8 a 12 caracteres que contengan letra mayúscula, minúscula, dígito numérico y un carácter especial (<strong> ! @ # $ % ^ & * </strong>).
                                        </p>
                                    <InputGroup>
                                        <Form.Control 
                                        type={passwordShown ? "text" : "password"}
                                        placeholder="" 
                                        value={pass}
                                        onChange={(e) => setPass(e.target.value)}
                                        />
                                        <span className="input-group-text" id="button-addon2" onClick={()=>setPasswordShown(!passwordShown)}>
                                            {passwordShown?<AiFillEyeInvisible/>:<AiFillEye/>}
                                        </span>  
                                    </InputGroup>
                                    <span className={pass.length>7 && pass.length<13?"mb-3 ms-1 font10 text-success":"mb-3 ms-1 font10 text-danger"} >{pass.length}/12</span>
                                    
                                </div>
                                <div className='mb-3 text-danger font12 text-center'>
                                    <strong>{error}</strong>
                                </div>
                                <div className="row">
                                  <div className="col-12 align-self-end">
                                    <button type="submit" className="btn btn-primary btn-block" >Restablecer Usuario y Contraseña</button>
                                  </div>
                                </div>
                                <p className="m-3">
                                  <Link to="/">Cancelar</Link>
                                </p>
                                
                            </form>
        </>);
      }
    }

    return (
        <div className='login d-flex align-items-center'>
            <div className="container">
                  <div className="d-flex justify-content-center">
                    <div className="login-box col-md-4 bg-white m-3 shadow mb-5 bg-body rounded">
                        <div className="card-header text-center bg-white h1 p-3">
                          <b>Perla</b><span> Net</span>
                          </div>
                          <div className="card-body text-center">
                              {load?<div className='mt-5 mb-5'><DivLoader/></div>:confimacion()}
                          </div>
                    </div>
                  </div>
            </div>
            <ModalConfir
              show={modal}
            />
        </div>
    );
}

function ModalConfir(props) {


  const [load, setLoad] = useState(false);
  const [confimado, setConfirmado] = useState(false);


  return (
    <Modal
      {...props}
      size="sm"
      centered
      contentClassName="border-0"
      backdrop="static"
      keyboard={false}
    >
    
              <Modal.Body >
                <p className='text-center mt-3 ms-1 me-1 mb-1'><strong className=''>Se logro cambiar las credenciales exitosamente!!</strong></p>
                <p className='font13 text-center mb-3'>
                  Ahora puede volver a inicar sesión con su nuevo usuario y contraseña
                </p>
              </Modal.Body>
              <Modal.Footer className='p-0'>
                <div className='row col-12 m-0'>
                  <Link to="/" className='col-12 btn-ios rounded-bottom'>Iniciar Sesión</Link>
                </div>
              </Modal.Footer>
              
    </Modal>
  );
}