import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import { useShowSide } from "../../context/showSideContext";
import { useUsuario } from "../../context/UsuarioContext";
import { TiArrowSortedDown } from "react-icons/ti";
import { AiFillDatabase } from "react-icons/ai";
import { FaBars } from "react-icons/fa";
import user_img from "../../assets/img/user_default.png";
import {
  Form001UsaDOC,
  Form006DOC,
  FormIncompativilidadHttpPdf,
  ManualDeUsuario,
  NotasConvetidas,
} from "../../http/log_download";
import {
  downloadCedulaDocente,
  downloadRegistroDocente,
  downloadRegistroDocenteEmit,
} from "../../http/log";
import { FaRegFilePdf, FaRegFileWord } from "react-icons/fa";

function Topbar2({ onSide }) {
  const { showSide, setShowSide } = useShowSide();
  const { setToken, setSpinnerLoad, infoBasicData } = useUsuario();

  const navigate = useNavigate();

  function cl(e) {
    e.preventDefault();
    setToken("");
  }

  function handleClick(e) {
    e.preventDefault();
    onSide();
  }

  function go(a) {
    setShowSide(false);
    navigate(a);
  }

  function downloadRegistroEmit() {
    setSpinnerLoad(true);
    downloadRegistroDocenteEmit().then((data) => {
      setSpinnerLoad(false);
    });
  }

  function downloadRegistro() {
    setSpinnerLoad(true);
    downloadRegistroDocente().then((data) => {
      setSpinnerLoad(false);
    });
  }

  function downloadCedula() {
    setSpinnerLoad(true);
    downloadCedulaDocente().then((data) => {
      console.log(data);
      setSpinnerLoad(false);
    });
  }

  function downloadFormIncompatibilidad() {
    setSpinnerLoad(true);
    FormIncompativilidadHttpPdf().then((data) => {
      setSpinnerLoad(false);
    });
  }

  function downloadFormUSA001() {
    setSpinnerLoad(true);
    Form001UsaDOC().then((data) => {
      setSpinnerLoad(false);
    });
  }

  function downloadForm006() {
    setSpinnerLoad(true);
    Form006DOC().then((data) => {
      setSpinnerLoad(false);
    });
  }

  function downloadNotasConvertidas() {
    setSpinnerLoad(true);
    NotasConvetidas().then((data) => {
      setSpinnerLoad(false);
    });
  }

  function downloadManual() {
    setSpinnerLoad(true);
    ManualDeUsuario().then((data) => {
      setSpinnerLoad(false);
    });
  }

  return (
    <Navbar
      bg=""
      variant="dark"
      expand="lg"
      className=""
      style={{ background: /*'#011627'*/ "#D71717" }}
    >
      <Container className="m-0" style={{ minWidth: "100%" }}>
        <Navbar.Brand>
          <div className="me-5" style={{ fontSize: 14 }}>
            <span className="bot-sidebar">
              <FaBars onClick={handleClick} />
            </span>
            Sistema Perla NET
          </div>
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <div className="nav-item dropdown">
              <span className="nav-link pointer" onClick={() => go("/")}>
                Inicio
              </span>
            </div>
            <NavDropdown title="Ver Datos" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <p onClick={() => go("/informacion")} className="btn-side">
                  {" "}
                  Datos Personales{" "}
                </p>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <p onClick={() => go("/historial")} className="btn-side">
                  {" "}
                  Historial de Docencia{" "}
                </p>
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Académica" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <p onClick={() => go("/asignaturas")} className="btn-side">
                  {" "}
                  Asignaturas{" "}
                </p>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <p onClick={() => go("/horario")} className="btn-side">
                  {" "}
                  Horarios y Aulas{" "}
                </p>
              </NavDropdown.Item>
            </NavDropdown>

            {
              <NavDropdown title="Certificaciones" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  <p onClick={() => go("/buyCertificado")} className="btn-side">
                    {" "}
                    Comprar Certificado{" "}
                  </p>
                </NavDropdown.Item>
              </NavDropdown>
            }

            <NavDropdown title="Descargas" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <p onClick={() => downloadRegistroEmit()} className="btn-side">
                  <FaRegFilePdf className="text-danger" /> Registro Docente{" "}
                </p>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <p onClick={() => downloadCedula()} className="btn-side">
                  <FaRegFilePdf className="text-danger" /> Cedula Docente{" "}
                </p>
              </NavDropdown.Item>
            </NavDropdown>

            <div className="nav-item dropdown">
              <span
                className="nav-link pointer"
                onClick={() => go("/biblioteca")}
              >
                Biblioteca Virtual
              </span>
            </div>

            <div className="nav-item dropdown">
              <span className="nav-link pointer" onClick={() => go("/acceso")}>
                Acceso
              </span>
            </div>

            <NavDropdown title="Doc. Adicionales" id="basic-nav-dropdown">
              <a
                className="dropdown-item"
                href="https://drive.google.com/file/d/1PuBVCC-oranmV_1kPdlSn7aoKAJpcen8/view?usp=sharing"
                target="blank"
              >
                <FaRegFilePdf className="text-danger" /> MODELO DE FORMACION
                SUPERIOR UNIVERSITARIA - UAP
              </a>
              <NavDropdown.Item>
                <p
                  onClick={() => downloadFormIncompatibilidad()}
                  className="btn-side"
                >
                  {" "}
                  <FaRegFilePdf className="text-danger" /> FORM incompatibilidad
                  en el horario de Docencia{" "}
                </p>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <p onClick={() => downloadFormUSA001()} className="btn-side">
                  {" "}
                  <FaRegFileWord className="text-primary" /> Hab. Registro notas
                  Parcial FORM-USA-001{" "}
                </p>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <p onClick={() => downloadForm006()} className="btn-side">
                  {" "}
                  <FaRegFileWord className="text-primary" /> Retroceso de Fase
                  FORM-006{" "}
                </p>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <p
                  onClick={() => downloadNotasConvertidas()}
                  className="btn-side"
                >
                  {" "}
                  <FaRegFilePdf className="text-danger" /> Convertir Notas{" "}
                </p>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <p onClick={() => downloadManual()} className="btn-side">
                  {" "}
                  <FaRegFilePdf className="text-danger" /> Manual de Usuario{" "}
                </p>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>

        <Navbar.Brand className="">
          <div className="dropdown data-user">
            <div className=" me-3">
              <span
                className="btn text-white"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="" style={{ display: "flex" }}>
                  <div className="col-12 me-2 divImage bg-secondary profile-user-img-mini">
                    <div className="ratio ratio-1x1 rounded-circle overflow-hidden border-1">
                      <div
                        style={{
                          backgroundImage:
                            "url(" +
                            (infoBasicData?.url_foto !== ""
                              ? infoBasicData?.url_foto
                              : user_img) +
                            ")",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repead",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    </div>
                  </div>
                  {infoBasicData?.nombres}
                </div>
              </span>

              <div
                className="dropdown-menu p-0"
                aria-labelledby="dropdownMenuButton1"
              >
                <div className="row col-12 m-0">
                  <span
                    onClick={cl}
                    className="col-12 btn-ios rounded-bottom p-2"
                  >
                    Cerrar Sesión
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default Topbar2;
