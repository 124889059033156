import { Table } from "react-bootstrap";
import { FaDownload,FaFilePdf } from 'react-icons/fa';
import { Link } from "react-router-dom";

function DatosAcademicos({data}){
    return(
        <div className="info_basico m-3">
            <div className='d-flex mb-2'>
                <div className='ms-auto'>
                    {/*<Link to="actualizar/" className="btn btn-outline-primary">Actualizar Información en Linea</Link>*/}
                </div>
            </div>
                <p className='text-start mt-3 mb-3'>Datos Académicos</p>
                <hr/>
                <div>
                    <Table responsive bordered hover size="sm" >
                        <thead>
                            <tr className="text-center">
                                <th>Titulo</th>
                                <th>Institución</th>
                                <th>Grado Académico</th>
                                <th>Descripción</th>
                                <th>Año</th>
                                <th>Nro. Serie</th>
                                <th className='text-center'><FaDownload /></th>
                            </tr>
                        </thead>
                        <tbody className="font14"> 
                        {data.map((titulo,index) =>  
                            <tr key={index}>
                                <td className='text-center'>{titulo.titulo}</td>
                                <td className='text-center'>{titulo.institucion}</td>
                                <td className='text-center'>{titulo.grado}</td>
                                <td className='text-center'>{titulo.descripcion}</td>
                                <td className='text-center'>{titulo.anio}</td>
                                <td className='text-center'>{titulo.serie}</td>
                                <td className='text-center'>
                                    {titulo.digital!==""?<a href={titulo.digital} target="_blank" rel="noreferrer" className='m-1 btn btn-light'><FaFilePdf className='text-danger'/></a>:<></>}
                                </td>
                            </tr> 
                        )}            
                        </tbody>
                    </Table>
                </div>
        </div>
    );
}

export default DatosAcademicos;