import { Col, Row } from "react-bootstrap";
import appImg from '../../assets/img/app.png'
import payQrImg from '../../assets/img/payQr.png'
import scanQrImg from '../../assets/img/scanQr.png'
import { TbSquareRoundedNumber1Filled, TbSquareRoundedNumber2Filled, TbSquareRoundedNumber3Filled, TbSquareRoundedNumber4Filled } from 'react-icons/tb';
import StepCard from "./StepCard";
import pagoImg from '../../assets/img/pago.png'

const TutorialPagoQr = (props) => {

    return (<div>
        <div className="ms-3 me-3">
            <Row>
                <Col sm={12} md={12}>
                    <Row>
                        <Col sm={12} className="text-center m-1">
                            <strong>Instrucciones de pago por QR</strong>
                        </Col>
                        <Col sm={1} md={1} className="d-flex">
                            <div className="text-center text-info m-auto"><h1 className=""><TbSquareRoundedNumber1Filled /></h1></div>
                        </Col>

                        <Col sm={11} md={5} className="d-flex mt-2 mb-2">
                            <StepCard
                                img={appImg}
                                titulo={"Inicia el App de tu Entidad Financiera"}
                                detalle={"Debe iniciar una aplicacion bancaria de su preferencia te que tenga la opción de pagos por QR"}
                                aclariacion={""}
                            />
                        </Col>
                        <Col sm={1} md={1} className="d-flex">
                            <div className="text-center text-info m-auto"><h1 className=""><TbSquareRoundedNumber2Filled /></h1></div>
                        </Col>

                        <Col sm={11} md={5} className="d-flex mt-2 mb-2">
                            <StepCard
                                img={payQrImg}
                                titulo={"Busca la opción Escanear QR"}
                                detalle={"Localice la opción escanear codigo QR o Pagar por QR, inicie esta opción"}
                                aclariacion={""}
                            />
                        </Col>
                        <Col sm={1} md={1} className="d-flex">
                            <div className="text-center text-info m-auto"><h1 className=""><TbSquareRoundedNumber3Filled /></h1></div>
                        </Col>

                        <Col sm={11} md={5} className="d-flex mt-2 mb-2">
                            <StepCard
                                img={scanQrImg}
                                titulo={"Escanea el Codigo Generado"}
                                detalle={"Debe acercar su telefono hacia el codigo QR con el obetivo de escanear el mismo desde su app, entonces se desplegara los datos del pago"}
                                aclariacion={""}
                            />
                        </Col>
                        <Col sm={1} md={1} className="d-flex">
                            <div className="text-center text-info m-auto"><h1 className=""><TbSquareRoundedNumber4Filled /></h1></div>
                        </Col>
                        <Col sm={11} md={5} className="d-flex mt-2 mb-2">
                            <StepCard
                                img={pagoImg}
                                titulo={"Confirma el Pago"}
                                detalle={"Debe confirmar el pago siguiendo las instrucciones de su banca movil"}
                                aclariacion={""}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    </div>);
}

export default TutorialPagoQr;