import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { downloadPreRegistro, infoPreDocumentos, infoPrePersonal, infoPreTitulos } from "../../http/log";
import { FaDownload, FaFilePdf } from 'react-icons/fa';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import CuadroImpresion from "../cuadro-impresion/CuadroImpresion";
import UapEncabezado from "../../components/uap-encabezado/UapEncabezado";
import ActualizacionDocente from "../form-encabezado/ActualizacionDocente";
import { useUsuario } from "../../context/UsuarioContext";
import { Link } from "react-router-dom";


function StepInfoFin({ prevStep, handleFormData, values }) {

    const { infoBasicData, setSpinnerLoad } = useUsuario();

    const [dataPersona, setDataPersona] = useState([]);
    const [dataTitulos, setDataTitulos] = useState([]);
    const [dataDocumentos, setDataDocumentos] = useState([]);

    //inicar haciendo una peticion http para obtener datos
    useEffect(() => {
        const getDataPersonal = () => {
            infoPrePersonal().then(
                data => {
                    if (data != null) {
                        setDataPersona(data);
                    }
                }
            )
        };
        const getDataTitulos = () => {
            infoPreTitulos().then(
                data => {
                    if (data != null) {
                        setDataTitulos(data);
                    }
                }
            )
        };
        const getDataDocumentos = () => {
            infoPreDocumentos().then(
                data => {
                    if (data != null) {
                        setDataDocumentos(data);
                    }
                }
            )
        };

        getDataPersonal();
        getDataTitulos();
        getDataDocumentos();

    }, []);
    //**************** */


    // after form submit validating the form data using validator
    const submitFormData = (e) => {
        e.preventDefault();
        prevStep();
    };

    const personal = () => {
        return (
            <div className="info_basico mt-4">
                <div className="col-12">
                    <hr />
                    <p className="m-1 roboto-medium">DATOS GENERALES</p>
                    <hr />
                </div>
                <div>
                    <Table responsive size="sm" className='align-middle'>
                        <tbody className="font14">
                            <tr>
                                <td className='text-start'><strong>Nombre:</strong></td>
                                <td className='text-end'>{dataPersona?.nombres}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Primer Apellido:</strong></td>
                                <td className='text-end'>{dataPersona?.paterno}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Segundo Apellido:</strong></td>
                                <td className='text-end'>{dataPersona?.materno}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Cédula de Identidad:</strong></td>
                                <td className='text-end'>{dataPersona?.ci}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>C.I. Expedido en:</strong></td>
                                <td className='text-end'>{dataPersona?.expedido_dep}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Sexo:</strong></td>
                                <td className='text-end'>{dataPersona?.sexo}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Estado Civil:</strong></td>
                                <td className='text-end'>{dataPersona?.estado_civil}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>País de Nacimiento:</strong></td>
                                <td className='text-end'>{dataPersona?.pais} </td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Departamento de Nacimiento:</strong></td>
                                <td className='text-end'>{dataPersona?.departamento}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Provincia de Nacimiento:</strong></td>
                                <td className='text-end'>{dataPersona?.provincia}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Localidad de Nacimiento:</strong></td>
                                <td className='text-end'>{dataPersona?.localidad}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Fecha de Nacimiento:</strong></td>
                                <td className='text-end'>{dataPersona?.nacimiento}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Domicilio:</strong></td>
                                <td className='text-end'>{dataPersona?.direccion}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Correo Personal:</strong></td>
                                <td className='text-end'>{dataPersona?.correo}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Celular</strong></td>
                                <td className='text-end'>{dataPersona?.celular}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Celular Emp. Telefónica</strong></td>
                                <td className='text-end'>{dataPersona?.empresa_telefonica}</td>
                            </tr>
                            <tr>
                                <td className='text-start'><strong>Grupo Sanguineo:</strong></td>
                                <td className='text-end'>{dataPersona?.sangre}</td>
                            </tr>

                        </tbody>
                    </Table>
                </div>
            </div>
        );
    };

    const titulos = () => {
        console.log(dataTitulos);
        return (
            <div>
                <div className="col-12">
                    <hr />
                    <p className="m-1 roboto-medium">DATOS ACADEMICOS</p>
                </div>
                <Table responsive bordered hover size="sm" >
                    <thead>
                        <tr className="text-center">
                            <th>Titulo</th>
                            <th>Institución</th>
                            <th>Grado Académico</th>
                            <th>Descripción</th>
                            <th>Año</th>
                            <th>Nro. Serie</th>
                        </tr>
                    </thead>
                    <tbody className="font14">

                        {dataTitulos.length !== 0 ? <>{dataTitulos.map((titulo, index) =>
                            <tr key={index}>
                                <td className='text-center'>{titulo.titulo}</td>
                                <td className='text-center'>{titulo.institucion}</td>
                                <td className='text-center'>{titulo.grado}</td>
                                <td className='text-center'>{titulo.descripcion}</td>
                                <td className='text-center'>{titulo.anio}</td>
                                <td className='text-center'>{titulo.serie}</td>
                            </tr>
                        )}</> : <tr>
                            <td className='text-center' colSpan={6}>
                                <p className=" mt-3 mb-3">Sin datos de actualización</p>
                            </td>
                        </tr>
                        }

                    </tbody>
                </Table>
            </div>
        );
    }

    const documentos = () => {
        return (
            <div>
                <div className="col-12">
                    <hr />
                    <p className="m-1 roboto-medium">DOCUMENTACIÓN DOCENTE</p>
                </div>
                <Table responsive className='align-middle' bordered hover size="sm" >
                    <thead>
                        <tr>
                            <th className='text-center'>#</th>
                            <th className='text-center'>Documento</th>
                            <th className='text-center'>Numero / Serie</th>
                            <th className='text-center'><FaFilePdf className="text-danger" /></th>
                        </tr>
                    </thead>
                    <tbody className="font14">
                        {dataDocumentos.length !== 0 ? <>{dataDocumentos.map((data, index) =>
                            <tr key={index.toString()}>
                                <td>{index + 1}</td>
                                <td className="text-start">{data.dctTipoDocumento.tipo_documento}</td>
                                <td className='text-center'>
                                    {data.numero_doc}
                                </td>
                                <td className='text-center'>
                                    {data.ruta_archivo !== "" ? <BsFillCheckCircleFill className="text-success" /> : <></>}
                                </td>

                            </tr>)
                        }</> : <tr>
                            <td className='text-center' colSpan={4}>
                                <p className=" mt-3 mb-3">Sin datos de actualización</p>
                            </td>
                        </tr>
                        }
                    </tbody>
                </Table>
            </div>
        );
    }

    const aclaracion = () => {
        return (
            <div>
                <div className="col-12 font12 mt-5 mb-5">
                    <p className="m-1"> Yo, <strong>{infoBasicData?.nombres} {infoBasicData?.paterno} {infoBasicData?.materno}</strong> identificado con C.I. Nro. <strong>{infoBasicData?.ci}</strong> declaro bajo juramento de ley, la veracidad de mis datos personales, institucionales y académicos consignados en este documento. Para tal efecto dejo como constancia mi firma.</p>
                </div>
                <br />
                <br />
                <div className="col-12 font12 mt-6 mb-3 text-center">
                    <p className="">.................................................................</p>
                    <p>{infoBasicData?.nombres} {infoBasicData?.paterno} {infoBasicData?.materno}</p>
                    <p>CI. {infoBasicData?.ci}</p>
                </div>
            </div>
        );
    }

    const doc = () => {
        return (
            <div>
                <div className="col-12 text-center">
                    {personal()}
                </div>
                <br />
                <div className="col-12 text-center">
                    {titulos()}
                </div>
                <br />
                <div className="col-12 text-center">
                    {documentos()}
                </div>
                <div className="col-12">
                    {aclaracion()}
                </div>
            </div>
        );
    }

    function dowloadPDF() {
        setSpinnerLoad(true);
        downloadPreRegistro().then(
            data => {
                setSpinnerLoad(false);
            }
        );
    }

    return (
        <div>
            <div className='text-muted'><strong>Importante: </strong><small>Para registrar la actualización se debe presentar el siguiente documento impreso, con los
                documentos de respaldo necesarios.</small></div>

            <div>
                <div className="mb-3 mt-3 text-end">
                    <Button variant="info" onClick={dowloadPDF}>
                        Descargar e Imprimir
                    </Button>
                </div>
                <CuadroImpresion encabezado={ActualizacionDocente()} cuerpo={doc()} />
                <div className="col-12 mb-5 mt-5">
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <Button variant="secondary" onClick={prevStep}>
                            VOLVER ATRÁS
                        </Button>
                        <Link to="../informacion" className="btn btn-success">FINALIZAR ACTUALIZACIÓN</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default StepInfoFin;