import React from 'react';
import { NAME_LOCALSTORAGE_TOKEN } from '../config/info_app';
import { exitLogin, mostrarAlertaDanger, mostrarAlertaErrorInfo, mostrarAlertaInfo } from '../alerts/Alerts';

function verificarCode(data) {

  if(data.status==null || data.status==undefined){
    return true;
  }
  
  switch (data.status) {
    case 403:
      localStorage.removeItem(NAME_LOCALSTORAGE_TOKEN); 
      mostrarAlertaErrorInfo(data.mensaje);
      exitLogin(data.mensaje);
      return false;
    case 200:
      mostrarAlertaInfo(data.mensaje);
      return true;
    default:
      mostrarAlertaErrorInfo(data.mensaje);
      console.log(`Sorry, we are out of ${data.status}.`);
      return false;
  }

}

export default verificarCode;