import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { GiSpikyExplosion } from "react-icons/gi";
import { useUsuario } from "../../context/UsuarioContext";
import { departamentosRest, empresasTelefonicasRest, estadosCivilesRest, expedidoRest, infoPersonal, localidadRest, paisesRest, provinciasRest, sexosRest, uploadInfoPersonal } from "../../http/log";

function StepInfoPersonal({ nextStep, handleFormData, prevStep, values }) {

    const { setSpinnerLoad } = useUsuario();

    const [data, setData] = useState([]);
    const [sexos, setSexos] = useState([]);
    const [estadosCiviles, setEstadosCiviles] = useState([]);
    const [expedidos, setExpedidos] = useState([]);
    const [paises, setPaises] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [localidades, setLocalidades] = useState([]);
    const [empresasTelefonicas, setEmpresasTelefonicas] = useState([]);

    const [formValue, setformValue] = useState({});

    function reset(datax){
        setformValue({
            nombres: datax.nombres,
            paterno: datax.paterno,
            materno: datax.materno,
            ci: datax.ci,
            codigo_expedido: datax.codigo_expedido,
            codigo_estado_civil: datax.codigo_estado_civil,
            codigo_sexo: datax.codigo_sexo,
            nacimiento: datax.nacimiento,
            codigo_pais: datax.codigo_pais,
            codigo_departamento: datax.codigo_departamento,
            codigo_provincia: datax.codigo_provincia,
            codigo_localidad: datax.codigo_localidad,
            direccion: datax.direccion,
            correo: datax.correo,
            celular: datax.celular,
            codigo_empresa_telefonica : datax.codigo_empresa_telefonica,
            sangre: datax.sangre,
        });
    }
    //inicar haciendo una peticion http para obtener datos
    useEffect(() => {
        const getData = () => {
            infoPersonal().then(
                data => { if (data != null) { setData(data); reset(data);}}
            )
        };
        const getSexos = () => {
            sexosRest().then(
                data => { if (data != null) { setSexos(data); } }
            )
        };
        const getEstadosCiviles = () => {
            estadosCivilesRest().then(
                data => { if (data != null) { setEstadosCiviles(data); } }
            )
        };

        const getExpedidos = () => {
            expedidoRest().then(
                data => { if (data != null) { setExpedidos(data); } }
            )
        };

        const getPaises = () => {
            paisesRest().then(
                data => { if (data != null) { setPaises(data); } }
            )
        };

        const getDepartamentos = () => {
            departamentosRest().then(
                data => { if (data != null) { setDepartamentos(data); } }
            )
        };

        const getProvincias = () => {
            provinciasRest().then(
                data => { if (data != null) { setProvincias(data); } }
            )
        };

        const getLocalidades = () => {
            localidadRest().then(
                data => { if (data != null) { setLocalidades(data); } }
            )
        };

        const getEmpresasTelefonicas = () => {
            empresasTelefonicasRest().then(
                data => { if (data != null) { setEmpresasTelefonicas(data); } }
            )
        };

        getData();
        getSexos();
        getEstadosCiviles();
        getExpedidos();
        getPaises();
        getDepartamentos();
        getProvincias();
        getLocalidades();
        getEmpresasTelefonicas();

    }, []);
    //**************** */



    const handleSubmit = (event) => {
        setSpinnerLoad(true);

        uploadInfoPersonal(formValue).then(data => {
            setSpinnerLoad(false);
            if(data!=null){
                nextStep();
            }
        });
        //console.log(formValue);
    }

    const handleChange = (event) => {
        setformValue({
            ...formValue,
            [event.target.name]: event.target.value
        });
    }

    return (
        <div>
            <p className="pb-3 pt-3 fw-bold">Revisa tu información personal, puedes modificarlo si es necesario.</p>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Nombres</Form.Label>
                            <Form.Control
                                defaultValue={data.nombres}
                                type="text"
                                placeholder=""
                                name="nombres"
                                onChange={handleChange} />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>1er Apellido</Form.Label>
                            <Form.Control
                                defaultValue={data.paterno}
                                type="text"
                                placeholder=""
                                name="paterno"
                                onChange={handleChange} />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>2do Apellido</Form.Label>
                            <Form.Control
                                defaultValue={data.materno}
                                type="text"
                                placeholder=""
                                name="materno"
                                onChange={handleChange} />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>C.I.</Form.Label>
                            <Form.Control
                                defaultValue={data.ci}
                                type="text"
                                placeholder=""
                                name="ci"
                                onChange={handleChange} />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>C.I. Expedido</Form.Label>
                            <Form.Select aria-label="Default select example"
                                name="codigo_expedido"
                                onChange={handleChange}>
                                <option value={data.codigo_expedido}>{data.expedido_dep} (actual)</option>
                                {expedidos.map((expedido, index) => {
                                    return <option key={index} value={expedido.id_expedido_dip}>{expedido.descripcion}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Estado Civil</Form.Label>
                            <Form.Select aria-label="Default select example"
                                name="codigo_estado_civil"
                                onChange={handleChange}>
                                <option value={data.codigo_estado_civil}>{data.estado_civil} (actual)</option>
                                {estadosCiviles.map((estadoCivil, index) => {
                                    return <option key={index} value={estadoCivil.id_tipo_estado_civil}>{estadoCivil.tipo_estado_civil}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Sexo</Form.Label>
                            <Form.Select aria-label="Default select example"
                                name="codigo_sexo"
                                onChange={handleChange}>
                                <option value={data.codigo_sexo}>{data.sexo} (actual)</option>
                                {sexos.map((sexo, index) => {
                                    return <option key={index} value={sexo.id_tipo_sexo}>{sexo.tipo_sexo}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Fecha de Nacimiento</Form.Label>
                            <Form.Control
                                defaultValue={data.nacimiento}
                                type="date"
                                placeholder=""
                                name="nacimiento"
                                onChange={handleChange} />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>País de Nacimiento</Form.Label>
                            <Form.Select aria-label="Default select example"
                                name="codigo_pais"
                                onChange={handleChange}>
                                <option value={data.codigo_pais}>{data.pais} (actual)</option>
                                {paises.map((pais, index) => {
                                    return <option key={index} value={pais.id_pais}>{pais.pais}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Departamento</Form.Label>
                            <Form.Select aria-label="Default select example"
                                name="codigo_departamento"
                                onChange={handleChange}>
                                <option value={data.codigo_departamento}>{data.departamento} (actual)</option>
                                {departamentos.map((departamento, index) => {
                                    return <option key={index} value={departamento.id_departamento}>{departamento.departamento}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Provincia</Form.Label>
                            <Form.Select aria-label="Default select example"
                                name="codigo_provincia"
                                onChange={handleChange}>
                                <option value={data.codigo_provincia}>{data.provincia} (actual)</option>
                                {provincias.map((provincia, index) => {
                                    return <option key={index} value={provincia.id_provincia}>{provincia.provincia}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Localidad</Form.Label>
                            <Form.Select aria-label="Default select example"
                                name="codigo_localidad"
                                onChange={handleChange}>
                                <option value={data.codigo_localidad}>{data.localidad} (actual)</option>
                                {localidades.map((localidad, index) => {
                                    return <option key={index} value={localidad.id_localidad}>{localidad.idLocalidad}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Dirección de Domicilio</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder=""
                                defaultValue={data.direccion}
                                name="direccion"
                                onChange={handleChange} />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Correo Electrónico Personal</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder=""
                                defaultValue={data.correo}
                                name="correo"
                                onChange={handleChange} />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Número de Celular</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder=""
                                defaultValue={data.celular}
                                name="celular"
                                onChange={handleChange} />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Empresa Telefónica</Form.Label>
                            <Form.Select aria-label="Default select example"
                                name="codigo_empresa_telefonica"
                                onChange={handleChange}>
                                <option value={data.codigo_empresa_telefonica}>{data.empresa_telefonica} (actual)</option>
                                {empresasTelefonicas.map((empresaTelefonica, index) => {
                                    return <option key={index} value={empresaTelefonica.id_tipos_empresa_telefonica}>{empresaTelefonica.tipo_empresa_telefonica}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Grupo Sanguineo</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder=""
                                defaultValue={data.sangre}
                                name="sangre"
                                onChange={handleChange} />
                        </Form.Group>
                    </div>
                    <div className="col-12 mb-5 mt-3">
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <Button variant="secondary" onClick={prevStep}>
                                ATRÁS
                            </Button>
                            <Button variant="primary" onClick={handleSubmit}>
                                GUARDAR Y CONTINUAR
                            </Button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default StepInfoPersonal;