
import { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import uploadImg from '../../assets/icons/cloud-upload-regular-240.png';
import fileDefault from '../../assets/icons/file-blank-solid-240.png';
import fileCSS from '../../assets/icons/file-css-solid-240.png';
import filePdf from '../../assets/icons/file-pdf-solid-240.png';
import filePng from '../../assets/icons/file-png-solid-240.png';
import './file.css';


function ModalFilePdf(props) {

    const ImageConfig = {
        default: fileDefault,
        pdf: filePdf,
        png: filePng,
        css: fileCSS
    }

    const wrapperRef = useRef(null);

    const [fileList, setFileList] = useState([]);

    const onDragEnter = () => wrapperRef.current.classList.add('dragover');

    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const onFileDrop = (e) => {
        const newFile = e.target.files[0];
        if (newFile) {
            const updatedList = [...fileList, newFile];
            setFileList(updatedList);
        }
    }

    const fileRemove = (file) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
    }


    function addNew() {

        props.onChange({
            name:props.nameFile,
            file:fileList[0]
        });
        ClearAndClose();
    }

    function ClearAndClose(){
        setFileList([]);
        props.onHide();
    }



    return (
        <div className="modal_upInfo">
            <Modal show={props.show} onHide={props.onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}>
                <Modal.Header>
                    <Modal.Title className="col-12">
                        <p className="text-center">Agregar Nuevo Documento Digital</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <>
                        <>{
                            fileList.length === 0 ? (
                                <div
                                    ref={wrapperRef}
                                    className="drop-file-input"
                                    onDragEnter={onDragEnter}
                                    onDragLeave={onDragLeave}
                                    onDrop={onDrop}
                                >
                                    <div className="drop-file-input__label">
                                        <img src={uploadImg} alt="" />
                                        <p>Arrastra y suelta tu archivos PDF aquí</p>
                                    </div>
                                    <input type="file" value="" onChange={onFileDrop} accept="application/pdf" />
                                </div>
                            ) : null
                        }
                        </>

                        {
                            fileList.length > 0 ? (
                                <div className="drop-file-preview">
                                    <p className="drop-file-preview__title">
                                        Documento Cargado
                                    </p>
                                    {
                                        fileList.map((item, index) => (
                                            <div key={index} className="drop-file-preview__item">
                                                <img src={ImageConfig[item.type.split('/')[1]] || ImageConfig['default']} alt="" />
                                                <div className="drop-file-preview__item__info">
                                                    <p>{item.name}</p>
                                                    <p>{item.size}B</p>
                                                </div>
                                                <span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>x</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            ) : null
                        }
                    </>

                </Modal.Body>

                <Modal.Footer className='p-0'>
                    <div className='row col-12 m-0'>
                        <div onClick={ClearAndClose} className='col-6 btn-ios rounded-bottom'>Cancelar</div>
                        <span onClick={addNew} className='col-6 border-start btn-ios rounded-bottom'>Guardar Documento</span>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ModalFilePdf;