import { useEffect, useState } from "react";
import StepInfoPersonal from "../../components/stepsInfo/StepInfoPersonal";
import StepInfo1 from "../../components/stepsInfo/StepInfo1";
import "./actualizarInformacion.css";
import { IoMdInformationCircle } from "react-icons/io"
import { FaUserAlt, FaUserGraduate } from "react-icons/fa"
import { SiBookstack } from "react-icons/si"
import { GiNotebook } from "react-icons/gi"
import { infoUser } from "../../http/log";
import StepInfoAcademica from "../../components/stepsInfo/StepInfoAcademica";
import { useUsuario } from '../../context/UsuarioContext';
import StepReqDocente from "../../components/stepsInfo/StepReqDocente";
import { Button, Col, Container, Row } from "react-bootstrap";
import StepInfoFin from "../../components/stepsInfo/StepInfoFin";

function ActualizarInformacion() {

    //state for steps
    var steps = [
        { key: '1Step', label: 'Inicio', icono: <IoMdInformationCircle />, isSelected: true, isDone: true },
        { key: '2Step', label: 'Información General', icono: <FaUserAlt />, isSelected: false, isDone: false},
        { key: '3Step', label: 'Información Académica', icono: <FaUserGraduate />, isSelected: false, isDone: false},
        { key: '4Step', label: 'Requisitos Docente', icono: <SiBookstack />, isSelected: false, isDone: false },
        { key: '5Step', label: 'Fin', icono: <GiNotebook />, isDone: false, isSelected: false},
    ];
    const [step, setstep] = useState(1);
    const [iPersonal, setIPersonal] = useState({});

    //state for form data
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        age: "",
        email: ""
    })

    // function for going to next step by increasing step state by 1
    const nextStep = () => {
        setstep(step + 1);
    };

    // function for going to previous step by decreasing step state by 1
    const prevStep = () => {
        setstep(step - 1);
    };

    // handling form input data by taking onchange value and updating our previous form data state
    const handleInputData = input => e => {
        // input value from the form
        const { value } = e.target;

        //updating for data state taking previous state and then adding new value to create new object
        setFormData(prevState => ({
            ...prevState,
            [input]: value
        }));
    }

    function fin(posic){
        if(posic===5){
            return(
                <div><StepInfoFin prevStep={prevStep}/></div>
            );
        }else{
            return(
                <div></div>
            );
        }
        
    }

    return (

        <div className="actualizarasd">
            <div className='container mb-3'>
                <div className='row p-2'>
                    <div className='col-12 mt-4 mb-2 text-start'>
                        <h3>Formulario de Actualización de Información</h3>
                    </div>
                    <div className=' col-12 col-lg-12 pb-3'>
                        <div className='bg-white shadow bg-body rounded '>
                            <div className="p-3">
                                <div className="d-flex justify-content-around h-stepper">
                                    {steps.map((stepData, i) => {
                                        return <div key={i} className="step-box">
                                            <div className={` ${'d-flex step-icon-box'} ${ i< step  ? 'active' : ''}`}>
                                                <div className="step-icon">
                                                    {stepData.icono}
                                                </div>
                                            </div>
                                            <div className={` ${'text-center '} ${ i+1=== step ? 'fw-bold' : 'noScreenMin'}`}>
                                                {stepData.label}
                                            </div>
                                        </div>
                                    })}

                                </div>
                            </div>
                            <hr />

                            <div className="step-component m-3">
                                <Container>
                                    <Row>
                                        <Col md={12} className="custom-margin" style={{ display: step === 1 ? "block" : "none" }}>
                                            <StepInfo1 nextStep={nextStep} handleFormData={handleInputData} values={(childData) =>{setIPersonal(childData)}} />
                                        </Col>
                                        <Col md={12} className="custom-margin" style={{ display: step === 2 ? "block" : "none" }}>
                                            <StepInfoPersonal nextStep={nextStep} prevStep={prevStep} />
                                        </Col>
                                        <Col md={12} className="custom-margin" style={{ display: step === 3 ? "block" : "none" }}>
                                            <StepInfoAcademica data={[]} nextStep={nextStep} prevStep={prevStep} />
                                        </Col>
                                        <Col md={12} className="custom-margin" style={{ display: step === 4 ? "block" : "none" }}>
                                            <StepReqDocente data={[]} nextStep={nextStep} prevStep={prevStep} />
                                        </Col>
                                        <Col md={12} className="custom-margin">
                                            {fin(step)}
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );



}


export default ActualizarInformacion;