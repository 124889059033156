import { useState } from "react";
import { Button } from "react-bootstrap";

function StepInfo1({ nextStep, handleFormData, values }) {

    // after form submit validating the form data using validator
    const submitFormData = (e) => {
        e.preventDefault();

        nextStep();
    };

    return (
        <div>
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <p className="h3 m-3">Hola, bienvenido a la sección de Actualización de Información docente Universiario.</p>
                            <p className="m-4">* Sigue las instrucciones cuidadosamente, al finalizar se generará un documento que debe ser presentado juntamente con los documentos necesarios para confirmar la actualización</p>
                        </div>
                    </div>
                </div>

                <div className="col-12 mb-5">
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <Button variant="primary" onClick={nextStep}>
                                CONTINUAR
                            </Button>
                        </div>
                    </div>
            </div>
        </div>
    );
}
export default StepInfo1;