import React, { useEffect, useState } from "react";
import { useToken } from "../hooks/useToken";
import { infoBasic } from "../http/log";

const UsuarioContext = React.createContext();

export function UsuarioProvider(props){
    
    const { token, setToken } = useToken();
    const [infoBasicData, setInfoBasicData] = useState();
    const [spinnerLoad, setSpinnerLoad]=useState(false);
    const [loginModalLoad, setLoginModalLoad]=useState(false);

    const loadData=()=>{
        setSpinnerLoad(true);
        infoBasic().then(
          data =>{
            if(data!=null ){
              if(data.status===200 && data.ok){
                console.log(data.data);
                setInfoBasicData(data.data);
              }else{
                
              }
            }
            setSpinnerLoad(false);
          }
        )
    }
    useEffect(()=>{
        if(token){
            loadData();
        }
        
      },[token]);

      const value = React.useMemo(()=>{
        return({
            token,
            infoBasicData,
            spinnerLoad,
            loginModalLoad,
            setToken,
            setSpinnerLoad,
            setLoginModalLoad,
            loadData
        });
      },[token,infoBasicData,spinnerLoad,loginModalLoad]);

      return <UsuarioContext.Provider value={value} {...props} />
}

export function useUsuario(){
    const context = React.useContext(UsuarioContext);
    if(!context){
        throw new Error('useUsuario debe estar dentro del proveedor UsuarioContext');
    }
    return context;
}