import './politicas.css';

export const Politicas = () => {
    return (
        <div>
            <div className="page_p">
                <div className="header_p">
                    <div className="container">
                        <p className="title"><font style={{verticalAlign: "inherit"}}>Política de privacidad de Perla NET </font></p>
                    </div>
                </div>
                <div className="translations-content-container">
                    <div className="container">
                        <div className="tab-content translations-content-item en visible" id="en">
                            <h1><font style={{verticalAlign: "inherit"}}>Política de privacidad </font></h1>
                            <p><font style={{verticalAlign: "inherit"}}>Última actualización: 16 de octubre de 2022 </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>Esta Política de privacidad describe Nuestras políticas y procedimientos sobre la recopilación, el uso y la divulgación de Su información cuando utiliza el Servicio y le informa sobre Sus derechos de privacidad y cómo la ley lo protege. </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>Usamos sus datos personales para proporcionar y mejorar el Servicio.   Al usar el Servicio, acepta la recopilación y el uso de información de acuerdo con esta Política de privacidad.   Esta Política de Privacidad ha sido creada con la ayuda del </font><a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank"><font style={{verticalAlign: "inherit"}}> Generador de Políticas de Privacidad </font></a><font style={{verticalAlign: "inherit"}}> . </font></p>
                            <h1><font style={{verticalAlign: "inherit"}}>Interpretación y Definiciones </font></h1>
                            <h2><font style={{verticalAlign: "inherit"}}>Interpretación </font></h2>
                            <p><font style={{verticalAlign: "inherit"}}>Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes condiciones.  Las siguientes definiciones tendrán el mismo significado independientemente de que aparezcan en singular o en plural. </font></p>
                            <h2><font style={{verticalAlign: "inherit"}}>Definiciones </font></h2>
                            <p><font style={{verticalAlign: "inherit"}}>A los efectos de esta Política de Privacidad: </font></p>
                            <ul>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Cuenta </font></strong><font style={{verticalAlign: "inherit"}}> significa una cuenta única creada para que Usted acceda a nuestro Servicio o partes de nuestro Servicio. </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Afiliado </font></strong><font style={{verticalAlign: "inherit"}}> significa una entidad que controla, es controlada o está bajo control común con una parte, donde "control" significa la propiedad del 50% o más de las acciones, participación accionaria u otros valores con derecho a voto para la elección de directores u otra autoridad administrativa. . </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Aplicación </font></strong><font style={{verticalAlign: "inherit"}}> significa el programa de software proporcionado por la Compañía descargado por Usted en cualquier dispositivo electrónico, llamado Perla NET </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Empresa </font></strong><font style={{verticalAlign: "inherit"}}> (referida como "la Empresa", "Nosotros", "Nos" o "Nuestro" en este Acuerdo) se refiere a Perla NET. </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>País </font></strong><font style={{verticalAlign: "inherit"}}> se refiere a: Bolivia </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Dispositivo </font></strong><font style={{verticalAlign: "inherit"}}> significa cualquier dispositivo que pueda acceder al Servicio, como una computadora, un teléfono celular o una tableta digital. </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Los datos personales </font></strong><font style={{verticalAlign: "inherit"}}> son cualquier información que se relaciona con un individuo identificado o identificable. </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Servicio </font></strong><font style={{verticalAlign: "inherit"}}> se refiere a la Aplicación. </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Proveedor de servicios </font></strong><font style={{verticalAlign: "inherit"}}> significa cualquier persona física o jurídica que procesa los datos en nombre de la Compañía.   Se refiere a empresas de terceros o personas empleadas por la Empresa para facilitar el Servicio, proporcionar el Servicio en nombre de la Empresa, realizar servicios relacionados con el Servicio o ayudar a la Empresa a analizar cómo se utiliza el Servicio. </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Los Datos de uso </font></strong><font style={{verticalAlign: "inherit"}}> se refieren a los datos recopilados automáticamente, ya sea generados por el uso del Servicio o por la propia infraestructura del Servicio (por ejemplo, la duración de una visita a la página). </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Usted </font></strong><font style={{verticalAlign: "inherit"}}> se refiere a la persona que accede o utiliza el Servicio, o la empresa u otra entidad legal en nombre de la cual dicha persona accede o utiliza el Servicio, según corresponda. </font></p>
                                </li>
                            </ul>
                            <h1>Collecting and Using Your Personal Data</h1>
                            <h2><font style={{verticalAlign: "inherit"}}>Tipos de datos recopilados </font></h2>
                            <h3><font style={{verticalAlign: "inherit"}}>Información personal </font></h3>
                            <p><font style={{verticalAlign: "inherit"}}>Mientras usa Nuestro Servicio, podemos pedirle que nos proporcione cierta información de identificación personal que se puede usar para contactarlo o identificarlo.  La información de identificación personal puede incluir, entre otros: </font></p>
                            <ul>
                                <li>
                                    <p><font style={{verticalAlign: "inherit"}}>Dirección de correo electrónico </font></p>
                                </li>
                                <li>
                                    <p><font style={{verticalAlign: "inherit"}}>Nombre y apellido </font></p>
                                </li>
                                <li>
                                    <p><font style={{verticalAlign: "inherit"}}>Número de teléfono </font></p>
                                </li>
                                <li>
                                    <p><font style={{verticalAlign: "inherit"}}>Dirección, estado, provincia, código postal, ciudad </font></p>
                                </li>
                                <li>
                                    <p><font style={{verticalAlign: "inherit"}}>Datos de uso </font></p>
                                </li>
                            </ul>
                            <h3><font style={{verticalAlign: "inherit"}}>Datos de uso </font></h3>
                            <p><font style={{verticalAlign: "inherit"}}>Los Datos de uso se recopilan automáticamente cuando se utiliza el Servicio. </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>Los datos de uso pueden incluir información como la dirección del protocolo de Internet de su dispositivo (por ejemplo, la dirección IP), el tipo de navegador, la versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha de su visita, el tiempo dedicado a esas páginas, dispositivo único identificadores y otros datos de diagnóstico. </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar cierta información automáticamente, que incluye, entre otros, el tipo de dispositivo móvil que utiliza, la identificación única de su dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema operativo, el tipo de navegador de Internet móvil que utiliza, identificadores únicos de dispositivos y otros datos de diagnóstico. </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando accede al Servicio a través de un dispositivo móvil. </font></p>
                            <h3><font style={{verticalAlign: "inherit"}}>Información recopilada durante el uso de la aplicación </font></h3>
                            <p><font style={{verticalAlign: "inherit"}}>Al utilizar Nuestra Aplicación, con el fin de proporcionar características de Nuestra Aplicación, podemos recopilar, con Su permiso previo: </font></p>
                            <ul>
                                <li><font style={{verticalAlign: "inherit"}}>Imágenes y otra información de la cámara y la biblioteca de fotos de su dispositivo </font></li>
                            </ul>
                            <p><font style={{verticalAlign: "inherit"}}>Usamos esta información para proporcionar características de Nuestro Servicio, para mejorar y personalizar Nuestro Servicio.  La información se puede cargar en los servidores de la Compañía y/o en el servidor de un Proveedor de servicios o simplemente se puede almacenar en Su dispositivo. </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>Puede habilitar o deshabilitar el acceso a esta información en cualquier momento, a través de la configuración de Su dispositivo. </font></p>
                            <h2><font style={{verticalAlign: "inherit"}}>Uso de sus datos personales </font></h2>
                            <p><font style={{verticalAlign: "inherit"}}>La Compañía puede utilizar los Datos Personales para los siguientes propósitos: </font></p>
                            <ul>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Para proporcionar y mantener nuestro Servicio </font></strong><font style={{verticalAlign: "inherit"}}> , incluso para monitorear el uso de nuestro Servicio. </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Para gestionar Su Cuenta: </font></strong><font style={{verticalAlign: "inherit"}}> para gestionar Su registro como usuario del Servicio.   Los Datos Personales que proporcione pueden darle acceso a diferentes funcionalidades del Servicio que están disponibles para Usted como usuario registrado. </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Para la ejecución de un contrato: </font></strong><font style={{verticalAlign: "inherit"}}> el desarrollo, cumplimiento y realización del contrato de compra de los productos, artículos o servicios que haya adquirido o de cualquier otro contrato con Nosotros a través del Servicio. </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Para contactarlo: </font></strong><font style={{verticalAlign: "inherit"}}> para contactarlo por correo electrónico, llamadas telefónicas, SMS u otras formas equivalentes de comunicación electrónica, como notificaciones automáticas de una aplicación móvil sobre actualizaciones o comunicaciones informativas relacionadas con las funcionalidades, productos o servicios contratados, incluidas las actualizaciones de seguridad, cuando sea necesario o razonable para su implementación. </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Para proporcionarle </font></strong><font style={{verticalAlign: "inherit"}}> noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos que son similares a los que ya compró o preguntó, a menos que haya optado por no recibir dicha información. </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Para gestionar Sus solicitudes: </font></strong><font style={{verticalAlign: "inherit"}}> Para atender y gestionar Sus solicitudes hacia Nosotros. </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Para transferencias comerciales: </font></strong><font style={{verticalAlign: "inherit"}}> podemos usar su información para evaluar o realizar una fusión, venta, reestructuración, reorganización, disolución u otra venta o transferencia de algunos o todos nuestros activos, ya sea como una empresa en marcha o como parte de una quiebra, liquidación, o procedimiento similar, en el que los Datos personales que tenemos sobre los usuarios de nuestro Servicio se encuentran entre los activos transferidos. </font></p>
                                </li>
                                <li>
                                    <p><strong><font style={{verticalAlign: "inherit"}}>Para otros fines </font></strong><font style={{verticalAlign: "inherit"}}> : podemos utilizar su información para otros fines, como el análisis de datos, la identificación de tendencias de uso, la determinación de la eficacia de nuestras campañas promocionales y para evaluar y mejorar nuestro Servicio, productos, servicios, marketing y su experiencia. </font></p>
                                </li>
                            </ul>
                            <p><font style={{verticalAlign: "inherit"}}>Podemos compartir su información personal en las siguientes situaciones: </font></p>
                            <ul>
                                <li><strong><font style={{verticalAlign: "inherit"}}>Con Proveedores de Servicios: </font></strong><font style={{verticalAlign: "inherit"}}> Podemos compartir Su información personal con Proveedores de Servicios para monitorear y analizar el uso de nuestro Servicio, para contactarlo. </font></li>
                                <li><strong><font style={{verticalAlign: "inherit"}}>Para transferencias comerciales: </font></strong><font style={{verticalAlign: "inherit"}}> Podemos compartir o transferir Su información personal en relación con, o durante las negociaciones de, cualquier fusión, venta de activos de la Compañía, financiamiento o adquisición de todo o una parte de Nuestro negocio a otra compañía. </font></li>
                                <li><strong><font style={{verticalAlign: "inherit"}}>Con afiliados: </font></strong><font style={{verticalAlign: "inherit"}}> podemos compartir su información con nuestros afiliados, en cuyo caso exigiremos a esos afiliados que respeten esta Política de privacidad.   Los afiliados incluyen nuestra empresa matriz y cualquier otra subsidiaria, socios de empresas conjuntas u otras empresas que controlamos o que están bajo control común con nosotros. </font></li>
                                <li><strong><font style={{verticalAlign: "inherit"}}>Con socios comerciales: </font></strong><font style={{verticalAlign: "inherit"}}> Podemos compartir Su información con Nuestros socios comerciales para ofrecerle ciertos productos, servicios o promociones. </font></li>
                                <li><strong><font style={{verticalAlign: "inherit"}}>Con otros usuarios: </font></strong><font style={{verticalAlign: "inherit"}}> cuando comparte información personal o interactúa en las áreas públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y puede distribuirse públicamente al exterior. </font></li>
                                <li><strong><font style={{verticalAlign: "inherit"}}>Con su consentimiento </font></strong><font style={{verticalAlign: "inherit"}}> : podemos divulgar su información personal para cualquier otro propósito con su consentimiento. </font></li>
                            </ul>
                            <h2><font style={{verticalAlign: "inherit"}}>Retención de sus datos personales </font></h2>
                            <p><font style={{verticalAlign: "inherit"}}>La Compañía conservará sus Datos personales solo durante el tiempo que sea necesario para los fines establecidos en esta Política de privacidad.  Conservaremos y utilizaremos sus datos personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a conservar sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales. </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>La Compañía también conservará los Datos de uso para fines de análisis interno.  Los Datos de uso generalmente se retienen por un período de tiempo más corto, excepto cuando estos datos se utilizan para fortalecer la seguridad o mejorar la funcionalidad de Nuestro Servicio, o cuando estamos legalmente obligados a retener estos datos por períodos de tiempo más largos. </font></p>
                            <h2><font style={{verticalAlign: "inherit"}}>Transferencia de sus datos personales </font></h2>
                            <p><font style={{verticalAlign: "inherit"}}>Su información, incluidos los Datos personales, se procesa en las oficinas operativas de la Compañía y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento.  Significa que esta información puede transferirse y mantenerse en computadoras ubicadas fuera de Su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de Su jurisdicción. </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>Su consentimiento a esta Política de privacidad seguido de Su envío de dicha información representa Su acuerdo con esa transferencia. </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>La Compañía tomará todas las medidas razonablemente necesarias para garantizar que sus datos se traten de forma segura y de acuerdo con esta Política de privacidad y no se realizará ninguna transferencia de sus datos personales a una organización o país, a menos que existan controles adecuados establecidos, incluida la seguridad de Sus datos y otra información personal. </font></p>
                            <h2><font style={{verticalAlign: "inherit"}}>Eliminar sus datos personales </font></h2>
                            <p><font style={{verticalAlign: "inherit"}}>Tiene derecho a eliminar o solicitar que lo ayudemos a eliminar los Datos personales que hemos recopilado sobre usted. </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>Nuestro Servicio puede darle la capacidad de eliminar cierta información sobre Usted dentro del Servicio. </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>Puede actualizar, modificar o eliminar su información en cualquier momento iniciando sesión en su cuenta, si tiene una, y visitando la sección de configuración de la cuenta que le permite administrar su información personal.  También puede comunicarse con nosotros para solicitar acceso, corregir o eliminar cualquier información personal que nos haya proporcionado. </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>Sin embargo, tenga en cuenta que es posible que necesitemos conservar cierta información cuando tengamos una obligación legal o una base legal para hacerlo. </font></p>
                            <h2><font style={{verticalAlign: "inherit"}}>Divulgación de sus datos personales </font></h2>
                            <h3><font style={{verticalAlign: "inherit"}}>Transacciones de negocios </font></h3>
                            <p><font style={{verticalAlign: "inherit"}}>Si la Compañía está involucrada en una fusión, adquisición o venta de activos, Sus Datos personales pueden transferirse.  Le enviaremos un aviso antes de que sus Datos personales se transfieran y queden sujetos a una Política de privacidad diferente. </font></p>
                            <h3><font style={{verticalAlign: "inherit"}}>Cumplimiento de la ley </font></h3>
                            <p><font style={{verticalAlign: "inherit"}}>En determinadas circunstancias, es posible que se le solicite a la Compañía que divulgue sus Datos personales si así lo exige la ley o en respuesta a solicitudes válidas de las autoridades públicas (por ejemplo, un tribunal o una agencia gubernamental). </font></p>
                            <h3><font style={{verticalAlign: "inherit"}}>Otros requisitos legales </font></h3>
                            <p><font style={{verticalAlign: "inherit"}}>La Compañía puede divulgar sus datos personales de buena fe cuando considere que esta acción es necesaria para lo siguiente: </font></p>
                            <ul>
                                <li><font style={{verticalAlign: "inherit"}}>Cumplir con una obligación legal </font></li>
                                <li><font style={{verticalAlign: "inherit"}}>Proteger y defender los derechos o propiedad de la Compañía </font></li>
                                <li><font style={{verticalAlign: "inherit"}}>Prevenir o investigar posibles irregularidades en relación con el Servicio </font></li>
                                <li><font style={{verticalAlign: "inherit"}}>Proteger la seguridad personal de los Usuarios del Servicio o del público </font></li>
                                <li><font style={{verticalAlign: "inherit"}}>Protéjase contra la responsabilidad legal </font></li>
                            </ul>
                            <h2><font style={{verticalAlign: "inherit"}}>Seguridad de sus datos personales </font></h2>
                            <p><font style={{verticalAlign: "inherit"}}>La seguridad de sus datos personales es importante para nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100 % seguro.  Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus datos personales, no podemos garantizar su seguridad absoluta. </font></p>
                            <h1><font style={{verticalAlign: "inherit"}}>Privacidad de los niños </font></h1>
                            <p><font style={{verticalAlign: "inherit"}}>Nuestro Servicio no se dirige a ninguna persona menor de 13 años. No recopilamos a sabiendas información de identificación personal de ninguna persona menor de 13 años. Si usted es un padre o tutor y sabe que su hijo nos ha proporcionado Datos personales, por favor Contáctenos.  Si nos damos cuenta de que hemos recopilado Datos personales de cualquier persona menor de 13 años sin verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de Nuestros servidores. </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>Si necesitamos basarnos en el consentimiento como base legal para procesar su información y su país requiere el consentimiento de uno de sus padres, podemos solicitar el consentimiento de su padre antes de recopilar y usar esa información. </font></p>
                            <h1><font style={{verticalAlign: "inherit"}}>Enlaces a otros sitios web </font></h1>
                            <p><font style={{verticalAlign: "inherit"}}>Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por Nosotros.  Si hace clic en el enlace de un tercero, será dirigido al sitio de ese tercero.  Le recomendamos encarecidamente que revise la Política de privacidad de cada sitio que visite. </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios o servicios de terceros. </font></p>
                            <h1><font style={{verticalAlign: "inherit"}}>Cambios a esta Política de Privacidad </font></h1>
                            <p><font style={{verticalAlign: "inherit"}}>Es posible que actualicemos nuestra Política de privacidad de vez en cuando.  Le notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página. </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>Le informaremos por correo electrónico y/o un aviso destacado en Nuestro Servicio, antes de que el cambio entre en vigencia y actualizaremos la fecha de "Última actualización" en la parte superior de esta Política de privacidad. </font></p>
                            <p><font style={{verticalAlign: "inherit"}}>Se le recomienda revisar esta Política de Privacidad periódicamente para cualquier cambio.  Los cambios a esta Política de privacidad son efectivos cuando se publican en esta página. </font></p>
                            <h1><font style={{verticalAlign: "inherit"}}>Contáctenos </font></h1>
                            <p><font style={{verticalAlign: "inherit"}}>Si tiene alguna pregunta sobre esta Política de privacidad, puede contactarnos: </font></p>
                            <ul>
                                <li><font style={{verticalAlign: "inherit"}}>Por correo electrónico: </font><font style={{verticalAlign: "inherit"}}> digo.empresa07@gmail.com </font></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footer">
                    <div className="container">
                        <p><font style={{verticalAlign: "inherit"}}>Generado usando </font><a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank"><font style={{verticalAlign: "inherit"}}> el Generador de Políticas de Privacidad </font></a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}