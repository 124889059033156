import React, { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Alert, Button, Form, Modal, Table } from "react-bootstrap";
import DivLoader from "../../components/divLoader/DivLoader";
import './administrarCalificaciones.css';
import LoginVerific from "../../components/loginVerific/LoginVerific";
import { BsFileEarmarkPdfFill } from "react-icons/bs";

import * as XLSX from "xlsx";

import { MdOutlineDeleteForever } from 'react-icons/md';

import uploadImg from '../../assets/icons/cloud-upload-regular-240.png';
import fileXlxs from '../../assets/icons/excel.jpg';
import { estudiantesAdminCalificacion, estudiantesAdminCalificacionAllUpdate, estudiantesAdminCalificacionUpdate } from "../../http/log_materia";
import { mostrarAlertaInfo, mostrarAlertaSuccess, mostrarMensajeOK } from "../../alerts/Alerts";
import { useUsuario } from "../../context/UsuarioContext";
import { preActaPDF, preActaSegundaInstanciaPDF } from "../../http/log_libretas";
import ModalInfoOk from "../../components/modals/ModalInfoOk";
import InputNota3 from "../../components/inputNota/InputNota3";
import { IoMdAlert } from "react-icons/io";
import { httpPost } from "../../http/request";

const AdministrarCalificaciones = () => {


    const { infoBasicData, setSpinnerLoad } = useUsuario();

    const location = useLocation();
    const dataMateria = location.state;

    const navigate = useNavigate()

    const [listaEstudiantes, setListaEstudiantes] = useState([]);

    const [isLoad, setIsLoad] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [modalExcel, setModalExcel] = useState(false);
    const [indexEdit, setIndexEdit] = useState(-1);
    const [isPonderacionEval, setIsPonderacionEval] = useState(false);
    const [modalInfo, setModalInfo] = useState(false);
    const [modalParametros, setModalParamtros] = useState(false);


    const [parametros, setParametros] = useState({});
    const [tramites, setTramites] = useState({});

    useEffect(() => {
        cargarVerificacionParametros();
    }, []);


    useEffect(() => {
        if (Object.keys(parametros).length !== 0) {
            cargarTramites();
        }
    }, [parametros]);


    const cargarTramites = () => {
        httpPost({
            codeAsig: dataMateria.asig.codigo_asig,
            parcial: dataMateria.eval.tipo_nota,
        }, "api_v1/tramite/verificarParametroHabilitacion").then((d) => {
            if (d) {
                setTramites(d);
            }
        });
    }

    const cargarVerificacionParametros = () => {
        httpPost({
            codeAsig: dataMateria.asig.codigo_asig,
            codeParcial: dataMateria.eval.id_tipo_nota,
        }, "api_v1/materia/verificarParametroRegistroNotasParcial").then((d) => {
            if (d) {
                setParametros(d);
            }
        });
    }

    function downloadPreActa(asig, ges, per, materia) {
        setSpinnerLoad(true);
        preActaPDF(asig, ges, per, materia).then(
            data => {
                setSpinnerLoad(false);
            }
        );
    }

    function downloadPreActaSegunda(asig, ges, per, materia) {
        setSpinnerLoad(true);
        preActaSegundaInstanciaPDF(asig, ges, per, materia).then(
            data => {
                setSpinnerLoad(false);
            }
        );
    }


    const cargarDatos = () => {

        if (dataMateria != null) {
            setIsLoad(true);

            estudiantesAdminCalificacion(dataMateria.asig?.codigo_asig, dataMateria.eval?.id_tipo_nota, dataMateria.asig?.gestion, dataMateria.asig?.periodo).then(
                data => {
                    if (data != null) {
                        setListaEstudiantes(data);
                    } else {
                        setIsLogin(false);
                    }
                    setIsLoad(false);
                }
            )

        } else {
            //window.location.href = '/asignaturas';
            navigate("/asignaturas");
        }
    };

    const listItems = (d) => d.map((data, index) =>
        <tr key={index.toString()}>
            <td className="ps-4">{index + 1}</td>
            <td className="text-center">{data.ru}</td>
            <td>{data.nombres}</td>
            <td className="text-center">{data.nuevo}</td>
            <td className="text-start">
                {/*<InputNota
                    index={index}
                    isPonderacion={isPonderacionEval}
                    nota={data.nota}
                    edit={indexEdit}
                    est={data}
                    asig={dataMateria?.asig}
                    eval={dataMateria?.eval}
                    onLoad={() => { setIndexEdit(index); console.log(index) }}
/>*/}
                {/*<InputNota2
                    isPonderacion={isPonderacionEval}
                    nota={data.nota}
                    est={data}
                    asig={dataMateria?.asig}
                    eval={dataMateria?.eval}
/>*/}
                {
                    <InputNota3
                        isPonderacion={isPonderacionEval}
                        nota={data.nota}
                        est={data}
                        asig={dataMateria?.asig}
                        eval={dataMateria?.eval}
                    />
                }

            </td>
        </tr>);


    let tabla = (d) => {
        return (
            <Table hover size="sm" responsive className='align-middle font12'>
                <thead>
                    <tr>
                        <th className='text-center'>#</th>
                        <th className='text-center'>R.U.</th>
                        <th className='text-center'>Nombre</th>
                        <th className='text-center'>Tipo</th>
                        <th className='text-center'>Nota (<span className="text-danger">{isPonderacionEval ? dataMateria?.eval?.ponderacion : "100"} p.</span>)</th>
                    </tr>
                </thead>
                <tbody>
                    {listItems(d)}
                </tbody>
            </Table>
        );
    }



    function pagina(is, d) {
        if (is) {
            return hoja(d);
        }
        return (
            <div>
                <LoginVerific
                    onLogin={() => { setIsLogin(true); cargarDatos() }}
                />
            </div>
        );
    }

    function hoja(d) {
        if (isLoad) {
            return (
                <div className="py-3">
                    <DivLoader />
                </div>
            );
        }
        return (
            <div>
                <div className="">
                    <p className="d-flex p-2">
                        {dataMateria.asig.maxima_nota !== dataMateria.eval.ponderacion ?
                            <Button className="m-1 me-auto fw-bold" variant={isPonderacionEval ? "outline-info" : "info"} size="sm" onClick={(e) => { setIsPonderacionEval(!isPonderacionEval); setModalInfo(true) }}>Cambiar evaluacion sobre {isPonderacionEval ? "100" : dataMateria?.eval?.ponderacion} puntos</Button>
                            : <span className="m-1 me-auto fw-bold"></span>}
                        <span className="w100"></span>

                        {dataMateria.asig?.obs_libretas ?
                            <>
                                <Button className="m-1" variant="light" size="sm" onClick={() => downloadPreActa(dataMateria.asig?.codigo_asig, dataMateria.asig?.gestion, dataMateria.asig?.periodo, dataMateria.asig?.materia)}><BsFileEarmarkPdfFill className="text-danger" /> Generar Pre-Acta</Button>
                                <>
                                    {dataMateria.asig?.obs_libretas_2da ?? false ? <Button className="m-1" variant="light" size="sm" onClick={() => downloadPreActaSegunda(dataMateria.asig?.codigo_asig, dataMateria.asig?.gestion, dataMateria.asig?.periodo, dataMateria.asig?.materia)}><BsFileEarmarkPdfFill className="text-danger" /> Generar Pre-Acta Segunda Instancia</Button> : <></>}
                                </>
                            </> : <></>}
                        <Button className="m-1" variant="success" size="sm" onClick={(e) => setModalExcel(true)}>Cargar notas desde documento Excel</Button>
                        <Button className="m-1" variant="outline-dark" size="sm" onClick={(e) => cargarDatos()}>Actualizar</Button>
                    </p>
                    <hr></hr>
                    {tabla(d)}
                </div>
            </div>
        );
    }


    const logicaParametros = () => {
        switch (parametros.bandera) {
            case 0:
                //No Tiene Registrado Ningún Parámetro Consulte con Sistemas
                return <div className="col-12 px-5 py-3"><Alert variant={"warning"} className="d-flex bg-white">
                    <span className="text-warning my-auto">
                        <IoMdAlert style={{ fontSize: "60px" }} />
                    </span>
                    <div className="ps-3 me-auto">
                        <p className="fw-bold">No se encontró regístro de Parámetros</p>
                        <p className="mb-2">Esto puede deberse a que aun la administración no registró los parámetros de fechas de registro de notas de acuerdo a calendario académico.</p>
                    </div>
                </Alert></div>;
            case 1:
                //Mostramos Login y Notas
                return <>
                    <div>
                        {pagina(isLogin, listaEstudiantes)}
                    </div>
                </>;
            case 2:
                //Aun no está habilitado debe esperar a la fecha establecida
                return <div className="col-12 px-5 py-3"><Alert variant={"success"} className="d-flex bg-white">
                    <span className="text-success my-auto">
                        <IoMdAlert style={{ fontSize: "60px" }} />
                    </span>
                    <div className="ps-3 me-auto">
                        <p className="fw-bold">La fecha actual no se encuentra dentro del intérvalo de fechas establecidas para el registro de notas en esta etapa</p>
                        <p className="mb-2">La fecha establecida para el registro de notas es del {parametros?.fecha_inicio} al {parametros?.fecha_final}</p>
                    </div>
                </Alert></div>;
            case 3:
                //Ya se Venció la fecha de registro
                return alertTramites();
            default:
                return <></>;
        }
    }


    const alertTramites = () => {

        if (Object.keys(tramites).length > 0) {

            return <div className="col-12 px-5 py-3">
                {tramites.esperando ?? false ?
                    <>
                        <Alert variant={"warning"} className="d-flex">
                            <span className="text-warning my-auto">
                                <IoMdAlert style={{ fontSize: "60px" }} />
                            </span>
                            <div className="ps-3 me-auto">
                                <p className="fw-bold">Esperando respuesta a su solicitud de parámetros de registro de notas</p>
                                <p className="">Usted ya realizó la Solicitud de Habilitación de Parametros, debe aguardar la respuesta.</p>
                                <p className="text-secondary font12">Es posible que la respuesta no sea la esperada. Este proceso sigue el conducto necesario para aprobar su Solicitud</p>
                            </div>
                        </Alert>
                    </> :
                    <>
                        <Alert variant={"danger"} className="d-flex">
                            <span className="text-danger my-auto">
                                <IoMdAlert style={{ fontSize: "60px" }} />
                            </span>
                            <div className="ps-3 me-auto">
                                <p className="fw-bold">No tiene habilitada la opción de registro de notas</p>
                                <p className="mb-2">Esto se debe a que se venció la fecha establecida para realizar el registro de notas en esta etapa ({dataMateria?.eval?.tipo_nota ?? " _ "}).</p>
                                <p className="">Para su habilitación puede realizar el tramite virtual de Solicitud de Parámetros en el siguiente botón</p>
                                <p className="text-secondary font12">La verificación y aprobación de su solicitud puede tardar, espere hasta que se responda su solicitud. Recibirá un mensaje de confirmación a su correo electrónico</p>
                            </div>
                            <div className="my-auto">
                                <button className="btn btn-outline-primary" onClick={() => setModalParamtros(true)}>Solicitar Parámetros</button>
                            </div>
                        </Alert>
                    </>
                }
                {tramites.tramites.length > 0 ? <>
                    <Alert variant={"info"} className="bg-white">
                        <div className="px-3 me-auto">
                            <p className="fw-bold">Este es un listado de solicitudes realizadas de Habilitación de Parámetros</p>
                            <p className="mb-2">Las solicitudes son las aplicadas a la asignatura {dataMateria?.asig?.materia ?? " _ "}  en {dataMateria?.eval?.tipo_nota ?? " _ "}.</p>
                            <div>
                                <Table size="sm" hover className="mt-3 border-dark">
                                    <thead className="table-dark">
                                        <tr>
                                            <th className="text-center">Fecha Solicitado</th>
                                            <th className="text-center">Estado de Solicitud</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tramites.tramites.map((d, i) =>
                                                <tr>
                                                    <td className="text-center align-middle">{d.fecha}</td>
                                                    <td className={`text-center  ${d.vigente ? 'text-success' : ''}`}><p className="text-uppercase fw-bold">{d.estado}</p>
                                                        {d.obs ? <p className="text-danger">Obs.: {d.obs ?? ""}</p> : <></>}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Alert>
                </> : <></>}
            </div>;
        }

        return <div className="col-12 px-5 py-3">
            <Alert variant={"danger"} className="d-flex">
                <span className="text-danger my-auto">
                    <IoMdAlert style={{ fontSize: "60px" }} />
                </span>
                <div className="ps-3 me-auto">
                    <p className="fw-bold">No tiene habilitada la opción de registro de notas</p>
                    <p className="mb-2">Esto se debe a que se venció la fecha establecida para realizar el registro de notas en esta etapa ({dataMateria?.eval?.tipo_nota ?? " _ "}).</p>
                    <p className="">Para su habilitación puede realizar el tramite virtual de Solicitud de Parámetros</p>
                    <p className="text-secondary font12">La verificación y aprobación de su solicitud puede tardar, espere hasta que se responda su solicitud. Recibirá un mensaje de confirmación a su correo electrónico</p>
                </div>
                <div className="my-auto">
                    <button className="btn btn-secondary" disabled={true}>Solicitar Parámetros</button>
                </div>
            </Alert></div>

    }

    return (
        <div>
            <div className='container'>
                <div className='row p-2'>
                    <div className="col-12 mt-3 mb-3">
                        <p className="h5 m-0">{dataMateria?.asig?.carrera ?? " _ "}</p>
                        <p className="roboto-condensed-regular h2">{dataMateria?.asig?.materia ?? " _ "}</p>
                        <p className="h4 m-0">{dataMateria?.asig?.periodo ?? " _ "} - {dataMateria?.asig?.gestion ?? " _ "}</p>
                    </div>
                    <div className=' col-12 col-lg-12'>
                        <div className='bg-white shadow mb-5 p-0 bg-body rounded '>

                            <div className="col-12 p-3">
                                <p className="h5 text-center roboto-condensed-regular m-0">ADMINISTRAR CALIFICACIONES - {dataMateria?.eval?.tipo_nota ?? " _ "} {dataMateria?.eval?.ponderacion ?? " _ "}%</p>
                                <p className="text-center text-danger m-0">{(parametros.bandera !== 0) ? `Habilitado del ${parametros?.fecha_inicio ?? "..."} hasta el ${parametros?.fecha_final ?? "..."}` : ""}</p>
                            </div>
                            <hr className="m-0" />

                            {logicaParametros()}

                            <div className="ps-3">
                                {isLogin ? <span className="text-danger">**Nota: las notas marcadas en color rojo, por defecto '0', corresponde a una nota que no está registrada en el sistema. </span> : <></>}
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>

            <ModalExcelAdmNotas
                show={modalExcel}
                onHide={() => setModalExcel(false)}
                ests={listaEstudiantes}
                asig={dataMateria?.asig}
                eval={dataMateria?.eval}
                onLoad={() => cargarDatos()}
            //nameFile={showModalFileName}
            //onChange={handleChangeDoc}
            />

            <ModalInfoOk
                show={modalInfo}
                onHide={() => setModalInfo(false)}
                titulo={"ATENCIÓN"}
                texto={isPonderacionEval ? "Habilitó el registro de notas sobre " + dataMateria?.eval?.ponderacion + " puntos" : "Habilitó el registro de notas sobre 100 puntos"}
            />

            <ModalCreateSolicitudParametros
                show={modalParametros}
                onHide={() => setModalParamtros(false)}
                asignatura={dataMateria?.asig}
                evaluacion={dataMateria?.eval}
                onSuccess={() => cargarTramites()}
            />

        </div>
    );



}


const ModalExcelAdmNotas = (props) => {

    const wrapperRef = useRef(null);

    const [file, setFile] = useState(null);
    const [datosValidos, setDatosValidos] = useState([]);
    const [hojas, setHojas] = useState([]);

    const [load, setLoad] = useState(false);

    const onDragEnter = () => wrapperRef.current.classList.add('dragover');
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
    const onDrop = () => wrapperRef.current.classList.remove('dragover');


    const onFileDrop = (e) => {
        const newFile = e.target.files[0];
        if (newFile) {

            const reader = new FileReader();

            reader.onload = event => {
                try {
                    const { result } = event.target;
                    const workbook = XLSX.read(result, { type: 'binary' });
                    //let data = [];
                    let dataHojas = []
                    for (const sheet in workbook.Sheets) {
                        if (workbook.Sheets.hasOwnProperty(sheet)) {
                            dataHojas.push({
                                nombre: sheet,
                                datos: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
                            });
                            //data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        }
                    }
                    console.log(dataHojas);
                    setHojas(dataHojas);
                    if (dataHojas.length > 0) {
                        validar(dataHojas[0].datos);
                    }
                    //validar(data);
                } catch (e) {
                    console.log('Error al Extraer información del Documento');
                    return;
                }
            };
            reader.readAsBinaryString(newFile);
            setFile(newFile);
        }
    }

    const fileRemove = () => {
        setFile(null);
        setDatosValidos([]);
    }

    const validar = (values) => {
        console.log(values.length);
        let vals = []
        for (var i = 0; i < values.length; i++) {
            var dt = values[i];
            if (dt.RU !== null && dt.RU !== undefined &&
                dt.NOMBRE !== null && dt.NOMBRE !== undefined &&
                dt.NOTA !== null && dt.NOTA !== undefined) {

                //ver si existe en la lista de programados oficial
                for (var j = 0; j < props.ests.length; j++) {
                    if (dt.RU === props.ests[j].ru) {
                        dt.UP = false;
                        vals.push(dt);
                        break;
                    }
                }
            }
        }
        setDatosValidos(vals);
    };

    function addNew() {
        upAll2();
    }

    function ClearAndClose() {
        setDatosValidos([]);
        setFile(null);
        props.onHide();
    }


    const upAll = async () => {
        setLoad(true);
        var cant = 0;

        for (var i = 0; i < datosValidos.length; i++) {
            let respuesta = await makeRequest(datosValidos[i].NOTA, datosValidos[i].RU);
            datosValidos[i].UP = respuesta;
            if (respuesta) {
                cant++;
            }
        }
        setLoad(false);
        mostrarAlertaSuccess(cant + " Registros exitosos, " + (datosValidos.length - cant) + " errores");
        props.onLoad();
        props.onHide();
        ClearAndClose();
    }



    async function makeRequest(n, ru) {

        let asignacion = props.asig.codigo_asig;
        let tipo_nota = props.eval.id_tipo_nota;
        let gestion = props.asig.gestion;
        let periodo = props.asig.periodo;
        let nota = n;
        let estudiante = ru;
        let data = await estudiantesAdminCalificacionUpdate(asignacion, tipo_nota, gestion, periodo, nota, estudiante);
        return data;
    }

    const upAll2 = async () => {
        setLoad(true);
        var cant = 0;
        var novalido = 0;
        var error = 0;
        var notas = "";
        for (var i = 0; i < datosValidos.length; i++) {
            notas += datosValidos[i].NOTA + "-" + datosValidos[i].RU;
            if (i < datosValidos.length - 1) {
                notas += "#";
            }
        }

        let respuesta = await makeRequest2(notas);
        setLoad(false);
        cant = respuesta.correctos ?? 0;
        console.log("respuesta.errorNota.length");
        console.log(respuesta.errorNota.length);
        novalido = respuesta.errorNota.length ?? 0;
        error = respuesta.error.length ?? 0;
        mostrarAlertaSuccess(cant + " Registros exitosos, " + novalido + " no validos y " + error + " errores");
        props.onLoad();
        props.onHide();
        ClearAndClose();
    }

    async function makeRequest2(n) {

        let asignacion = props.asig.codigo_asig;
        let tipo_nota = props.eval.id_tipo_nota;
        let gestion = props.asig.gestion;
        let periodo = props.asig.periodo;
        let data = await estudiantesAdminCalificacionAllUpdate(asignacion, tipo_nota, gestion, periodo, n);
        return data;
    }

    return (
        <div className="modal_upInfo">
            <Modal show={props.show} onHide={props.onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}>
                <Modal.Header>
                    <Modal.Title className="col-12">
                        <p className="text-center">Cargar notas de un documento Excel</p>
                        <p className="text-center font13 text-danger">* Las notas deben estar registradas sobre 100p.</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        load ? <div className="text-center">
                            <div className="spinner-border text-success" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div><br></br>
                            Cargando las notas...
                        </div>
                            :
                            <>
                                <>{
                                    file === null ? (
                                        <div
                                            ref={wrapperRef}
                                            className="drop-file-input"
                                            onDragEnter={onDragEnter}
                                            onDragLeave={onDragLeave}
                                            onDrop={onDrop}
                                        >
                                            <div className="drop-file-input__label">
                                                <img src={uploadImg} alt="" />
                                                <p>Arrastra y suelta tu archivo Excel aquí</p>
                                            </div>
                                            <input type="file" value="" onChange={onFileDrop} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                        </div>
                                    ) : null
                                }
                                </>

                                {
                                    file !== null ? (
                                        <div className="drop-file-preview">
                                            <p className="drop-file-preview__title m-0">
                                                Documento Cargado
                                            </p><div>
                                                <div className="drop-file-preview__item">
                                                    <img src={fileXlxs} alt="" className="img-fluid" />
                                                    <div className="drop-file-preview__item__info">
                                                        <p>{file.name}</p>
                                                        <p>{file.size}B</p>
                                                        <div className="mt-1">
                                                            <select className="form-select form-select-sm" aria-label=".form-select-sm example">
                                                                {hojas.map((d, i) => <option key={i} value={i} onClick={() => validar(d.datos)}>{d.nombre}</option>)}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <span className="drop-file-preview__item__del" onClick={() => fileRemove()}><MdOutlineDeleteForever className="h3 text-danger" /></span>
                                                </div>
                                                <div>
                                                    <span>{datosValidos.length} registros válidos encontrados.</span>
                                                    <button className="btn btn-ligth btn-sm text-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNotasExcel" aria-expanded="false">
                                                        ver datos
                                                    </button>
                                                </div>
                                            </div>
                                            <div style={{ maxHeight: '200px', overflow: 'auto' }}>
                                                <div className="collapse" id="collapseNotasExcel">
                                                    <div className="card card-body p-0">
                                                        <Table size="sm" className="font11">
                                                            <thead>
                                                                <tr>
                                                                    <th>N</th>
                                                                    <th className="text-center">RU</th>
                                                                    <th>NOMBRE</th>
                                                                    <th className="text-center">NOTA (100p.)</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {datosValidos.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item.N}</td>
                                                                        <td className="text-center">{item.RU ?? "( error )"}</td>
                                                                        <td>{item.NOMBRE ?? "( error )"}</td>
                                                                        <td className="text-center">{item.NOTA ?? "( error )"}</td>
                                                                        <td>{item.UP ? <>up</> : <></>}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null
                                }
                            </>

                    }

                </Modal.Body>

                <Modal.Footer className='p-0'>
                    <div className='row col-12 m-0'>
                        {
                            load ? <></> :
                                datosValidos.length > 0 ? (
                                    <>
                                        <span onClick={ClearAndClose} className='col-6 btn-ios rounded-bottom'>Cancelar</span>
                                        <span onClick={(e) => addNew()} className='col-6 border-start btn-ios rounded-bottom'>Cargar Notas</span>
                                    </>
                                ) : (
                                    <div onClick={ClearAndClose} className='col-12 btn-ios rounded-bottom'>Cancelar</div>
                                )
                        }
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}


const ModalCreateSolicitudParametros = (props) => {


    const { infoBasicData,setSpinnerLoad } = useUsuario();
    const [director, setDirector] = useState("...");

    useEffect(() => {
        const cargarDirector = () => {
            setSpinnerLoad(true);
            httpPost({
                codeAsig: props.asignatura.codigo_asig,
            }, "api_v1/general/miInmediatoSuperior").then((d) => {
                if (d) {
                    setDirector(d.nombre);
                }
                setSpinnerLoad(false);
            });
        }

        if (props.show) {
            cargarDirector();
        }
    }, [props.show]);

    const iniciarTramite = () => {
        setSpinnerLoad(true);
        httpPost({
            codeAsig: props.asignatura.codigo_asig,
            parcial: props.evaluacion.tipo_nota,
            telefono: infoBasicData?.celular
        }, "api_v1/tramite/iniciarParametroHabilitacion").then((d) => {
            console.log(d);
            if (d) {
                mostrarMensajeOK("Se registró su solicitud de habilitación de tramite");
                props.onSuccess();
                props.onHide();
            }
            setSpinnerLoad(false);
        });
    }


    return (
        <Modal show={props.show} onHide={props.onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            keyboard={false}>
            <Modal.Header>
                <Modal.Title className="col-12">
                    <p className="text-center">Solicitud de parámetros</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table className="table-borderless">
                    <tbody>
                        <tr>
                            <td className="fw-bold">Carrera :</td>
                            <td>
                                <Form.Control className="text-center" value={props.asignatura.carrera} type="text" disabled={true} />
                            </td>
                            <td className="fw-bold">Teléfono :</td>
                            <td>
                                <Form.Control className="text-center" value={infoBasicData?.celular} type="text" disabled={true} />
                            </td>
                        </tr>
                        <tr >
                            <td className="fw-bold">RD :</td>
                            <td colSpan={3}>
                                <Form.Control className="text-center" value={infoBasicData?.codigo} type="text" disabled={true} />
                            </td>
                        </tr>
                        <tr>
                            <td className="fw-bold">Periodo :</td>
                            <td>
                                <Form.Control className="text-center" value={props.asignatura.periodo} type="text" disabled={true} />
                            </td>
                            <td className="fw-bold">Gestión :</td>
                            <td>
                                <Form.Control className="text-center" value={props.asignatura.gestion} type="text" disabled={true} />
                            </td>
                        </tr>
                        <tr>
                            <td className="fw-bold">Evaluación a Habilitar :</td>
                            <td>
                                <Form.Control className="text-center" value={props.evaluacion.tipo_nota} type="text" disabled={true} />
                            </td>
                            <td className="fw-bold">Plan :</td>
                            <td>
                                <Form.Control className="text-center" value={props.asignatura.plan_materia} type="text" disabled={true} />
                            </td>
                        </tr>
                        <tr >
                            <td className="fw-bold">Asignatura :</td>
                            <td colSpan={3}>
                                <Form.Control className="text-center" value={props.asignatura.materia} type="text" disabled={true} />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr >
                            <td className="fw-bold">Esta solicitud será<br /> enviada inicialmente a :</td>
                            <td colSpan={3}>
                                <p className="text-center">{director}</p>
                            </td>
                        </tr>
                    </tfoot>
                </Table>

            </Modal.Body>

            <Modal.Footer className='p-0'>
                <div className='row col-12 m-0'>
                    <button onClick={() => props.onHide()} className='col-6 btn-ios rounded-bottom'>Cancelar</button>
                    <button onClick={() => iniciarTramite()} className='col-6 border-start btn-ios rounded-bottom'>Generar Solicitud</button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}


export default AdministrarCalificaciones;