import { URL } from "./info_app";

export const conexion_url=URL+'api_v1/login/conexion';
export const login_url=URL+'api_v1/login/log';
export const infoBasic_url=URL+'api_v1/docente/infobasica';
export const infoGeneral_url=URL+'api_v1/docente/infogeneral';
export const infoPersonal_url=URL+'api_v1/docente/info_personal';
export const infoDocente_url=URL+'api_v1/docente/info_docente';
export const infoTitulos_url=URL+'api_v1/docente/info_titulos';
export const infoDoc_url=URL+'api_v1/docente/documentos';
export const historial_url=URL+'api_v1/docente/historial';
export const estudiantes_materia_url=URL+'api_v1/materia/estudiantes_materia_asignacion';
export const materia_asignatura_url=URL+'api_v1/materia/materia';
export const docente_materias_url=URL+'api_v1/docente/docente_materias';
export const docente_materiasPG_url=URL+'api_v1/docente/docente_materias_periodo_gestion';
export const docente_up_data_url=URL+'api_v1/docente/up_data';
export const docente_up_pass_url=URL+'api_v1/login/up_pass';

export const horario_materias_aula_url=URL+'api_v1/horarios/porAsignadosActual';

export const biblioteca_busqueda_libro_url=URL+'api_v1/docente/busqueda_libro';
export const biblioteca_elibro_access_url=URL+'api_v1/biblioteca/getCredencialElibro';

export const usuario_verificar_recovery_url=URL+'api_v1/login/recover_pass';
export const usuario_confirmar_recovery_url=URL+'api_v1/login/recover_confirm';
export const usuario_verific_token_recover_url=URL+'api_v1/login/verific_token_recover';

export const download_registro_docente_url=URL+'documents/pdfRegistDocente/';
export const download_registro_docente_emit_url=URL+'documents/pdfRegistDocenteEmit/';
export const download_registro_docente_emit_verific_url=URL+'documents/pdfRegistDocenteEmit_verific/';
export const download_cedula_docente_url=URL+'documents/pdfCedulaDocente/';
export const download_cedula_docente_verific_url=URL+'documents/pdfCedulaDocente_verific/';
export const download_pre_registro_url=URL+'documents/pdfPreRegistroDocente/';
export const download_estudiantes_en_materia_xlsx_url=URL+'documents/entudiantes_materiaXLSX/';

export const general_sexos_url=URL+'api_v1/general/sexos';
export const general_estado_civil_url=URL+'api_v1/general/estado_civil';
export const general_expedidos_url=URL+'api_v1/general/expedidos';
export const general_paises_url=URL+'api_v1/general/paises';
export const general_departamentos_url=URL+'api_v1/general/departamentos';
export const general_provincias_url=URL+'api_v1/general/provincias';
export const general_localidades_url=URL+'api_v1/general/localidades';
export const general_tipos_titulos_url=URL+'api_v1/general/tipos_titulos';
export const general_intituciones_url=URL+'api_v1/general/instituciones';
export const general_grados_academicos_url=URL+'api_v1/general/grados_academicos';
export const general_empresas_telefonicas_url=URL+'api_v1/general/empresas_telefonicas';
export const imagen_login_url=URL+'img_data/img_login';
export const mensaje_notificacion_url=URL+'api_v1/general/notificacionApp';


export const upload_informacion_personal_url=URL+'api_v1/docente/upload_personal';
export const upload_titulos_academicos_url=URL+'api_v1/docente/upload_titulos';
export const upload_documentos_url=URL+'api_v1/docente/upload_documentos';

export const infoPrePersonal_url=URL+'api_v1/docente/info_pre_personal';
export const infoPreTitulos_url=URL+'api_v1/docente/info_pre_titulos';
export const infoPreDocumentos_url=URL+'api_v1/docente/info_pre_documentos';



export const evaluaciones_definidas_url=URL+'api_v1/materia/evaluaciones_definidas';