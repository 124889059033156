import './alertToast.css';
import { IoIosAlert } from "react-icons/io";
import { useState } from 'react';

function AlertToast() {

    const [visible,setVisible]=useState(true);

    return (
        <div className='aT'>
        <div className={visible?"alertToast bg-warning text-white":"alertToast bg-warning text-white hide"}>
            <div className="d-flex">
                <div className='ms-5 mt-auto mb-auto'><IoIosAlert/></div>
                <div className="toast-body">
                    Se ha verificado que usted no tiene actualizado documentación de requisitos docente<br/> <strong>Es necesario que proceda a regularizar su documentación</strong>
                </div>
                <button type="button" onClick={()=>setVisible(false)} className="btn-close mt-auto mb-auto ms-auto me-3" aria-label="Close"></button>
            </div>
        </div>
        </div>
    );
}

export default AlertToast;