import { useNavigate } from "react-router-dom";
import "./sidebar.css";
import { TiArrowSortedDown } from "react-icons/ti";
import { AiFillDatabase } from "react-icons/ai";
import { BiLogOutCircle } from "react-icons/bi";
import { SiMicrosoftacademic } from "react-icons/si";
import {
  IoLibrary,
  IoArrowBackCircle,
  IoDocumentsSharp,
} from "react-icons/io5";
import { FaUserLock, FaBars, FaFileDownload } from "react-icons/fa";
import { useShowSide } from "../../context/showSideContext";
import { useUsuario } from "../../context/UsuarioContext";
import {
  downloadCedulaDocente,
  downloadRegistroDocente,
  downloadRegistroDocenteEmit,
} from "../../http/log";
import {
  Form006DOC,
  FormIncompativilidadHttpPdf,
  ManualDeUsuario,
  NotasConvetidas,
} from "../../http/log_download";
import { version_app } from "../../config/info_app";

function Sidebar() {
  const { showSide, setShowSide } = useShowSide();
  const { setToken, setSpinnerLoad, infoBasicData } = useUsuario();

  const navigate = useNavigate();

  function handleClick(e) {
    e.preventDefault();
    setShowSide(!showSide);
  }
  function go(a) {
    setShowSide(false);
    navigate(a);
  }

  function cl(e) {
    e.preventDefault();
    setToken("");
    //window.location.reload(true);
  }

  function downloadRegistroEmit() {
    setSpinnerLoad(true);
    downloadRegistroDocenteEmit().then((data) => {
      setSpinnerLoad(false);
    });
  }

  function downloadRegistro() {
    setSpinnerLoad(true);
    downloadRegistroDocente().then((data) => {
      setSpinnerLoad(false);
    });
  }

  function downloadCedula() {
    setSpinnerLoad(true);
    downloadCedulaDocente().then((data) => {
      setSpinnerLoad(false);
    });
  }

  function downloadFormIncompatibilidad() {
    setSpinnerLoad(true);
    FormIncompativilidadHttpPdf().then((data) => {
      setSpinnerLoad(false);
    });
  }

  function downloadForm006() {
    setSpinnerLoad(true);
    Form006DOC().then((data) => {
      setSpinnerLoad(false);
    });
  }

  function downloadNotasConvertidas() {
    setSpinnerLoad(true);
    NotasConvetidas().then((data) => {
      setSpinnerLoad(false);
    });
  }

  function downloadManual() {
    setSpinnerLoad(true);
    ManualDeUsuario().then((data) => {
      setSpinnerLoad(false);
    });
  }
  return (
    <div>
      <div className={showSide ? "sidebar active shadow" : "sidebar shadow"}>
        <div
          className="titulo-sidebar text-center roboto-medium"
          onClick={handleClick}
        >
          <span className="bot-sidebar">
            <FaBars onClick={handleClick} />
          </span>
          Sistema Perla NET
        </div>
        <hr className="hr-sidebar" />
        <div className="sidebar-content">
          <ul className="sidebarMenu fw-bold">
            <li>
              <p onClick={() => go("/")} className="btn-side">
                Inicio
              </p>
            </li>

            <li>
              <p
                className="btn-side d-flex"
                href="/#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseInfo"
                aria-expanded="false"
                aria-controls="collapseInfo"
              >
                <AiFillDatabase className="my-auto" /> <span>Ver Datos</span>{" "}
                <TiArrowSortedDown className="ms-auto my-auto" />
              </p>
              <ul className="submenu collapse ms-3" id="collapseInfo">
                <li className="">
                  <p onClick={() => go("/informacion")} className="btn-side">
                    {" "}
                    Datos Personales{" "}
                  </p>
                  <p onClick={() => go("/historial")} className="btn-side">
                    {" "}
                    Historial de Docencia{" "}
                  </p>
                </li>
                {/*<li className='ms-3'>
                                    <span onClick={() => go("/escalafon")} className='btn-side'> Escalafón Docente </span>
    </li>*/}
              </ul>
            </li>

            <li>
              <p
                className="btn-side d-flex"
                href="/#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseAcademic"
                aria-expanded="false"
                aria-controls="collapseAcademic"
              >
                <SiMicrosoftacademic className="my-auto" />{" "}
                <span>Académica</span>{" "}
                <TiArrowSortedDown className="ms-auto my-auto" />
              </p>
              <ul className="submenu collapse" id="collapseAcademic">
                <li className="ms-3">
                  <p onClick={() => go("/asignaturas")} className="btn-side">
                    {" "}
                    Asignaturas{" "}
                  </p>
                </li>
                <li className="ms-3">
                  <p onClick={() => go("/horario")} className="btn-side">
                    {" "}
                    Horarios y Aulas{" "}
                  </p>
                </li>
                {/*<li className='ms-3'>
                                    <span onClick={() => go("/calificacion")} className='btn-side'> Registro de Calificaciones </span>
</li>*/}
              </ul>
            </li>

            <li>
              <p
                className="btn-side d-flex"
                href="/#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseDescargas"
                aria-expanded="false"
                aria-controls="collapseAcademic"
              >
                <FaFileDownload className="my-auto" /> <span>Descargas</span>{" "}
                <TiArrowSortedDown className="ms-auto my-auto" />
              </p>
              <ul className="submenu collapse" id="collapseDescargas">
                <li className="ms-3">
                  <p
                    onClick={() => downloadRegistroEmit()}
                    className="btn-side"
                  >
                    {" "}
                    Registro Docente{" "}
                  </p>
                  {/*<p onClick={() => downloadRegistro()} className='btn-side'> Registro Docente Declarado </p>*/}
                  <p onClick={() => downloadCedula()} className="btn-side">
                    {" "}
                    Cedula Docente{" "}
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p className="btn-side" onClick={() => go("/biblioteca")}>
                <IoLibrary className="" /> <span>Biblioteca Virtual</span>
              </p>
            </li>

            <li>
              <p className="btn-side" onClick={() => go("/acceso")}>
                <FaUserLock className="" /> <span>Acceso</span>
              </p>
            </li>

            <li>
              <p
                className="btn-side d-flex"
                href="/#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseAdicional"
                aria-expanded="false"
                aria-controls="collapseAdicional"
              >
                <IoDocumentsSharp className="my-auto" />{" "}
                <span>Doc. Adicionales</span>{" "}
                <TiArrowSortedDown className="ms-auto my-auto" />
              </p>
              <ul className="submenu collapse" id="collapseAdicional">
                <li className="ms-3">
                  <p className="btn-side">
                    <a
                      href="https://drive.google.com/file/d/1PuBVCC-oranmV_1kPdlSn7aoKAJpcen8/view?usp=sharing"
                      target="blank"
                      className="text-white"
                    >
                      {" "}
                      MODELO ACADÉMICO UAP
                    </a>
                  </p>

                  <p
                    onClick={() => downloadFormIncompatibilidad()}
                    className="btn-side"
                  >
                    {" "}
                    FORM incompatibilidad en el horario de Docencia
                  </p>
                  <p onClick={() => downloadForm006()} className="btn-side">
                    {" "}
                    Retroceso de Fase FORM-006
                  </p>
                  <p
                    onClick={() => downloadNotasConvertidas()}
                    className="btn-side"
                  >
                    {" "}
                    Convertir Notas{" "}
                  </p>
                  <p onClick={() => downloadManual()} className="btn-side">
                    {" "}
                    Manual de Usuario{" "}
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p className="btn-side" onClick={cl}>
                <BiLogOutCircle className="" /> <span> Cerrar Sesión </span>
              </p>
            </li>
          </ul>

          <hr className="hr-sidebar mt-5" />
          <li className="mt-4 mb-2">
            <p className="text-center text-white">
              {" "}
              - {infoBasicData?.nombres} {infoBasicData?.paterno}{" "}
              {infoBasicData?.materno} -{" "}
            </p>
          </li>

          <p className="text-center pb-3 text-secondary">{version_app}</p>
        </div>
      </div>

      <div
        className={showSide ? "sidebar-off active" : "sidebar-off"}
        onClick={handleClick}
      >
        &nbsp;
      </div>
    </div>
  );
}
export default Sidebar;
