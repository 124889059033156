import axios from "axios";
import { mostrarAlerta, mostrarAlertaDanger} from "../alerts/Alerts";
import verificarCode from "./VerificCodeResponse";
import { mensaje_notificacion_url } from "../config/urls";
import { infoCod } from "../functions/info";

export async function notificacionHttp() {

    return axios.get(mensaje_notificacion_url,
      {
        headers: {
          'Authorization': `Bearer ${infoCod()}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
        }
      }).then(data => {
        //console.log(data.data);
        if (data.data.ok && data.status === 200) {
          return data.data.data;
        } else {
          verificarCode(data.data);
          console.log("Conexion fallida");
          return null;
        }
      })
      .catch(err => {
        console.log(err);
        mostrarAlerta("Ocurrió un error al conectar con el servidor");
      });
  
  }
  
  export async function peticionGetAbiertaAuthHttp(url) {

    return axios.get(url,
      {
        headers: {
          'Authorization': `Bearer ${infoCod()}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
        }
      }).then(data => {
        //console.log(data.data);
        if (data.data.ok && data.status === 200) {
          return data.data.data;
        } else {
          verificarCode(data.data);
          console.log("Conexion fallida");
          return null;
        }
      })
      .catch(err => {
        console.log(err);
        mostrarAlerta("Ocurrió un error al conectar con el servidor");
      });
  
  }
  

