import { memo, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import Documentos from '../../sections/documentos/Documentos';
import InfoBasico from '../../sections/info_basico/InfoBasico';
import { downloadCedulaDocente, downloadRegistroDocente, downloadRegistroDocenteEmit, infoUser } from '../../http/log';
import user_img from '../../assets/img/user_default.png'
import { FaFilePdf, FaBookmark } from "react-icons/fa";
import ModalImage from '../../components/modals/ModalImage';
import { useUsuario } from '../../context/UsuarioContext';
import DatosAcademicos from '../../sections/datos_academicos/DatosAcademicos';
import { Link } from 'react-router-dom';
import ModalUpInfo from '../../sections/modal_upInfo/Modal_upInfo';
import AlertToast from '../../components/alertToast/AlertToast';
import './informacion.css'

function Informacion() {

    const [showModal, setShowModal] = useState(false);
    const [notificacionToast, setNotificacionToast] = useState(false);
    const { infoBasicData, setSpinnerLoad, loadData } = useUsuario();
    const [showModalImage, setShowModalImage] = useState(false);
    const [info, setInfo] = useState();
    const [titulos, setTitulos] = useState([]);
    const [materias, setMaterias] = useState([]);


    const Mdocumentos= useMemo(()=><Documentos onView={() => setNotificacionToast(true)} />,[]);
    //inicar haciendo una peticion http para obtener datos
    useEffect(() => {
        upData();
    }, []);

    function upData() {
        infoUser().then(
            data => {
                if (data != null) {
                    if (data.status === 200 && data.ok) {
                        //console.log(data);
                        setInfo(data.data.informacion);
                        setTitulos(data.data.titulos ?? []);
                        setMaterias(data.data.materiasAsignadas ?? []);
                    } else {

                    }
                }
            }
        )
    }
    //**************** */

    function downloadRegistroEmit() {
        setSpinnerLoad(true);
        downloadRegistroDocenteEmit().then(
            data => {
                setSpinnerLoad(false);
            }
        );
    }

    function downloadRegistro() {
        setSpinnerLoad(true);
        downloadRegistroDocente().then(
            data => {
                setSpinnerLoad(false);
            }
        );
    }

    function downloadCedula() {
        setSpinnerLoad(true);
        downloadCedulaDocente().then(
            data => {
                setSpinnerLoad(false);
            }
        );
    }


    return (
        <div className="infoPage pb-5">
            {notificacionToast ? <AlertToast></AlertToast> : <></>}
            <div className='container'>
                <div className='row p-2'>
                    <div className='col-12 mt-4 mb-2 text-start'>
                        <h3>Perfil de Usuario</h3>
                    </div>


                    <div className='col-12 col-lg-4'>
                        <div className='bg-white shadow-lg mb-5 p-3 bg-body rounded '>
                            <div className="card-header text-center bg-white  p-0 pb-3">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-6 col-md-7 col-lg-8 align-self-center text-center ">
                                            <div className="col-12 mb-1">
                                                <div className="col-12 mb-1 divImage bg-secondary">
                                                    <div className="ratio ratio-1x1 rounded-circle overflow-hidden border-1">
                                                        <div onClick={() => setShowModalImage(true)} style={{ backgroundImage: "url(" + (infoBasicData?.url_foto !== '' ? infoBasicData?.url_foto : user_img) + ")", backgroundPosition: "center", backgroundRepeat: "no-repead", backgroundSize: "cover" }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className='mt-2'><strong>{info?.nombres} {info?.paterno} {info?.materno}</strong></h4>
                                <p className='mt-1 text-success'><strong>R.D. : {infoBasicData?.codigo}</strong></p>
                                <p className='mt-1 text-success'>Docente Universitario</p>
                            </div>
                            <div className=" text-start">

                                <hr />
                                <div className='dataInfo'>
                                    <strong>Asignaturas Asignadas</strong>
                                    <div className='ms-3 text-secondary'>
                                        {materias.length !== 0 ?
                                            <>
                                                {materias.map((data, index) => <div key={index} className="mb-2">
                                                    <p><FaBookmark /> {data?.materia ?? "-"}</p>
                                                    <p className='ps-4 font12'>{data?.programa ?? "-"}</p>
                                                    </div>)}
                                            </>
                                            :
                                            <div className='text-center'>
                                                <p className=" mt-2 mb-2">Sin materias Asignadas</p>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <hr />
                                <hr />
                                <hr />

                                <div className='dataInfo'>
                                    <strong>Dirección de Domicilio</strong>
                                    <div className='ms-3 text-secondary'>
                                        <p>{info?.direccion ?? "-"}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className='dataInfo'>
                                    <strong>Correos Electrónicos Personal</strong>
                                    <div className='ms-3 text-secondary'>
                                        <p>{info?.correo ?? "-"}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className='dataInfo'>
                                    <strong>Celular</strong>
                                    <div className='ms-3 text-secondary'>
                                        <p>{info?.celular ?? "-"}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className='mb-3 mt-3 center text-end'>
                                    <button type="button" className="btn btn-link" onClick={() => setShowModal(true)}>Modificar informacion básica</button>
                                    <ModalUpInfo show={showModal} onHide={() => setShowModal(false)} onUp={() => { upData(); loadData() }} dir={info?.direccion} email={info?.correo} cel={info?.celular} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=' col-12 col-lg-8'>

                        <div className='bg-white shadow-lg mb-5 bg-body rounded '>
                            <div className='pb-3'>
                                <Tabs
                                    defaultActiveKey="dg"
                                    transition={true}
                                    id="noanim-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="dg" title="Datos Generales">
                                        <InfoBasico data={info} />
                                    </Tab>
                                    <Tab eventKey="da" title="Datos Académicos">
                                        <DatosAcademicos data={titulos} />
                                    </Tab>
                                    <Tab eventKey="profile" title="Requisitos Docente">
                                        {Mdocumentos}
                                    </Tab>
                                </Tabs>
                            </div>
                            <hr />
                            <div className='p-3 text-end'>
                                {/*<Link to="actualizar/" className="btn btn-outline-secondary m-1">Actualizar Datos</Link>*/}
                                <Button onClick={e => downloadRegistroEmit()} className="m-1" variant="outline-success"><FaFilePdf /> Registro Docente</Button>
                                {/*<Button onClick={e => downloadRegistro()} className="m-1" variant="outline-success"><FaFilePdf /> Registro Docente Declarado</Button>*/}
                                <Button onClick={e => downloadCedula()} className="m-1" variant="outline-success"><FaFilePdf /> Cedula Docente</Button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalImage show={showModalImage} onHide={() => setShowModalImage(false)} src={infoBasicData?.url_foto ?? user_img} />
        </div>
    );
}

export default Informacion;