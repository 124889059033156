import axios from "axios";
import { mostrarAlerta, mostrarAlertaDanger} from "../alerts/Alerts";
import verificarCode from "./VerificCodeResponse";
import { infoCod } from "../functions/info";
import { confirmar_pago_certificado_url, download_certificado_original_PDF_url, lista_certificados_pago_solicitados_url, lista_certificados_pago_url } from "../config/url_certificados_pago";
import { conexion } from "./log";
import download from "downloadjs";

export async function listaCertificadosPagoHttp() {

    return axios.get(lista_certificados_pago_url,
      {
        headers: {
          'Authorization': `Bearer ${infoCod()}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
        }
      }).then(data => {
        //console.log(data.data);
        if (data.data.ok && data.status === 200) {
          return data.data.data;
        } else {
          

verificarCode(data.data);
          console.log("Conexion fallida");
          return null;
        }
      })
      .catch(err => {
        console.log(err);
        mostrarAlerta("Ocurrió un error al conectar con el servidor");
      });
  
  }
  
export async function confirmarPagoCertificadosHttp(codeCertificado,institucion,correo) {

    return axios.post(confirmar_pago_certificado_url,
      {
        codeCertificado,
        institucion,
        correo
      },
      {
        headers: {
          'Authorization': `Bearer ${infoCod()}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
        }
      }).then(data => {
        //console.log(data.data);
        if (data.data.ok && data.status === 200) {
          return data.data.data;
        } else {
          verificarCode(data.data);
          console.log("Conexion fallida");
          return null;
        }
      })
      .catch(err => {
        console.log(err);
        mostrarAlerta("Ocurrió un error al conectar con el servidor");
      });
  
  }
  
export async function listaCertificadosPagoSolicitadosHttp() {

  return axios.get(lista_certificados_pago_solicitados_url,
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {
        verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
    });

}


export async function downloadCertificadoPagoCodigoOriginalHttpPdf(codeCert,nomnbreCert) {

  await conexion();
  return await axios({
    url: download_certificado_original_PDF_url,
    data:{codeCert},
    method: "POST",
    responseType: "blob",
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
    }
  }).then(response => {
    verificarCode(response.data);
    if (response.status === 200) {
      const content = response.headers['content-type'];
      download(response.data, nomnbreCert+".pdf", content);
      return true;
    }
    return false;
  })
    .catch(error => {
      console.log(error);
      return false;
    });
}
