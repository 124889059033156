import { URL } from "./info_app";


export const estudiante_asignacion_nota_url=URL+'api_v1/materia/estudiantes_asignacion_nota';
export const estudiante_nota_update_url=URL+'api_v1/materia/estudiantes_nota_up';
export const estudiante_notas_all_update_url=URL+'api_v1/materia/estudiantes_all_notas_up';
export const avanzar_fase_url=URL+'api_v1/materia/avanzarFase';
export const tipos_evaluaciones_url=URL+'api_v1/materia/tiposNotas';
export const definir_evaluacion_url=URL+'api_v1/materia/definirEvaluacion';
export const definir_evaluacion_all_url=URL+'api_v1/materia/definirEvaluacionAll';


export const pdf_estudiantes_materia_url=URL+'documents/entudiantes_materia';