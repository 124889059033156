import { FormControl, Spinner } from "react-bootstrap";
import './inputNota.css';
import { useEffect, useRef, useState } from "react";
import { mostrarAlertaInfo } from "../../alerts/Alerts";
import { MdCheckCircle, MdError, MdSave } from "react-icons/md";
import { estudiantesAdminCalificacionUpdate } from "../../http/log_materia";

const InputNota3 = (props) => {

    let timeout = useRef(null);
    var notaE100 = useRef(props.nota);

    const [notaInput, setNotaInput] = useState(props.nota);
    const [notaAnt, setNotaAnt] = useState(props.nota);

    const [estado, setEstado] = useState("");

    useEffect(() => {
        if (props.nota === 0 && props.est.id_nota === 0) {
            setNotaInput("-");
        }
    }, [props.nota]);

    useEffect(() => {
        if ((props.nota === 0 && props.est.id_nota === 0 && notaE100.current === 0)) {

        } else {
            if ((notaInput || notaInput === 0) && !isNaN(notaInput)) {
                if (props.isPonderacion) {
                    //setNotaInput(decimalEntero((notaE100 / 100 * props.eval.ponderacion).toFixed(1)));
                    setNotaInput(notaPonderacionO100(notaE100.current));
                } else {
                    setNotaInput(notaE100.current);
                }
            }
        }
        //setNotaInput(props.isPonderacion? (notaE100/100*props.eval.ponderacion).toFixed(2):notaE100);

    }, [props.isPonderacion]);
    //*********FUNCIONES********
    const notaAl100 = (n) => {
        if (props.isPonderacion) {
            return parseFloat((n * 100 / props.eval.ponderacion).toFixed(2));
        } else {
            return n;
        }
    }

    const notaPonderacionO100 = (n) => {
        if (props.isPonderacion) {
            return decimalEntero((n / 100 * props.eval.ponderacion).toFixed(1))
        } else {
            return n;
        }
    }

    //******/
    const change = (n) => {
        console.log("s");
        console.log(n);
        if ((!props.isPonderacion && parseFloat(n) > props.asig.maxima_nota) || (props.isPonderacion && parseFloat(n) > props.eval.ponderacion)) {
            mostrarAlertaInfo("La nota maxima permitida es " + (props.isPonderacion ? props.eval.ponderacion : props.asig.maxima_nota));
            clearTimeout(timeout.current);
            setEstado("error");
            setNotaInput(n);
            notaE100.current = notaAl100(n);
            return 0;
        }

        if (n.length > 5) {
            return 0;
        }

        setNotaInput(n);
        if (n) {

            n = parseFloat(n);
            notaE100.current = notaAl100(n);
            setEstado("");
            timeNota(notaE100.current, notaAnt);
        } else {
            setEstado("error");
            clearTimeout(timeout.current)
        }
    }

    const timeNota = (n, na) => {
        console.log(n);
        console.log(na);
        if (timeout.current != null) {
            clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
            if (n !== na || n === 0 || n === "0") {
                console.log("cargamos");
                console.log(n);
                upNota(n);
            } else {
                console.log("Notas iguales");
            }
            /*if(n===na || n!==0 || n!=="0"){
                console.log("Notas iguales");
            }else{
                console.log("cargamos");
                console.log(n);
                upNota(n);
            }*/

            clearTimeout(timeout.current)
        }, 2000);
    }


    const upNota = (n) => {
        console.log("----");
        console.log(notaAnt);
        console.log(notaE100.current);
        console.log("----");
        if(!n && n!==0){
            setEstado("error");
            return 0;
        }
        setEstado("cargando")
        makeRequest(n).then(r => {
            if (r) {
                setNotaInput(notaPonderacionO100(n));
                //setNotaInput(props.isPonderacion ? decimalEntero((notaE100 / 100 * props.eval.ponderacion).toFixed(1)) : notaE100);
                setNotaAnt(n);
                setEstado("ok")
                //setUp(false);
            } else {
                setEstado("error")
            }
        });
    };


    async function makeRequest(n) {

        let asignacion = props.asig.codigo_asig;
        let tipo_nota = props.eval.id_tipo_nota;
        let gestion = props.asig.gestion;
        let periodo = props.asig.periodo;
        let nota = n;
        let estudiante = props.est.ru;
        let data = await estudiantesAdminCalificacionUpdate(asignacion, tipo_nota, gestion, periodo, nota, estudiante);
        /*
                console.log("-------------");
                console.log(props.asig.codigo_asig);
                console.log(props.eval.id_tipo_nota);
                console.log(props.asig.periodo);
                console.log(props.asig.gestion);
                console.log(n);
                console.log(props.est.ru);
                console.log("Actualizando");*/
        return data;
    }






    const mensaje = (std) => {
        switch (std) {
            /*case 'editando':
                return(<Spinner animation="grow" variant="info" size="sm" />);*/
            case 'cargando':
                return (<Spinner animation="border" size="sm" variant="success" />);
            case 'error':
                return (<MdError className='text-danger' style={{ fontSize: "17px" }} />);
            case 'ok':
                return (<MdCheckCircle className='text-success' style={{ fontSize: "17px" }} />);
            /*default:
                return(<span className='m-2'></span>);*/
        }
    };

    const guardarBTN = (std) => {
        if (std !== 'cargando' && std !== 'ok' && std !== 'ok' && (notaAnt !== notaE100.current) && notaE100.current !== "" && notaInput) {
            return (<MdSave onClick={() => upNota(notaE100.current)} className='text-success pointer' style={{ fontSize: "17px" }} />);
        }
    };

    return (<div className='div-nota'>
        <FormControl
            className={props.est?.id_nota === 0 ? "input-nota text-danger" : "input-nota"}
            type="text"
            defaultValue={notaInput}
            value={notaInput}
            placeholder={notaPonderacionO100(notaAnt)}
            onChange={(e) => change(e.target.value)}
        //onKeyDown={(e) => registrarNota(e.target.value, notaInput)}
        />
        {guardarBTN(estado)}
        {mensaje(estado)}
    </div>);
}


const decimalEntero = (n) => {
    if ((n % 1) !== 0) {
        return n;
    } else {
        return parseInt(n, 10);
    }
}

export default InputNota3;