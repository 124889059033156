import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./pages/login/login";
import Historial from "./pages/historial/Historial";
import Asignaturas from "./pages/asignaturas/Asignaturas";
import Biblioteca from "./pages/biblioteca/Biblioteca";
import Acceso from "./pages/acceso/Acceso";
import HojaImpresión from "./components/hoja-impresion/HojaImpresion";
import MateriaEstudiante from "./pages/materiaEstudiantes/MateriaEstudiantes";
import PageNotFound from "./pages/notFound/NotFound";
import ModalLoad from "./components/modals/ModalLoad";
import { useUsuario, UsuarioProvider } from "./context/UsuarioContext";
import { SideProvider, useShowSide } from "./context/showSideContext";
import RecoverPassword from "./pages/login/RecoverPassword";
import RecoverPasswordConfirm from "./pages/login/RecoverPasswordConfirm";
import Html from "./pages/prueba/Html";
import Informacion from "./pages/informacion/Informacion";
import ActualizarInformacion from "./pages/actualizarInformacion/ActualizarInformacion";
import DocumentValidate from "./pages/documentValidate/DocumentValidate";
import ModalLogin from "./components/modals/modalLogin/ModalLogin";
import AdministrarCalificaciones from "./pages/administrarCalificaciones/AdministrarCalificaciones";
import { Politicas } from "./pages/politicas/Politicas";
import { Toaster } from "react-hot-toast";
import ComprarCertificado from "./pages/comprarCetificado/ComprarCertificado";
import ModalNotification from "./components/modals/ModalNotification";
import { useEffect, useState } from "react";
import { notificacionHttp } from "./http/log_general";
import ListaComprasCertificados from "./pages/comprarCetificado/ListaComprasCertificados";
import Topbar2 from "./components/topbar/Topbar2";
import FueraDeLinea from "./pages/fueraDeLinea/FueraDeLinea";
import HorariosPage from "./pages/horarios/HorariosPage";
//import "./assets/style/shards-dashboards.1.1.0.min.css";

export default () => <UsuarioProvider>
  <SideProvider>
    <App></App>
  </SideProvider>
</UsuarioProvider>

function App() {

  const { token } = useUsuario();
  const { showSide, setShowSide } = useShowSide();
  const [isOnline,setIsOnLine]=useState(navigator.onLine);

  useEffect(()=>{
    const handleOnline=()=>{
      setIsOnLine(true);
    };

    const handleOffline=()=>{
      setIsOnLine(false)
    };

    window.addEventListener('online',handleOnline);
    window.addEventListener('offline',handleOffline);

    return ()=>{
      window.addEventListener('online',handleOnline);
      window.addEventListener('offline',handleOffline);
    };

  },[]);

  if(!isOnline){
    return(
      <FueraDeLinea/>
    );
  }

  if (!token) {

    return (
      <div>
        <ModalLoad />
        <div><Toaster /></div>
        <Routes basename={'/perlanet'}>
          <Route exact path="/*" element={<Login />} />
          <Route path="/forgot-password" element={<RecoverPassword />} />
          <Route path="/validate/reset/:token" element={<RecoverPasswordConfirm />} />
          <Route path="/validate/documento/:token" element={<DocumentValidate />} />
          <Route path="/privacidad" element={<Politicas />} />
        </Routes>
      </div>
    )

  }

  return (
    <div className="App">
      <Sidebar />
      <div style={{'height':'100vh','display':'flex','flexDirection':'column'}}>
        {/*<Topbar onSide={() => setShowSide(!showSide)} />*/}
        <Topbar2 onSide={() => setShowSide(!showSide)} />
        <div className="pages pb-5">
          <Routes>
            <Route component={PageNotFound} />
            <Route exact path="/*" element={<Informacion />} />
            <Route path="/inicio" element={<Informacion />} />
            <Route path="/informacion" element={<Informacion />} />
            <Route path="/informacion/actualizar" element={<ActualizarInformacion />} />
            <Route path="/historial" element={<Historial />} />
            <Route path="/horario" element={<HorariosPage />} />
            {/* 
                <Route path="/escalafon" element={<Escalafon />}/>
                */}
            <Route path="/asignaturas" element={<Asignaturas />} />
            <Route path="/asignaturas/detalle" element={<MateriaEstudiante />} />
            <Route path="/asignaturas/administrar_calificaciones" element={<AdministrarCalificaciones />} />
            {/* 
                <Route path="/calificacion" element={<Calificacion />}/>
                <Route path="/notas" element={<Notas />}/>
                */}
            <Route path="/biblioteca" element={<Biblioteca />} />
            <Route path="/acceso" element={<Acceso />} />
            <Route path="/hoja" element={<HojaImpresión />} />
            <Route path="/validate/documento/:token" element={<DocumentValidate />} />

            {<Route path="/buyCertificado" element={<ComprarCertificado />} />}
            {<Route path="/listBuyCertificados" element={<ListaComprasCertificados />} />}
          </Routes>
        </div>
      </div>
      <ModalLoad />

      <ModalNotification />

      <div><Toaster /></div>

    </div>
  );
}
