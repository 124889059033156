import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { estudiantesMateria, infoDocente, materiaAsignatura } from "../../http/log";
import HojaImpresion from "../../components/hoja-impresion/HojaImpresion";
import UapEncabezado from "../../components/uap-encabezado/UapEncabezado";
import DivLoader from "../../components/divLoader/DivLoader";
import { useUsuario } from "../../context/UsuarioContext";
import ModalPrint from "../../components/modals/modalPrint/ModalPrint";
import { listaEstudiantesMateriaPDF } from "../../http/log_materia";

const MateriaEstudiante = () => {

    const { infoBasicData } = useUsuario();
    const location = useLocation();
    const dataMateria = location.state;

    const { setSpinnerLoad } = useUsuario();

    const [show, setShow] = useState(false);

    //console.log(dataMateria?.data);
    const navigate = useNavigate()


    const [datoDocente, setDatoDocente] = useState([]);
    const [listaEstudiantes, setListaEstudiantes] = useState([]);
    const [datoMateria, setDatoMateria] = useState([]);

    const [isLoad, setIsLoad] = useState(false);

    useEffect(() => {
        console.log(dataMateria);
        if (dataMateria != null) {
            setIsLoad(true);
            estudiantesMateria(dataMateria.data).then(
                data => {
                    setIsLoad(false);
                    console.log(data.data);
                    if (data != null) {
                        //setDatoDocente(data.data.datoDocente);
                        setListaEstudiantes(data.data.programados);
                        //setDatoMateria(data.data.datoMateria);
                    }
                }
            )

            materiaAsignatura(dataMateria.data).then(
                data => {
                    setIsLoad(false);
                    console.log(data);
                    if (data != null) {
                        setDatoMateria(data);
                    }
                }
            )
            infoDocente().then(
                data => {
                    setIsLoad(false);
                    console.log(data.data);
                    if (data != null) {
                        setDatoDocente(data);
                    }
                }
            )
        } else {
            //window.location.href = '/asignaturas';
            navigate("/asignaturas");
        }
    }, [dataMateria, navigate]);

    
    function downloadDocument() {
        setSpinnerLoad(true);
        listaEstudiantesMateriaPDF(dataMateria.data).then(
            data => {
                setSpinnerLoad(false);
            }
        );
    }


    const listItems = listaEstudiantes.map((data, index) =>
        <tr key={index.toString()}>
            <td>{index + 1}</td>
            <td>{data.ru}</td>
            <td>{data.paterno} {data.materno} {data.nombre}</td>
            <td>{data.nuevo ? "Nuevo" : "Repite"}</td>
        </tr>);


    let tabla = () => {
        return (
            <Table hover bordered size="sm" responsive className='align-middle font10'>
                <thead>
                    <tr>
                        <th className='text-center'>#</th>
                        <th className='text-center'>R.U.</th>
                        <th className='text-center'>Nombre</th>
                        <th className='text-center'>Tipo</th>
                    </tr>
                </thead>
                <tbody>
                    {listItems}
                </tbody>
            </Table>
        );
    }

    let info = () => {
        return (
            <div className="col-12">
                <div className="col-12">
                    <p className="h5 text-center roboto-condensed-regular">LISTA DE ESTUDIANTES PROGRAMADOS</p>
                </div>
                <div className="table-mini col-12 ms-1 me-1 mb-3 mt-3 font10">
                    <table className="align-middle font10 table">
                        <thead >
                            <tr>
                                <td>TIPO DE ACTA::</td>
                                <td>Eval. {datoMateria.evaluacion ?? " _ "}</td>
                                <td>PERIODO ::</td>
                                <td>{datoMateria.periodo ?? " _ "} - {datoMateria.gestion ?? " _ "}</td>
                            </tr>
                            <tr>
                                <td>DOCENTE::</td>
                                <td>{datoDocente.paterno} {datoDocente.materno} {datoDocente.nombres}</td>
                                <td>CODIGO ::</td>
                                <td>{datoDocente.codigo}</td>
                            </tr>
                            <tr>
                                <td>SIGLA-MATERIA ::</td>
                                <td>{datoMateria.sigla ?? " _ "} - {datoMateria.materia ?? " _ "}</td>
                                <td>NIVEL ACADÉMICO ::</td>
                                <td>{datoMateria.nivel_academico ?? " _ "}</td>
                            </tr>
                            <tr>
                                <td>CARRERA::</td>
                                <td>{datoMateria.carrera ?? " _ "}</td>
                                <td>FACULTAD::</td>
                                <td>{datoMateria.facultad ?? " _ "}</td>
                            </tr>
                            <tr>
                                <td>PLAN ::</td>
                                <td>{datoMateria.plan_materia ?? " _ "}</td>
                                <td>GRUPO::</td>
                                <td>{datoMateria.grupo ?? " _ "}</td>
                            </tr>
                        </thead>
                    </table>
                </div>
                {tabla()}
                <p className="font12">Fecha y Hora de Impresión: {new Date().toLocaleString() + ""}   Usuario: {infoBasicData?.codigo}  Nombres: {infoBasicData?.nombres} {infoBasicData?.paterno} {infoBasicData?.materno} </p>
                <p className="text-center mt-5">_______________</p>
                <p className="text-center roboto-condensed-regular">Firma Docente</p>
            </div>
        );

    };


    function hoja(is) {
        if (is) {
            return (
                <div>
                    <DivLoader />
                </div>
            );
        }
        return (
            <div>
                <div className="">
                    {tabla()}
                </div>

                <div className=" noScreenMin" >
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className='container'>
                <div className='row p-2'>
                    <div className="col-12 mt-3 mb-3">
                        <p className="h5 m-0">{dataMateria?.carrera ?? " _ "}</p>
                        <p className="roboto-condensed-regular h2">{dataMateria?.materia ?? " _ "}</p>
                        <p className="text-muted font11 soloScreenMin"> * Para ver en formato impresión utilice un dispositivo de mayor resolución</p>
                    </div>
                    <div className=' col-12 col-lg-12'>
                        <div className='bg-white shadow mb-5 p-3 bg-body rounded '>

                            <div className="col-12">
                                <p className="h5 text-center roboto-condensed-regular">LISTA DE ESTUDIANTES PROGRAMADOS</p>
                            </div>
                            <hr className="mt-1 mb-1" />
                            <p className="text-end mb-2 mt-2">
                            <Button className="me-2" variant="outline-primary" size="sm" onClick={() => setShow(true)}>
                                Ver Como Documento
                            </Button>
                            <Button  size="sm"  variant="info" className="" onClick={()=>downloadDocument()}>Descargar e Imprimir como Documento</Button>
                            </p>
                            {isLoad?
                            <div className="text-center"><div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Cargando...</span>
                        </div></div>
                        :hoja(isLoad)}

                        </div>
                    </div>
                </div>
            </div>

            <ModalPrint
                titulo={"Lista de Estudiante Programados"}
                show={show}
                onHide={() => setShow(false)}
            >
                <HojaImpresion encabezado={new UapEncabezado()} cuerpo={info()} />

            </ModalPrint>


        </div>
    );



}

export default MateriaEstudiante;