import toast from "react-hot-toast"
import Swal from "sweetalert2"
import { useUsuario } from "../context/UsuarioContext"
import { useEffect } from "react"

export const mostrarAlertaDanger=(mensaje)=>{
  toast.error(mensaje, {
    position: "bottom-center",
    style: {
      borderRadius: '10px',
      background: '#f00',
      color: '#fff',
      fontWeight:'bold'
    },
  })
   /* Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'error',
      title: mensaje,
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
    })*/
  }

  
export const mostrarAlertaDangerTop=(mensaje)=>{
  toast.error(mensaje, {
    position: "top-center",
    style: {
      borderRadius: '10px',
      fontWeight:'bold'
    },
  })
   /* Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'error',
      title: mensaje,
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
    })*/
  }

  export const mostrarAlertaSuccess=(mensaje)=>{
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'success',
      title: mensaje,
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
    })
  }

  export const mostrarAlertaInfo=(mensaje)=>{
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'info',
      title: mensaje,
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
    })
  }

  export const mostrarAlertaInfoTopC = (mensaje)=>{
    
  toast.error(mensaje, {
    position: "bottom-center",
    style: {
      borderRadius: '10px',
      background: '#f00',
      color: '#fff',
      fontWeight:'bold'
    },
  })
    /*Swal.fire({
      toast: true,
      position: 'top-center',
      icon: 'info',
      title: mensaje,
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
    })*/
  }
  
export const mostrarAlerta = (mensaje) => {
  toast.error(mensaje, {
    position: "bottom-center",
    style: {
      borderRadius: '10px',
      background: '#f00',
      color: '#fff',
      fontWeight:'bold'
    },
  })
}

export const mostrarAlertaErrorInfo = (mensaje) => {
  toast.error(mensaje)
}



export const exitLogin = (mensaje) => {
  toast.remove();
  toast.error(<Reload msj={mensaje} />
  );
}

export const mostrarMensajeOK=(msj)=>{
  toast.success(msj)
}


const Reload = (props) => {
  const { token, setToken } = useUsuario();
  useEffect(() => {
    setToken("");
  }, []);
  return <b>{props.msj}</b>
}
