import axios from "axios";
import download from "downloadjs";
import Swal from "sweetalert2";
import { mostrarAlerta, mostrarAlertaDanger, mostrarAlertaDangerTop, mostrarAlertaInfo, mostrarAlertaSuccess } from "../alerts/Alerts";
import { biblioteca_busqueda_libro_url, conexion_url, docente_materiasPG_url, docente_materias_url, docente_up_data_url, docente_up_pass_url, download_cedula_docente_url, download_estudiantes_en_materia_xlsx_url, download_pre_registro_url, download_registro_docente_emit_url, download_registro_docente_url, estudiantes_materia_url, evaluaciones_definidas_url, general_departamentos_url, general_empresas_telefonicas_url, general_estado_civil_url, general_expedidos_url, general_grados_academicos_url, general_intituciones_url, general_localidades_url, general_paises_url, general_provincias_url, general_sexos_url, general_tipos_titulos_url, historial_url, infoBasic_url, infoDocente_url, infoDoc_url, infoGeneral_url, infoPersonal_url, infoPreDocumentos_url, infoPrePersonal_url, infoPreTitulos_url, infoTitulos_url, login_url, materia_asignatura_url, upload_documentos_url, upload_informacion_personal_url, upload_titulos_academicos_url, usuario_confirmar_recovery_url, usuario_verificar_recovery_url, usuario_verific_token_recover_url, download_registro_docente_emit_verific_url, download_cedula_docente_verific_url, biblioteca_elibro_access_url, horario_materias_aula_url } from "../config/urls";
import { infoCod } from "../functions/info"
import verificarCode from "./VerificCodeResponse";


function errorLoad() {
  console.log("");
  Swal.fire({
    title: 'Ocurrio un error en la conexión!!',
    text: "Verifique su conexión a internet y actualice la página",
    icon: 'warning',
    showCancelButton: false,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Actualizar',
    closeOnConfirm: false,
    closeOnCancel: false
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.reload(false);
    }
  })
}

export async function conexion() {

  return axios.post(conexion_url, {
    //--
  }, {
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    if (data.data.ok && data.status === 200) {
      return data.data;
    } else {
      verificarCode(data.data);
      console.log("Conexion fallida");
      return null;
    }
  })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
      return null;
    });

}


export async function loginUser(credentials) {
  return fetch(login_url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "/*",
    },
    body: JSON.stringify(credentials)
  })
    .then(data => {
      //console.log(data);
      if (data.ok && data.status === 200) {
        return data.json();
      } else {
        console.log("Conexion fallida");
        mostrarAlerta("Ocurrió un error, intenta nuevamente");
        return null;
      }
    })
    .catch(err => {
      //console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
      return null;
    })
}

export async function infoBasic() {

  return axios.post(infoBasic_url, {
    //...data
  }, {
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    //console.log(data);
    if (data.data.ok && data.status === 200) {
      return data.data;
    } else {
      verificarCode(data.data);
      console.log("Conexion fallida");
      return null;
    }

  })
    .catch(err => {
      console.log(err.response);
      //console.log(err);
      errorLoad();
      return null;
    });

}

export async function infoUser() {

  return axios.post(infoGeneral_url, {
    //...data
  }, {
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    console.log(data.data);
    if (data.data.ok && data.status === 200) {
      return data.data;
    } else {
      verificarCode(data.data);
      console.log("Conexion fallida");
      return null;
    }
  })
    .catch(err => {
      //console.log(err);
      errorLoad();
      return null;
    });

}

export async function infoPersonal() {

  return axios.post(infoPersonal_url, {
    //...data
  }, {
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    //console.log(data.data);
    if (data.data.ok && data.status === 200) {
      return data.data.data;
    } else {
      verificarCode(data.data);
      console.log("Conexion fallida");
      return null;
    }
  })
    .catch(err => {
      //console.log(err);
      errorLoad();
      return null;
    });

}

export async function infoTitulos() {

  return axios.post(infoTitulos_url, {
    //...data
  }, {
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    //console.log(data.data);
    if (data.data.ok && data.status === 200) {
      return data.data.data;
    } else {
      verificarCode(data.data);
      console.log("Conexion fallida");
      return null;
    }
  })
    .catch(err => {
      //console.log(err);
      errorLoad();
      return null;
    });

}
export async function infoDocuments() {

  return axios.post(infoDoc_url, {
    //...data
  }, {
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    //console.log(data.data);
    if (data.data.ok && data.status === 200) {
      return data.data;
    } else {
      verificarCode(data.data);
      console.log("Conexion fallida");
      return null;
    }
  })
    .catch(err => {
      //console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
      return null;
    });

}

export async function historialDocente() {

  return axios.post(historial_url, {
    //...data
  }, {
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    //console.log(data.data);
    if (data.data.ok && data.status === 200) {
      return data.data;
    } else {
      verificarCode(data.data);
      console.log("Conexion fallida");
      return null;
    }
  })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
      return null;
    });

}

export async function estudiantesMateria(codigo) {

  return axios.post(estudiantes_materia_url, {
    code: codigo
  }, {
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    //console.log(data.data);
    if (data.data.ok && data.status === 200) {
      return data.data;
    } else {
      verificarCode(data.data);
      console.log("Conexion fallida");
      return null;
    }
  })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
      return null;
    });

}

export async function materiasDocente() {

  return axios.post(docente_materias_url, {
    //--
  }, {
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    //console.log(data.data);
    if (data.data.ok && data.status === 200) {
      return data.data;
    } else {
      verificarCode(data.data);
      console.log("Conexion fallida");
      return null;
    }
  })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}


export async function horarioDocente() {

  return axios.get(horario_materias_aula_url, {
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    //console.log(data.data);
    if (data.data.ok && data.status === 200) {
      return data.data.data;
    } else {
      verificarCode(data.data);
      console.log("Conexion fallida");
      return null;
    }
  })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
      return null;
    });

}



export async function materiasDocentePG(d) {

  return axios.post(docente_materiasPG_url, d, {
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    //console.log(data.data);
    if (data.data.ok && data.status === 200) {
      return data.data;
    } else {
      verificarCode(data.data);
      console.log("Conexion fallida");
      return null;
    }
  })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
      return null;
    });

}



//************************* UP DATA */

export async function upInfo(data) {

  return axios.post(docente_up_data_url,
    data,
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data;
      } else {
        

verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
      return null;
    });

}

//************************* UP PASSWORD Y USUARIO */

export async function upPass(data) {

  return axios.post(docente_up_pass_url,
    data,
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      console.log(data.data);
      if (data.data.ok && data.status === 200) {
        mostrarAlertaSuccess(data.data.mensaje);
        return data.data;
      } else {        

verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
      return null;
    });

}



//************************* Busqueda de libros */

export async function searchLibro(data) {

  return axios.post(biblioteca_busqueda_libro_url,
    data,
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        mostrarAlertaSuccess(data.data.mensaje);
        return data.data.data;
      } else {        

verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
      return null;
    });

}



//************************* Busqueda de accesos elibro */

export async function searchAccessElibro(data) {

  return axios.post(biblioteca_elibro_access_url,
    data,
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {        
          verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
      return null;
    });

}

//************************* Recuperación de contraseña */

export async function verificarDatosRecovery(data) {

  return axios.post(usuario_verificar_recovery_url,
    data,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {
        verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
      return null;
    });

}

export async function confirmarRecovery(data) {

  return axios.post(usuario_confirmar_recovery_url,
    data,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {
        verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function verificarTokenRecover(data) {

  return axios.post(usuario_verific_token_recover_url,
    data,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {        
        verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}


//**************DOWLOAD  PDF */

export async function downloadRegistroDocente(data) {

  await conexion();
  return await axios({
    url: download_registro_docente_url,
    method: "POST",
    responseType: "blob",
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
    }
  }).then(response => {
    /*
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
     const pdfWindow = window.open();
     pdfWindow.location.href = fileURL; 
     */
    console.log(response);
    //verificarCode(response.data);
    if (response.status === 200) {
      const content = response.headers['content-type'];
      download(response.data, "RegistroDocente.pdf", content);
      return true;
    }
    return false;
  })
    .catch(error => {
      console.log(error);
      return false;
    });
}

export async function downloadRegistroDocenteEmit() {

  var response = await axios({
    url: download_registro_docente_emit_verific_url,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  });
  console.log(response);
  if (response.data?.status===200) {
    var res = await downloadRegistroDocenteEmit_confimado();
    return res;
  } else {
    mostrarAlertaDangerTop(response.data.mensaje);
    return null;
  }
}

async function downloadRegistroDocenteEmit_confimado() {

  return await axios({
    url: download_registro_docente_emit_url,
    method: "POST",
    responseType: "blob",
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
    }
  }).then(response => {
    console.log(response);
    verificarCode(response.data);
    if (response.status === 200) {
      const content = response.headers['content-type'];
      download(response.data, "RegistroDocente.pdf", content);
      return true;
    }
    return false;
  })
    .catch(error => {
      console.log(error);
      return false;
    });
}

export async function downloadCedulaDocente(data) {

  var response = await axios({
    url: download_cedula_docente_verific_url,
    method: "POST",
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  });

  console.log(response);
  if (response.data?.status===200) {
    var res = await downloadCedulaDocente_confirmado();
    return res;
  } else {
    mostrarAlertaDangerTop(response.data.mensaje);
    return null;
  }
}

async function downloadCedulaDocente_confirmado(data) {

  await conexion();
  return await axios({
    url: download_cedula_docente_url,
    method: "POST",
    responseType: "blob",
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
    }
  }).then(response => {
    verificarCode(response.data);
    if (response.status === 200) {
      const content = response.headers['content-type'];
      download(response.data, "CedulaDocente.pdf", content);
      return true;
    }
    return false;
  })
    .catch(error => {
      console.log(error);
      return false;
    });
}

export async function downloadPreRegistro(data) {

  await conexion();
  return await axios({
    url: download_pre_registro_url,
    method: "POST",
    responseType: "blob",
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
    }
  }).then(response => {
    verificarCode(response.data);
    if (response.status === 200) {
      const content = response.headers['content-type'];
      download(response.data, "PreActualizacion.pdf", content);
      return true;
    }
    return false;
  })
    .catch(error => {
      console.log(error);
      return false;
    });
}

export async function downloadListaEstudiantesAsignaturaXlsx(data, materia) {

  await conexion();
  return await axios({
    url: download_estudiantes_en_materia_xlsx_url,
    method: "POST",
    data: data,
    responseType: "blob",
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
    }
  }).then(response => {
    verificarCode(response.data);
    if (response.status === 200) {
      const content = response.headers['content-type'];
      download(response.data, materia + ".xlsx", content);
      return true;
    }
    return false;
  })
    .catch(error => {
      console.log(error);
      return false;
    });
}


//************************* datos Generales */

export async function sexosRest() {

  return axios(
    {
      url: general_sexos_url,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      verificarCode(data.data);
      if (data.data.ok && data.status === 200) {
        mostrarAlertaSuccess(data.data.mensaje);
        return data.data.data;
      } else {
        mostrarAlertaDanger(data.data.mensaje);
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function estadosCivilesRest() {

  return axios(
    {
      url: general_estado_civil_url,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      verificarCode(data.data);
      if (data.data.ok && data.status === 200) {
        mostrarAlertaSuccess(data.data.mensaje);
        return data.data.data;
      } else {
        mostrarAlertaDanger(data.data.mensaje);
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function expedidoRest() {

  return axios(
    {
      url: general_expedidos_url,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      verificarCode(data.data);
      if (data.data.ok && data.status === 200) {
        mostrarAlertaSuccess(data.data.mensaje);
        return data.data.data;
      } else {
        mostrarAlertaDanger(data.data.mensaje);
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function paisesRest() {

  return axios(
    {
      url: general_paises_url,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      verificarCode(data.data);
      if (data.data.ok && data.status === 200) {
        mostrarAlertaSuccess(data.data.mensaje);
        return data.data.data;
      } else {
        mostrarAlertaDanger(data.data.mensaje);
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function departamentosRest() {

  return axios(
    {
      url: general_departamentos_url,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      verificarCode(data.data);
      if (data.data.ok && data.status === 200) {
        mostrarAlertaSuccess(data.data.mensaje);
        return data.data.data;
      } else {
        mostrarAlertaDanger(data.data.mensaje);
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function provinciasRest() {

  return axios(
    {
      url: general_provincias_url,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      verificarCode(data.data);
      if (data.data.ok && data.status === 200) {
        mostrarAlertaSuccess(data.data.mensaje);
        return data.data.data;
      } else {
        mostrarAlertaDanger(data.data.mensaje);
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function localidadRest() {

  return axios(
    {
      url: general_localidades_url,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      verificarCode(data.data);
      if (data.data.ok && data.status === 200) {
        mostrarAlertaSuccess(data.data.mensaje);
        return data.data.data;
      } else {
        mostrarAlertaDanger(data.data.mensaje);
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function tiposTitulosRest() {

  return axios(
    {
      url: general_tipos_titulos_url,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      verificarCode(data.data);
      if (data.data.ok && data.status === 200) {
        mostrarAlertaSuccess(data.data.mensaje);
        return data.data.data;
      } else {
        mostrarAlertaDanger(data.data.mensaje);
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function institucionesRest() {

  return axios(
    {
      url: general_intituciones_url,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      verificarCode(data.data);
      if (data.data.ok && data.status === 200) {
        mostrarAlertaSuccess(data.data.mensaje);
        return data.data.data;
      } else {
        mostrarAlertaDanger(data.data.mensaje);
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function gradosAcademicosRest() {

  return axios(
    {
      url: general_grados_academicos_url,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      verificarCode(data.data);
      if (data.data.ok && data.status === 200) {
        mostrarAlertaSuccess(data.data.mensaje);
        return data.data.data;
      } else {
        mostrarAlertaDanger(data.data.mensaje);
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function empresasTelefonicasRest() {

  return axios(
    {
      url: general_empresas_telefonicas_url,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      verificarCode(data.data);
      if (data.data.ok && data.status === 200) {
        mostrarAlertaSuccess(data.data.mensaje);
        return data.data.data;
      } else {
        mostrarAlertaDanger(data.data.mensaje);
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}


//************************* solicitar actualizacion de datos */

export async function uploadInfoPersonal(data) {

  return axios.post(upload_informacion_personal_url,
    data,
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {        

verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function uploadInfoTitulos(data) {

  return axios.post(upload_titulos_academicos_url,
    data,
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {        

verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function uploadInfoDocumentos(data) {

  return axios.post(upload_documentos_url,
    data,
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        "Content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {        

verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}
//////////////////////////////
//************************* ver solicitud de actualizacion de datos */

export async function infoPrePersonal() {

  return axios.post(infoPrePersonal_url,
    {},
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {        

verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function infoPreTitulos() {

  return axios.post(infoPreTitulos_url,
    {},
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {        

verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function infoPreDocumentos() {

  return axios.post(infoPreDocumentos_url,
    {},
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {        

verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}

export async function requestGenearl(data, url) {

  return axios.post(url, data, {
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    //console.log(data.data);
    if (data.data.ok && data.status === 200) {
      return data.data;
    } else {
      verificarCode(data.data);
      console.log("Conexion fallida");
      return null;
    }
  })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}
//////////////////////////////


export async function materiaAsignatura(data, gestion, periodo) {

  return axios.post(materia_asignatura_url,
    {
      "code": data,
      "gestion": gestion,
      "periodo": periodo,
    },
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {        

verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
    });

}


export async function evaluacionesDefinidas(data, mat, ges, per) {

  return axios.post(evaluaciones_definidas_url,
    {
      "code": data,
      "code_m": mat,
      "gestion": ges,
      "periodo": per
    },
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {        

verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
      return null;
    });

}

export async function infoDocente() {

  return axios.post(infoDocente_url,
    {},
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {        

verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
      return null;
      return null;
    });

}

