import React from 'react';
import { useState } from 'react';
import './login.css';
import * as FaIcons from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import { loginUser } from '../../http/log';
import { useUsuario } from '../../context/UsuarioContext';
import { Link } from 'react-router-dom';
import logoUap from '../../assets/img/logo_uap.png';
import logoPerlaNet from '../../assets/img/LOGO_PERLA_NET_512.png';
import { imagen_login_url } from '../../config/urls';
import { version_app } from '../../config/info_app';

export default function Login() {


  const { setToken, setSpinnerLoad, setLoginModalLoad } = useUsuario();

  const [password, setPassword] = useState();
  const [username, setUserName] = useState();

  const [loginError, setLoginError] = useState("");

  const [viewPass, setviewPass] = useState(false);

  const [imageLogin, setImageLogin] = useState(false);



  /*
    const handleValidation = (event) => {
      let formIsValid = true;
  
      if (!username.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
        formIsValid = false;
        setemailError("Email No Valido");
        return false;
      } else {
        setemailError("");
        formIsValid = true;
      }
  
      if (!password.match(/^[a-zA-Z]{8,22}$/)) {
        formIsValid = false;
        setpasswordError(
          "Only Letters and length must best min 8 Chracters and Max 22 Chracters"
        );
        return false;
      } else {
        setpasswordError("");
        formIsValid = true;
      }
  
      return formIsValid;
    };*/

  const handleSubmit = async e => {
    e.preventDefault();
    setLoginError("");
    setSpinnerLoad(true);

    let data = await loginUser({
      username,
      password
    });
    //console.log(data);
    if (data != null) {
      if (data.status === 200 && data.ok) {
        console.log(data);
        setToken(data.data.token);
        setLoginModalLoad(false);
      } else {
        setLoginError(data.mensaje);
      }
    }
    setSpinnerLoad(false);
  }

  return (
    <div className='login bg-white d-flex align-items-center'>

      <div className="container">
        <div className="row d-flex justify-content-center align-items-center">

          <div className='col-lg-8 col-md-6 col-sm-12 align-items-center text-center noScreenMin'>
            <img src={imagen_login_url} className='img-fluid' alt='imagen' onLoad={() => setImageLogin(true)} style={imageLogin ? { 'display': 'inline','transition':'visibility 0s, opacity 0.5s linear' } : { 'display': 'none' }} />
            {imageLogin ? <></> : <img src={logoUap} className='img-fluid' alt='LogoUAP' />}
            <br></br>
            <p style={{
              'fontSize': '2em',
              'fontFamily': 'cursive',
            }}>Universidad Amazónica de Pando</p>
            <p style={{
              'fontSize': '1em',
              'fontFamily': 'cursive',
            }}>Sistema Académico Docente</p>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12'>

            <div className='col-12 soloScreenMin' style={{ 'height': '110px' }}>
              <div className='text-center' style={{ 'position': 'absolute', 'left': '0', 'right': '0', }}>
                <img src={logoUap} style={{ 'width': '120px' }} alt='logo UAP' />
              </div>
            </div>

            <div className="login-box bg-white m-3 shadow mb-5 bg-body rounded" style={{ 'borderTop': '5px solid red' }}>
              <div className="card-header text-center bg-white h1 p-3 mt-3 mb-3">
                <b>Perla</b><span>Net</span>
              </div>
              <div className="card-body text-center">
                <p className="login-box-msg text-muted mb-3">Ingrese su usuario y clave para acceder al Sistema</p>
                <form onSubmit={handleSubmit}>
                  <div className="input-group mb-3">
                    <input onChange={e => setUserName(e.target.value)} type="text" className="form-control" placeholder="Usuario" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                    <span className="input-group-text" id="basic-addon2"><FaIcons.FaUserAlt /></span>
                  </div>

                  <div className="input-group mb-2">
                    <input onChange={e => setPassword(e.target.value)} name="password" placeholder="Contraseña" type={viewPass ? "text" : "password"} className="form-control" />
                    <span className="input-group-text pointer" id="basic-addon2" onClick={(e) => setviewPass(!viewPass)}>{viewPass ? <FaIcons.FaEye /> : <FaIcons.FaEyeSlash />}</span>
                  </div>

                  <div className='mb-3 text-danger font12'>
                    <strong>{loginError.length < 100 ? loginError : ""}</strong>
                  </div>

                  <div className="row">
                    {/*
                              <div className="col-7">
                                <div className="icheck-primary">
                                  <Form.Check 
                                    type="switch"
                                    id="custom-switch"
                                    label="Mantener Sesión"
                                  />  
                                </div>
    </div>*/}

                    <div className="col-12 align-self-end">
                      <button type="submit" className="btn btn-primary btn-block" >Acceder</button>
                    </div>
                  </div>

                </form>
                <p className="m-3">
                  <Link to="/forgot-password">Recuperar mi contraseña</Link>
                </p>
              </div>

              <div className='text-center'>
                <img style={{height:"40px"}} src={logoPerlaNet}/>
              </div>
              <p className='text-center pb-3 text-secondary'>{version_app}</p>
            </div>
          </div>


        </div>
      </div>
      <ModalInfoLogin
        show={loginError.length > 100}
        onHide={() => setLoginError("")}
        titulo={"Atención"}
        texto={loginError}
      />
    </div>
  );
}

function ModalInfoLogin(props) {

  return (
    <Modal size="md" show={props.show} onHide={props.onHide}
      centered>
      <Modal.Body>
        <div closeButton className="text-center roboto-condensed-regular m-1 mb-3"><p>{props.titulo}</p></div>
        <div className="text-center  m-3" dangerouslySetInnerHTML={{ __html: props.texto }} />
      </Modal.Body>

      <Modal.Footer className='p-0'>
        <div className='row col-12 m-0'>
          <span onClick={props.onHide} className='col-12 btn-ios rounded-bottom'>Cerrar</span>
        </div>
      </Modal.Footer>

    </Modal>
  );
}


