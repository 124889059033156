import { Button, Form, Modal, Table, } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import * as Bs from 'react-icons/bs';
import { useEffect, useRef, useState } from 'react';
import ModalInfo from '../../components/modals/ModalInfo';
import { infoDocuments, uploadInfoDocumentos } from '../../http/log';
import ModalFilePdf from '../fileUpload/FileUpload';
import { FaFilePdf } from "react-icons/fa";


function StepReqDocente({ nextStep, handleFormData, prevStep, values }) {
    //inicar haciendo una peticion http para obtener datos

    const [documentos, setDocumentos] = useState([]);

    useEffect(() => {
        cargarDatos();
    }, []);

    function cargarDatos() {
        infoDocuments().then(
            data => {
                if (data != null) {
                    if (data.status === 200 && data.ok) {
                        //console.log(data);
                        setDocumentos(data.data ?? []);
                    } else {

                    }
                }
            }
        )
    }
    //**************** */

    const [showModal, setShowModal] = useState(false);
    const [showModalFile, setShowModalFile] = useState(false);
    const [showModalFileName, setShowModalFileName] = useState("");
    const [titleModal, setTitleModal] = useState("titulo");
    const [textoModal, setTextModal] = useState("texto");
    const [formValue, setformValue] = useState({});
    const [formValueDoc, setformValueDoc] = useState({});
    const [formValueNum, setformValueNum] = useState({});

    function dataUp(texto) {
        setShowModal(true);
        setTitleModal("Observación");
        setTextModal(texto);
    }

    const handleChange = (event) => {
        setformValue({
            ...formValue,
            [event.target.name]: formValue[event.target.name] === undefined || formValue[event.target.name] === false ? true : false
        });
        //console.log(formValue);
    }

    const handleChangeDoc = (dat) => {
        if (dat.file !== undefined && dat.name !== undefined) {
            setformValueDoc({
                ...formValueDoc,
                [dat.name]: dat.file
            });
        }
    }

    
    const handleChangeNum = (event) => {
        setformValueNum({
            ...formValueNum,
            [event.target.name]: event.target.value
        });
    }


    const handleSubmit = (event) => {

        console.log(formValue);

        event.preventDefault();
        let formData = new FormData();

        Object.keys(formValue).forEach((e) => {
            //console.log(e, ':', formValue[e]);
            if (formValue[e] === true) {
                formData.append(e, formValue[e]);
                if (formValueDoc[e + "-file"] !== undefined) {
                    formData.append(e + "-file", formValueDoc[e + "-file"])
                }
                if (formValueNum[e + "-code"] !== undefined) {
                    formData.append(e + "-code", formValueNum[e + "-code"])
                }
            }
        });
        //console.log(formData);
        // console.log(formValueDoc);
        uploadInfoDocumentos(formData).then(
            data => {
                if (data != null) {
                    nextStep();
                }
            }
        )
    }


    const loadModal = (name) => {
        setShowModalFileName(name);
        setShowModalFile(true);
    }
    /* DATA */

    /** */
    const listItems = documentos.map((data, index) =>
        <tr key={index.toString()}>
            <td>{index + 1}</td>
            <td>{data.tipo_documento}</td>
            <td className='text-center'>
                <strong >{data.tiene ? <FaIcons.FaRegCheckCircle className='text-success' /> : <FaIcons.FaRegTimesCircle className='text-danger' />}</strong>
            </td>
            <td className='text-center'>
                {data.observacion !== "" ? <Button variant="light" className='m-1 p-0' onClick={() => dataUp(data.observacion)}><Bs.BsFillChatSquareTextFill className='text-info' /></Button> : <></>}
            </td>
            <td className='text-center'>
                {data.url !== "" ? <a href={data.url} target="_blank" rel="noreferrer" className='m-1 p-0 btn btn-light'><FaIcons.FaFilePdf className='text-danger' /></a> : <></>}
            </td>
            <td className='text-center'>
                <div className="input-group">
                    <div className="input-group-text border-0 bg-transparent">
                        <input
                            onChange={handleChange}
                            className="form-check-input"
                            type="checkbox"
                            name={"doc-" + data.codigo_tipo_documento}
                            id="flexCheckDefault"
                            data-bs-toggle="collapse"
                            data-bs-target={"#inp" + index}
                            aria-expanded="false"
                            aria-controls={"inp" + index} />
                    </div>
                    <div className="collapse form-control p-0 border-0 bg-transparent" id={"inp" + index}>
                        <div className="p-0">
                            <Form.Control
                                name={"doc-" + data.codigo_tipo_documento + "-code"}
                                type="text"
                                placeholder='Número o serie'
                                id={index + 10000}
                                onChange={handleChangeNum}
                                className="mb-1"
                            //className="visually-hidden"
                            //onChange={(e) => { setImage(e.target.files[0]); onSelectFile(e) }}
                            />
                            <div className="mb-1" >
                                {
                                    formValueDoc["doc-" + data.codigo_tipo_documento + "-file"] === undefined ?
                                        (<div>
                                            <Button variant="light col-12" onClick={(e) => loadModal("doc-" + data.codigo_tipo_documento + "-file")}>Cargar Documento PDF</Button>
                                        </div>)
                                        :
                                        (<div className=''>
                                            <Button variant="info col-12" className="text-white" onClick={(e) => loadModal("doc-" + data.codigo_tipo_documento + "-file")}><FaFilePdf /> {formValueDoc["doc-" + data.codigo_tipo_documento + "-file"].name} ({((formValueDoc["doc-" + data.codigo_tipo_documento + "-file"].size) / 1000000).toFixed(3)} Mb)</Button>
                                        </div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </td>

        </tr>);



    return (
        <div className="info_basico m-3">
            <p className='text-start mt-3 mb-3'>La siguiente tabla muestra la documentación de requisitos docente. Si desea regularizar su documentación selecione la opción en la columna <strong>Actualizar ?</strong>. Es posible agregar el número de documento si es necesario, ademas de adjuntar el docuemto en digital.</p>
            <hr />
            <Form onSubmit={(e) => handleSubmit(e)}>

                <div className='d-flex mb-2'>
                </div>
                <p className='font12 text-secondary text-end mb-2'>Fecha de última actualización {documentos[0]?.modificacion}</p>
                <hr />
                <div>
                    <Table responsive className='align-middle' bordered hover size="sm" >
                        <thead>
                            <tr>
                                <th className='text-center'>#</th>
                                <th className='text-center'>Documento</th>
                                <th className='text-center'>Estado</th>
                                <th className='text-center'>Obs.</th>
                                <th className='text-center'><FaIcons.FaDownload /></th>
                                <th className='text-center'>Actualizar ?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listItems}
                        </tbody>
                    </Table>
                </div>

                <div className="col-12 mb-5 mt-3">
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <Button variant="secondary" onClick={prevStep}>
                            ATRÁS
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            GUARDAR Y CONTINUAR
                        </Button>
                    </div>
                </div>
            </Form>

            <ModalFilePdf
                show={showModalFile}
                onHide={() => setShowModalFile(false)}
                nameFile={showModalFileName}
                onChange={handleChangeDoc}
            />
            <ModalInfo show={showModal} onHide={() => setShowModal(false)} titulo={titleModal} texto={textoModal} />
        </div>
    );
}



export default StepReqDocente;