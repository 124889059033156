import { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import './modalPrint.css';

const ModalPrint = (props) => {



    let componentRef = useRef(null);



    return (
        <>
            <Modal show={props.show} fullscreen={true} onHide={() => props.onHide()}>
                <Modal.Header closeButton className="modal-header-print">
                    <Modal.Title>{props.titulo}</Modal.Title>
                    <p className="text-end ms-5">
                        <ReactToPrint
                            trigger={() => {
                                return <Button  size="sm"  variant="light" className="">Imprimir Documento</Button>;
                            }}
                            documentTitle="Impresión"
                            content={() => componentRef}
                        />
                    </p>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="bg-secondary p-3">
                        <div className="d-flex justify-content-center" >
                            <div className="borde-pagina-1cm shadow">
                                <div className="" ref={el => (componentRef = el)}>
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalPrint;