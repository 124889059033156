import { Col, Row } from "react-bootstrap";
import { TbSquareRoundedNumber1Filled, TbSquareRoundedNumber2Filled, TbSquareRoundedNumber3Filled, TbSquareRoundedNumber4Filled } from 'react-icons/tb';
import StepCard from "./StepCard";
import pagoImg from '../../assets/img/pago.png'
import logoUniMovil from '../../assets/img/UniMovil.png';
import appMenuImg from '../../assets/img/appMenu.png'
import numericoImg from '../../assets/img/numerico.png'

const PagoCpt = (props) => {

    return (<div>

        <div className="m-3">

            <div className="text-center m-auto mb-4" >
                <h5 className="text-center fw-bold mb-5">Registre su código de pago de tramite CPT</h5>
                <div className="m-3 border fs-1 rounded-pill">{props.cptCode}</div>
            </div>
            <Row>
                <Col sm={12} className="text-center m-3">
                    <strong>Intrucciones</strong>
                </Col>
                <Col sm={1} md={1} className="d-flex">
                    <div className="text-center text-info m-auto"><h1 className=""><TbSquareRoundedNumber1Filled /></h1></div>
                </Col>

                <Col sm={11} md={5} className="d-flex mt-2 mb-2">
                    <StepCard
                        img={logoUniMovil}
                        titulo={"Inicia la App UniMovil o plataforma UniNet del Banco Unión"}
                        detalle={"Debe acceder a una de las plataformas mencionadas del Banco Unión"}
                        aclariacion={""}
                    />
                </Col>
                <Col sm={1} md={1} className="d-flex">
                    <div className="text-center text-info m-auto"><h1 className=""><TbSquareRoundedNumber2Filled /></h1></div>
                </Col>

                <Col sm={11} md={5} className="d-flex mt-2 mb-2">
                    <StepCard
                        img={appMenuImg}
                        titulo={"Busca la opción Pago de Trámites"}
                        detalle={"En el Menú de la plataforma, localice la opción Pago de tramites"}
                        aclariacion={""}
                    />
                </Col>
                <Col sm={1} md={1} className="d-flex">
                    <div className="text-center text-info m-auto"><h1 className=""><TbSquareRoundedNumber3Filled /></h1></div>
                </Col>

                <Col sm={11} md={5} className="d-flex mt-2 mb-2">
                    <StepCard
                        img={numericoImg}
                        titulo={"Ingrese el Código"}
                        detalle={"Ingrese el codigo CPT que se muestra arriba, registrelo cuidadosamente para no tener fallas"}
                        aclariacion={""}
                    />
                </Col>
                <Col sm={1} md={1} className="d-flex">
                    <div className="text-center text-info m-auto"><h1 className=""><TbSquareRoundedNumber4Filled /></h1></div>
                </Col>
                <Col sm={11} md={5} className="d-flex mt-2 mb-2">
                    <StepCard
                        img={pagoImg}
                        titulo={"Confirma el Pago"}
                        detalle={"Debe confirmar el pago siguiendo las instrucciones de la plataforma"}
                        aclariacion={""}
                    />
                </Col>
            </Row>
            <Col sm={12} className="mt-2 mb-2">
                * Después de confirmar el pago, debes dirigirte a la opcion de VER CERTIFICADOS COMPRADOS, donde podrás descargar el documento y ver el estado del mismo
            </Col>
        </div>
    </div>);
}

export default PagoCpt;