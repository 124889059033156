import axios from "axios";
import download from "downloadjs";
import { infoCod } from "../functions/info"
import { conexion } from "./log";
import verificarCode from "./VerificCodeResponse";
import { mostrarAlerta, mostrarAlertaDanger, mostrarAlertaErrorInfo} from "../alerts/Alerts";
import { download_form001_usa_doc_url, download_form006_doc_url, download_form_incompatibilidad_PDF_url, download_Manual_PDF_url, download_NotasConvertidas_PDF_url } from "../config/url_dowloads";


export async function FormIncompativilidadHttpPdf() {

  await conexion();
  return await axios({
    url: download_form_incompatibilidad_PDF_url,
    data:{},
    method: "POST",
    responseType: "blob",
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
    }
  }).then(response => {
    verificarCode(response.data);
    if (response.status === 200) {
      const content = response.headers['content-type'];
      download(response.data, "FORM_incompatibilidad_en_el_horario_de_Docencia.pdf", content);
      return true;
    }
    return false;
  })
    .catch(error => {
      console.log(error);
      return false;
    });
}


export async function Form001UsaDOC() {

  await conexion();
  return await axios({
    url: download_form001_usa_doc_url,
    data:{},
    method: "POST",
    responseType: "blob",
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
    }
  }).then(response => {
    verificarCode(response.data);
    if (response.status === 200) {
      const content = response.headers['content-type'];
      download(response.data, "HabilitacionParcialNotas_FORM001.docx", content);
      return true;
    }
    return false;
  })
    .catch(error => {
      console.log(error);
      return false;
    });
}


export async function Form006DOC() {

  await conexion();
  return await axios({
    url: download_form006_doc_url,
    data:{},
    method: "POST",
    responseType: "blob",
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
    }
  }).then(response => {
    verificarCode(response.data);
    if (response.status === 200) {
      const content = response.headers['content-type'];
      download(response.data, "RetrocesoDeFase_FORM006.docx", content);
      return true;
    }
    return false;
  })
    .catch(error => {
      console.log(error);
      return false;
    });
}


export async function NotasConvetidas() {

  await conexion();
  return await axios({
    url: download_NotasConvertidas_PDF_url,
    data:{},
    method: "POST",
    responseType: "blob",
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
    }
  }).then(response => {
    verificarCode(response.data);
    if (response.status === 200) {
      const content = response.headers['content-type'];
      download(response.data, "NotasConvertidas.pdf", content);
      return true;
    }
    return false;
  })
    .catch(error => {
      console.log(error);
      return false;
    });
}


export async function ManualDeUsuario() {

  await conexion();
  return await axios({
    url: download_Manual_PDF_url,
    data:{},
    method: "POST",
    responseType: "blob",
    headers: {
      'Authorization': `Bearer ${infoCod()}`,
    }
  }).then(response => {
    verificarCode(response?.data);
    if (response.status === 200) {
      const content = response.headers['content-type'];
      download(response.data, "ManualDeUsuario_PerlaNET.pdf", content);
      return true;
    }
    return false;
  })
    .catch(error => {
      console.log(error);
      return false;
    });
}
