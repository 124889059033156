import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row, Table } from "react-bootstrap";
import { FaDownload, FaFilePdf } from "react-icons/fa";
import { gradosAcademicosRest, infoTitulos, institucionesRest, tiposTitulosRest, uploadInfoTitulos } from "../../http/log";


function StepInfoAcademica({ nextStep, handleFormData, prevStep, values }) {

    const [data, setData] = useState([]);
    const [nuevos, setNuevos] = useState([]);
    const [tiposTitulos, setTiposTitulos] = useState([]);
    const [instituciones, setInstituciones] = useState([]);
    const [gradosAcademicos, setGradosAcademicos] = useState([]);
    const [modalNew, setModalNew] = useState(false);

    //inicar haciendo una peticion http para obtener datos

    useEffect(() => {

        const getTitulos = () => {
            infoTitulos().then(
                data => { if (data != null) { setData(data) } }
            )
        };

        const getTiposTitulos = () => {
            tiposTitulosRest().then(
                data => { if (data != null) { setTiposTitulos(data) } }
            )
        };

        const getInstituciones = () => {
            institucionesRest().then(
                data => { if (data != null) { setInstituciones(data) } }
            )
        };

        const getGradosAcademicos = () => {
            gradosAcademicosRest().then(
                data => { if (data != null) { setGradosAcademicos(data) } }
            )
        };

        getTitulos();
        getTiposTitulos();
        getInstituciones();
        getGradosAcademicos();

    }, []);

    //**************** */


    const handleSubmit = (event) => {
        let da = nuevos.map((nuevo) => ({
            codigo_institucion: nuevo.institucion.id,
            codigo_grado: nuevo.grado.id,
            codigo_titulo: nuevo.tipo.id,
            descripcion: nuevo.descrip,
            anio: nuevo.anio,
            serie: nuevo.serie,
        }));
        //console.log(nuevos);
        //console.log(da);
        uploadInfoTitulos(da).then(
            data => {
                if (data != null) {
                    nextStep();
                }
            }
        )
    }

    const nuevo = (d) => {
        //console.log(d);
        //console.log(d.tipo.val);
        setNuevos(nuevos => [...nuevos, d]);

    }
    const remove = (index) => {
        setNuevos([
            ...nuevos.slice(0, index),
            ...nuevos.slice(index + 1)
        ]);
    }

    return (
        <div className="info_basico m-3">
            <p className='text-start mt-3 mb-3'>La tabla siguiente muestra los títulos académicos registrados en el sistema. Si desea Agregar Uno nuevo dale Click en el botón <strong>Agregar Nuevo</strong></p>
            <hr />
            <br />
            <div>
                <Table responsive bordered hover size="sm" >
                    <thead>
                        <tr className="text-center">
                            <th>Titulo</th>
                            <th>Institución</th>
                            <th>Grado Académico</th>
                            <th>Descripción</th>
                            <th>Año</th>
                            <th>Nro. Serie</th>
                            <th className='text-center'><FaDownload /></th>
                        </tr>
                    </thead>
                    <tbody className="font14">
                        {data.map((titulo, index) =>
                            <tr key={index}>
                                <td className='text-center'>{titulo.titulo}</td>
                                <td className='text-center'>{titulo.institucion}</td>
                                <td className='text-center'>{titulo.grado}</td>
                                <td className='text-center'>{titulo.descripcion}</td>
                                <td className='text-center'>{titulo.anio}</td>
                                <td className='text-center'>{titulo.serie}</td>
                                <td className='text-center'>
                                    {titulo.digital !== "" ? <a href={titulo.digital} target="_blank" rel="noreferrer" className='m-1 btn btn-light p-0'><FaFilePdf className='text-danger' /></a> : <></>}
                                </td>
                            </tr>
                        )}
                        <tr className="text-center">
                            <th className='text-center' colSpan={7}>
                                <strong>Nuevos</strong>
                            </th>
                        </tr>
                        {nuevos.map((tituloN, index) =>
                            <tr key={index}>
                                <td className='text-center'>{tituloN.tipo.val}</td>
                                <td className='text-center'>{tituloN.institucion.val}</td>
                                <td className='text-center'>{tituloN.grado.val}</td>
                                <td className='text-center'>{tituloN.descrip}</td>
                                <td className='text-center'>{tituloN.anio}</td>
                                <td className='text-center'>{tituloN.serie}</td>
                                <td className='text-center'>
                                    <Button variant="outline-danger" onClick={(e) => remove(index)}>X</Button>
                                </td>
                            </tr>
                        )}
                        <tr className="text-center">
                            <td className='text-end' colSpan={7}>

                                <Button variant="outline-success" onClick={(e) => setModalNew(true)}>+ Agregar Nuevo</Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className='text-muted'><strong>Nota: </strong><small>Se debe registrar el Diploma de Bachiller,
                    Diploma Academico, Titulo en Provision Nacinal, Diplomado en Educacion Superior o Maestria en Educacion Superior y otros Titulos Posgraduales. Todos debidamente legalizados</small></div>

            </div>

            <div className="col-12 mb-5 mt-3">
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <Button variant="secondary" onClick={prevStep}>
                        ATRÁS
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        GUARDAR Y CONTINUAR
                    </Button>
                </div>
            </div>

            <ModalNewTituloAcademico
                show={modalNew}
                onHide={() => setModalNew(false)}
                tiposTitulos={tiposTitulos}
                instituciones={instituciones}
                gradosAcademicos={gradosAcademicos}
                onNuevo={nuevo}
            />
        </div>
    );
}


function ModalNewTituloAcademico(props) {


    const [tituloSelect, setTituloSelect] = useState({});
    const [instSelect, setInstSelect] = useState({});
    const [gradoSelect, setGradoSelect] = useState({});
    const [descrip, setDescrip] = useState("");
    const [anio, setAnio] = useState(0);
    const [serie, setSerie] = useState("");
    const [error, setError] = useState("");


    function validacion(){
        if(tituloSelect.id===undefined)
        {
            setError("Es necesario selecionar un tipo de titulo académico");
            return false;
        }
        if(instSelect.id===undefined)
        {
            setError("Es necesario selecionar una istitución académica");
            return false;
        }
        if(gradoSelect.id===undefined)
        {
            setError("Es necesario selecionar un grado académico");
            return false;
        }
        if(descrip==="" || descrip===null)
        {
            setError("Es necesario llenar la descripción del título académico");
            return false;
        }
        if(anio<1900 || anio>3000)
        {
            setError("Es necesario ingresar el año correctamente");
            return false;
        }
        if(serie==="" || serie===null)
        {
            setError("Es necesario ingresar el número de serie");
            return false;
        }
        
        return true;
    }

    function limpiarYsalir(){

        setTituloSelect({});
        setInstSelect({});
        setGradoSelect({});
        setDescrip("");
        setAnio(0);
        setSerie("");
        setError("");
        props.onHide();
    }

    function addNew() {
        console.log({
            tipo: tituloSelect,
            institucion: instSelect,
            grado: gradoSelect,
            descrip: descrip,
            anio: anio,
            serie: serie,
        });
        if(validacion()){
            props.onNuevo({
                tipo: tituloSelect,
                institucion: instSelect,
                grado: gradoSelect,
                descrip: descrip,
                anio: anio,
                serie: serie,
            });
            limpiarYsalir();
        }
    }



    return (
        <div className="modal_upInfo">
            <Modal show={props.show} onHide={props.onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}>

                <Form>
                    <Modal.Header>
                        <Modal.Title className="col-12">
                            <p className="text-center">Agregar Nuevo Título Académico</p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label><strong>Tipo de Título</strong></Form.Label>
                            <Form.Select aria-label="Default select example">
                                <option onClick={(e) => setTituloSelect({})}>Seleccione una Opción</option>
                                {props.tiposTitulos.map((tipoTitulo, index) => {
                                    return <option onClick={(e) => setTituloSelect({ "id": tipoTitulo.id_tipo_titulo, "val": tipoTitulo.titulo })}
                                        key={index} value={tipoTitulo.id_tipo_titulo}>{tipoTitulo.titulo}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Label><strong>Institución</strong></Form.Label>
                            <Form.Select aria-label="Default select example">
                                <option onClick={(e) => setInstSelect({})}>Seleccione una Opción</option>
                                {props.instituciones.map((institucion, index) => {
                                    return <option onClick={(e) => setInstSelect({ "id": institucion.id_institucion, "val": institucion.institucion })}
                                        key={index} value={institucion.id_institucion}>{institucion.institucion}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                            <Form.Label><strong>Grado Académico</strong></Form.Label>
                            <Form.Select aria-label="Default select example">
                                <option onClick={(e) => setGradoSelect({})}>Seleccione una Opción</option>
                                {props.gradosAcademicos.map((gradoAcademico, index) => {
                                    return <option onClick={(e) => setGradoSelect({ "id": gradoAcademico.id_grado_academico, "val": gradoAcademico.grado_academico })}
                                        key={index} value={gradoAcademico.id_grado_academico}>{gradoAcademico.grado_academico}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                            <Form.Label><strong>Descripción</strong></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Ej: Maestria en Educación Superior"
                                onChange={(e) => setDescrip(e.target.value)}
                            />
                        </Form.Group>
                        <Row>
                            <Col xs={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                    <Form.Label><strong>Año</strong></Form.Label>
                                    <Form.Control
                                        type="number"
                                        pattern="[0-9]{4}"
                                        placeholder=""
                                        size="4"
                                        onChange={(e) => setAnio(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                    <Form.Label><strong>Número de Serie</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        onChange={(e) => setSerie(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12}>
                                <div className="text-danger">{error}</div>
                            </Col>
                        </Row>


                    </Modal.Body>

                    <Modal.Footer className='p-0'>
                        <div className='row col-12 m-0'>
                            <div onClick={limpiarYsalir} className='col-6 btn-ios rounded-bottom'>Cancelar</div>
                            <span onClick={addNew} className='col-6 border-start btn-ios rounded-bottom'>Guardar</span>
                        </div>
                    </Modal.Footer>

                </Form>
            </Modal>
        </div>
    );
}


export default StepInfoAcademica;