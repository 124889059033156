import { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import {AiFillEye,AiFillEyeInvisible} from "react-icons/ai";
import {MdOutlineModeEditOutline,MdOutlineEditOff} from "react-icons/md";
import { useUsuario } from "../../context/UsuarioContext";
import { upPass } from "../../http/log";

function Acceso(){

    const { setToken,setSpinnerLoad } = useUsuario();

    const [usuario, setUsuario]= useState("");
    const [pass, setPass]= useState("");
    const [newPass, setNewPass] = useState("");
    const [reNewPass, setReNewPass] = useState("");

    const [passwordError, setpasswordError] = useState("");

    const [usuarioEdit, setUsuarioEdit] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [reNewPasswordShown, setReNewPasswordShown] = useState(false);

    const handleValidation = () => {
        var paswd=  /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,12}$/;
        let formIsValid = true;
        if(pass===null || pass===""){
            setpasswordError("Ingrese la Contraseña Actual");
            return false;
        }

        if(newPass===null || newPass===""){
            setpasswordError("Ingrese la Nueva Contraseña");
            return false;
        }

        if(!newPass.match(paswd)){
            setpasswordError("La contraseña debe tener el formato establecido");
            return false;
        }

        if(newPass!==reNewPass){
            setpasswordError("La nueva Contraseña debe coincidir en los dos campos");
            return false;
        }
        setpasswordError("");
        return formIsValid;
    }

    function upData(){
        if(handleValidation()){
            const formData = new FormData();
            formData.append("pass", pass);
            formData.append("newpass", newPass);
            if(usuario!==null && usuario!=="")formData.append("user", usuario);
            
            console.log(usuario);
            console.log(pass);
            console.log(newPass);

            setSpinnerLoad(true);
            upPass(formData).then(
                data =>{
                    console.log(data);
                    if(data!=null ){
                        setToken("");
                    }
                    
                    setSpinnerLoad(false);
                }
            );
        }
        
    }

    return(
        <div className="acceso">
            <div className='container'>
               <div className='row p-2 justify-content-center'>
                    <div className='col-12 mt-4 mb-2 text-start'>
                        <h3>Acceso</h3>
                    </div>
                    <div className='col-xs-12 col-sm-9 col-md-8 col-lg-7 col-xl-5'>
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Cambio de Usuario y Contraseña</h3>
                            </div>
                            <div className="card-body">
                                <div >
                                    <Form>

                                        <Form.Group as={Row} className="" controlId="formPlaintextPassword">
                                            <Form.Label column sm="6">
                                            Usuario
                                            </Form.Label>
                                            <Col sm="6">
                                                <InputGroup className="">
                                                    <Form.Control 
                                                    type="text"
                                                    value={usuario}
                                                    disabled = {!usuarioEdit}
                                                    onChange={(e) => setUsuario(e.target.value)}
                                                    />
                                                    <Button variant="secondary" id="button-addon2" onClick={()=>{setUsuarioEdit(!usuarioEdit);if(usuarioEdit){setUsuario("");}}}>
                                                        {usuarioEdit?<MdOutlineEditOff/>:<MdOutlineModeEditOutline/>}
                                                    </Button>    
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                        <p className="font12 text-end ps-2 pe-2 mb-3 text-success">
                                            {usuarioEdit?<>Está modificando el nombre de Usuario, si desea mantener el antiguo deshabilite <MdOutlineModeEditOutline/></>:<>El nombre de usuario se mantendrá, si desea cambiarlo presione en <MdOutlineModeEditOutline/></>}
                                        </p>
                                        <hr/>
                                        <Form.Group as={Row} className="mb-3 mt-3" controlId="formPlaintextPassword">
                                            <Form.Label column sm="6">
                                            Contraseña Actual
                                            </Form.Label>
                                            <Col sm="6">
                                                <InputGroup className="mb-3">
                                                    <Form.Control 
                                                    type={passwordShown ? "text" : "password"}
                                                    placeholder="" 
                                                    onChange={(e) => setPass(e.target.value)}
                                                    />
                                                    <Button variant="secondary" id="button-addon2" onClick={()=>setPasswordShown(!passwordShown)}>
                                                        {passwordShown?<AiFillEyeInvisible/>:<AiFillEye/>}
                                                    </Button>    
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="" controlId="formPlaintextPassword">
                                            <Form.Label column sm="6">
                                            Contraseña Nueva
                                            </Form.Label>
                                            <Col sm="6">
                                                <InputGroup className="mb-3">
                                                    <Form.Control 
                                                    type={newPasswordShown ? "text" : "password"}
                                                    placeholder="" 
                                                    onChange={(e) => setNewPass(e.target.value)}
                                                    />
                                                    <Button variant="secondary" id="button-addon2" onClick={()=>setNewPasswordShown(!newPasswordShown)}>
                                                        {newPasswordShown?<AiFillEyeInvisible/>:<AiFillEye/>}
                                                    </Button>    
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                        <p className="font12 text-secondary ps-2 pe-2">
                                            Contraseña de 8 a 12 caracteres que contengan letra mayúscula, minúscula, dígito numérico y un carácter especial (!@#$%^&*).
                                        </p>
                                        <Form.Group as={Row} className="mt-3" controlId="formPlaintextPassword">
                                            <Form.Label column sm="6">
                                            Reingrese la Contraseña Nueva
                                            </Form.Label>
                                            <Col sm="6">
                                                <InputGroup className="mb-3">
                                                    <Form.Control 
                                                    type={reNewPasswordShown ? "text" : "password"}
                                                    placeholder="" 
                                                    onChange={(e) => setReNewPass(e.target.value)}
                                                    />
                                                    <Button variant="secondary" id="button-addon2" onClick={()=>setReNewPasswordShown(!reNewPasswordShown)}>
                                                        {reNewPasswordShown?<AiFillEyeInvisible/>:<AiFillEye/>}
                                                    </Button>    
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                        <div className='mb-3 text-danger font12 text-end'>
                                            <strong>{passwordError}</strong>
                                        </div>
                                    </Form>
                                </div>
                                <div className="text-center">
                                    <Button variant="success" onClick={()=>upData()}>Cambiar Contraseña</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    

               </div>
           </div>

        </div>
    );
}

export default Acceso;