
import logo from '../../assets/uap-logo.bmp';

function UapEncabezado(){
    

    return(
        <div className="uap-encabezado mb-3">
            <div className='mb-3'>
                <div className="row">
                    <div className="col-2">
                        <img src={logo} alt="Logo UAP" />
                    </div>
                    <div className="col-8">
                        <p className="h4 text-center roboto-condensed-regular">Univerdidad Amazónica de Pando</p>
                        <p className="h6 text-center roboto-condensed-regular">COBIJA - PANDO - BOLIVIA</p>
                        <p className="text-center font11">La preservación de la Amazonía es parte de la vida, del progreso y desarrollo de la bella tierra pandina</p>
                    </div>
                    <div className="col-2 align-self-center text-end ">
                        <span className='font10'>Fecha Impresión : {new Date().toLocaleString() + ""}</span>
                    </div>
                </div>
            </div>
            <hr/>
            <hr/>
            <hr/>
            <hr/>
        </div>
    );
}

export default UapEncabezado;