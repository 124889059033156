import axios from "axios";
import { mostrarAlerta, mostrarAlertaDanger} from "../alerts/Alerts";
import { infoCod } from "../functions/info"
import verificarCode from "./VerificCodeResponse";
import { login_verificacion_url } from "../config/url_login";


export async function login_verificacion(data) {

  return axios.post(login_verificacion_url,data,
    {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {        
        verificarCode(data.data);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlerta("Ocurrió un error al conectar con el servidor");
    });

}
