import { Modal } from "react-bootstrap";

function ModalImage(props){

    return(
        <Modal size="sm" show={props.show} onHide={props.onHide} 
        centered contentClassName="bg-transparent border-0" >
        <Modal.Body >
            <div className="container">
                <div className="row">
                    <div className="col align-self-center text-center">
                        <img onClick={props.onHide} className="col-12" src={props.src}  alt="Foto de Usuario" />
                                
                    </div>
                </div>
            </div>
        </Modal.Body>
          
      </Modal>
    );
}

export default ModalImage;