import { useUsuario } from '../../context/UsuarioContext';
import { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import { login_verificacion } from '../../http/log_login';
import { FaEyeSlash, FaEye } from "react-icons/fa";

const LoginVerific = (props) => {

    const [password, setPassword] = useState();
    const [username, setUserName] = useState();
    const { setSpinnerLoad } = useUsuario();
    const [loginError, setLoginError] = useState("");
    const [viewPass, setViewPass] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();

        setSpinnerLoad(true);

        let data = await login_verificacion({
            username,
            password
        });

        if (data != null) {
            if (data) {
                props.onLogin();
            }
        } else {
            setLoginError("Usuario y/o contraseña incorrecto");
        }
        setSpinnerLoad(false);
    }

    return (
        <div>

            <div className='d-flex align-items-center'>
                <div className="container">
                    <div className="d-flex justify-content-center">
                        <div className="login-box col-lg-4 col-md-6 col-sm-8 bg-white m-3 border-end border-bottom border-start mb-5 bg-body rounded">
                            <div className="card-body text-center">
                                <p className="fw-bold mb-4">Para continuar debe ingresar sus credenciales de acceso</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="input-group mb-3">
                                        <input onChange={e => setUserName(e.target.value)} type="text" className="form-control" placeholder="Ingrese su Usuario" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                    </div>

                                    <div className="input-group mb-2">
                                        {/*<input onChange={e => setPassword(e.target.value)} name="password" placeholder="Contraeña" type="password" id="password" className="form-control" />
                                        <span className="input-group-text" id="basic-addon2"><FaIcons.FaLock /></span>*/}
                                    </div>


                                    <div className="" style={{ position: "relative" }}>
                                        <input
                                            type={viewPass ? "text" : "password"}
                                            className="form-control"
                                            placeholder="Ingresa tu contraseña"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div
                                            className="d-flex"
                                            style={{
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                                bottom: "0",
                                            }}
                                        >
                                            <span
                                                className="tx-15 me-1 text-primary pointer m-auto p-1 pe-2"
                                                onClick={() => {
                                                    setViewPass(!viewPass);
                                                }}
                                            >
                                                {viewPass ? <FaEye /> : <FaEyeSlash />}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='mb-3 text-danger font12'>
                                        <strong>{loginError}</strong>
                                    </div>

                                    <div className="row">

                                        <div className="col-12 align-self-end">
                                            <button type="submit" className="btn btn-primary btn-block" >Continuar</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginVerific;