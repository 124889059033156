
import { useEffect, useState, useId } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { upInfo } from "../../http/log";
import { TiDeleteOutline,TiEdit } from "react-icons/ti";
import './modal_upinfo.css'
import { mostrarAlertaDanger, mostrarAlertaSuccess } from "../../alerts/Alerts";

function ModalUpInfo(props){

    const idImage=useId();
    const [image, setImage] = useState(null);
    const [direccion, setdireccion] = useState(props.dir??'');
    const [email, setEmail] = useState(props.email??'');
    const [celular, setCelular] = useState(props.cel??'');

    const [preview, setPreview] = useState()
    const [selectedFile, setSelectedFile] = useState()

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])
    }

    function deleteImagen(){
        setPreview(null);
        setImage(null);
        setSelectedFile(null);
    }

    function upData(){
        const formData = new FormData();
        formData.append("image", image);
        formData.append("direccion", direccion);
        formData.append("email", email);
        formData.append("celular", celular);
        upInfo(formData).then(
            data =>{
              if(data!=null ){
                  if(data.status===200 && data.ok){
                    mostrarAlertaSuccess(data.mensaje);
                    props.onUp();
                    props.onHide();
                  }else{
                    mostrarAlertaDanger(data.mensaje);
                  }
                }
            }
        );
    }

    const imagenDiv=(data)=>{
        return (data!=null?
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-8 col-md-6 col-lg-5 align-self-center text-center ">
                        <div className="col-12 mb-1">
                            <div className="col-12 mb-1 divImage bg-secondary">
                                <div className="ratio ratio-1x1 rounded-circle overflow-hidden border-1">
                                    <img src={preview} className="card-img-top img-cover" alt="Imagen de perfíl"/>
                                </div>
                            </div>
                            <div className="">
                                <Button variant="outline-danger" className=' m-1' onClick={(e)=>deleteImagen()}><TiDeleteOutline/></Button>
                                <label htmlFor={idImage}>
                                    <div className='btn btn-outline-info  m-1' ><TiEdit/></div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-8 align-self-center text-center position-relative">
                        <div className="col-12 mb-3">
                            <div>
                                <label htmlFor={idImage}>
                                    <div className='btn btn-outline-success fs-6 m-1' >Seleccionar Imagen</div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            );
    }

    return (
        <div className="modal_upInfo">
            <Modal show={props.show} onHide={props.onHide} 
            aria-labelledby="contained-modal-title-vcenter"
            centered 
            backdrop="static"
            keyboard={false}>
                <Modal.Header>
                    <Modal.Title className="col-12">
                        <p className="text-center">Actualizar Información</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                    
                    <Form.Group className="mb-1">
                        <Form.Label><strong>Imagen de Perfíl</strong></Form.Label>
                        {imagenDiv(image)} 
                        <Form.Control 
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            id={idImage}
                            className="visually-hidden"
                            onChange={(e) => {setImage(e.target.files[0]);onSelectFile(e)}}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label><strong>Dirección</strong></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Dirección domiciliaria"
                            defaultValue={props.dir}
                            onChange={(e) => setdireccion(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label><strong>Correo Electrónico</strong><span className="font11"> ( Puede ingresar más correos personales separándolos con espacio )</span></Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Correo electrónico"
                            defaultValue={props.email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                        <Form.Label><strong>Número de Celular</strong></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="00000000"
                            defaultValue={props.cel}
                            onChange={(e) => setCelular(e.target.value)}
                        />
                    </Form.Group>
                </Form>
                </Modal.Body>

                <Modal.Footer className='p-0'>
                    <div className='row col-12 m-0'>
                    <div className='col-6 btn-ios rounded-bottom' onClick={props.onHide}>Cancelar</div>
                    <span className='col-6 border-start btn-ios rounded-bottom' onClick={()=>upData()}>Continuar</span>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    );
}

export default ModalUpInfo;
